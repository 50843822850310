import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import uuid from 'react-uuid'
import { serverTimestamp } from 'firebase/database'

import { beleefKimApi } from '../../service/beleefKim'
import { createProject, updateProject } from 'features/projects/createAction'
import {
  updateTitle,
  updateFiles,
  updateStep,
} from 'features/projects/projectSlice'
import { blobFromUrl } from '../utils/blobFromUrl'
import Title from 'components/form/Title'
import Files from 'components/form/Files'

const { uploadFileHandler, downloadFileHandler } = beleefKimApi
// .env --start
const DATABASE_PATH = '/projects'
const STORAGE_URL = '/projects/files'
// .env --end

const ProjectForm = () => {
  const dispatch = useDispatch()

  const { projectForm, projectItem, isLoading } = useSelector(
    (store) => store.projects
  )

  const { step } = projectForm

  const navigate = useNavigate()
  let { id } = useParams()

  const [loading, setLoading] = React.useState(false)

  // features/utils --start
  // const onNextStep = (e, body) => {
  //   e.preventDefault()

  //   dispatch(addToForm(body))

  //   setStep(step + 1)
  // }

  // const onPrevStep = (e, body) => {
  //   e.preventDefault()

  //   dispatch(addToForm(body))

  //   setStep(step - 1)
  // }

  // const onStepChange = (body, step) => {
  //   dispatch(addToForm(body))

  //   setStep(step)
  // }

  const handleFiles = async (file) => {
    const newFiles = []
    if (file.length > 0) {
      for (const key in file) {
        const blob = await blobFromUrl(file[key].downloadURL, file[key].type)
        const { name } = blob

        await uploadFileHandler(`${STORAGE_URL}/${name}`, blob)
        const { downloadURL } = await downloadFileHandler(
          `${STORAGE_URL}/${name}`
        )

        newFiles.push({
          downloadURL,
          type: file[key].type,
          name,
        })
      }
    }

    return newFiles
  }

  // features/utils --end

  // onSubmitProject --hook
  const onSubmitProject = async (e, data) => {
    e.preventDefault()

    setLoading(true)

    const { thumbnail, files } = data
    const { title } = projectForm.data

    const newThumbnail = await handleFiles(thumbnail)
    const newFiles = await handleFiles(files)

    if (id) {
      const newData = {
        id: projectItem.id,
        title,
        files: newFiles,
        thumbnail: newThumbnail,
        createdAt: projectItem.createdAt,
        updatedAt: serverTimestamp(),
      }

      dispatch(updateProject({ newProject: newData, project: projectItem }))

      navigate(`/projects/${id}`)
    } else {
      const newData = {
        title,
        thumbnail: newThumbnail,
        files: newFiles,
        createdAt: serverTimestamp(),
      }

      dispatch(createProject(newData))

      navigate('/projects')
    }

    setLoading(false)
  }

  switch (step) {
    case 'title':
      return (
        <Title
          id={id}
          isVisible={{ title: true, files: true, details: false }}
          form={projectForm.data}
          item={projectItem}
          routeGroup={DATABASE_PATH}
          updateTitle={updateTitle}
          updateStep={updateStep}
        />
      )
    case 'files':
      return (
        <Files
          id={id}
          isVisible={{ title: true, files: true, details: false }}
          loading={loading}
          hasFiles={true}
          form={projectForm.data}
          item={projectItem}
          routeGroup={DATABASE_PATH}
          updateFiles={updateFiles}
          updateStep={updateStep}
          // onStepChange={onStepChange}
          // onNextStep={onNextStep}
          // onPrevStep={onPrevStep}
          onSubmit={onSubmitProject}
        />
      )
  }
}

export default ProjectForm
