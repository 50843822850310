import styled from 'styled-components'

import { theme } from 'theme'

export const Container = styled.div`
  position: relative;
  display: flex;
  width: calc(100% - 120px);
  height: calc(100vh - 120px);
  border: 2px solid ${theme.colors.quaternary};
  margin: 60px;
  overflow-y: scroll;
`

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5000;
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid ${theme.colors.quaternary};
  background-color: #fff;
  cursor: pointer;

  img {
    width: 45px;
  }
`

export const Left = styled.div`
  position: fixed;
  top: 60px;
  left: 60px;
  bottom: 60px;

  width: 35%;
  margin: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: top;
  }
`

export const Right = styled.div`
  margin-left: auto;
  width: 65%;
  padding: 80px 60px 0 120px;
  text-align: right;
  color: ${theme.colors.quinary};

  span {
    display: block;
    margin-bottom: 10px;
  }
`

export const Title = styled.h1`
  margin: 0;
  margin-bottom: 40px;
  font-family: 'Brittany Signature';
  font-size: 48px;
`

export const Text = styled.p`
  width: 100%;
  margin: 0;
  font-family: 'VL Arch Light Cond Light';
  font-size: 18px;
  color: ${theme.colors.quinary};
`
