import * as React from 'react'
import { useTab, useTabList, useTabPanel } from 'react-aria'
import { Item, useTabListState } from 'react-stately'

export function Tabs(props) {
  let state = useTabListState(props)
  let ref = React.useRef(null)
  let { tabListProps } = useTabList(props, state, ref)
  return (
    <div className={`tabs ${props.orientation || ''}`}>
      <div {...tabListProps} ref={ref} style={{ display: 'flex' }}>
        {[...state.collection].map((item) => (
          <Tab key={item.key} item={item} state={state} />
        ))}
      </div>
      <TabPanel key={state.selectedItem?.key} state={state} />
    </div>
  )
}

function Tab({ item, state }) {
  let { key, rendered, props } = item
  let { writableTab } = props

  const [isReadonly, setIsReadonly] = React.useState(true)

  let tabRef = React.useRef(null)
  let { tabProps } = useTab({ key }, state, tabRef)

  let tabInputRef = React.useRef(null)

  React.useEffect(() => {
    if (state.selectedKey === key) {
      if (isReadonly) tabRef.current?.focus()
      if (!isReadonly) tabInputRef.current?.focus()
    }
  }, [state.selectedKey, key, isReadonly])

  React.useLayoutEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (state.selectedKey !== key) {
        if (
          !isReadonly &&
          tabRef.current &&
          !tabRef.current.contains(e.target)
        ) {
          setIsReadonly(true)
        }
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [state.selectedKey, key, isReadonly])

  const readOnlyTab = () => {
    return (
      <div
        {...tabProps}
        ref={tabRef}
        onKeyDown={(e) => {
          if (e.key === 'Enter') setIsReadonly(false)
        }}
        onClick={() => setIsReadonly(true)}
      >
        {rendered}
      </div>
    )
  }

  const onWritableTab = () => {
    // Close input on click outside!
    return (
      <div
        {...tabProps}
        ref={tabRef}
        onKeyDown={(e) => {
          if (e.key === 'Enter') setIsReadonly(true)
        }}
      >
        {writableTab(props, tabInputRef, { isReadonly, setIsReadonly })}
      </div>
    )
  }

  if (isReadonly) return readOnlyTab()
  else return onWritableTab()
}

function TabPanel({ state, ...props }) {
  let ref = React.useRef(null)
  let { tabPanelProps } = useTabPanel(props, state, ref)
  return (
    <div {...tabPanelProps} ref={ref}>
      {state.selectedItem?.props.children}
    </div>
  )
}
