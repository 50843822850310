import { initializeApp } from 'firebase/app'
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
} from 'firebase/auth'
import { getDatabase } from 'firebase/database'

const firebaseConfig = {
  apiKey: 'AIzaSyBpWe4As-dUPAlU7pSDbYa76Gwww-J6dyM',
  authDomain: 'beleefkim.firebaseapp.com',
  databaseURL:
    'https://beleefkim-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'beleefkim',
  storageBucket: 'beleefkim.appspot.com',
  messagingSenderId: '285677283324',
  appId: '1:285677283324:web:936d23ac16d5b4713eb479',
  measurementId: 'G-25DMH1MK02',
}

const firebaseApp = initializeApp(firebaseConfig)

export const auth = getAuth()

export const signInAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return

  return await signInWithEmailAndPassword(auth, email, password)
}

export const onAuthStateChangedListener = (callback) =>
  onAuthStateChanged(auth, callback)

export const signOutHandler = () => {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
    })
}

export const passwordReset = async (email) => {
  return await sendPasswordResetEmail(auth, email)
}
