import aboutImage from 'assets/kim/kim-about.jpg'

import advise from 'assets/icons/approach/advise.png'
import adviseWhite from 'assets/icons/approach/advise-white.png'
import styling from 'assets/icons/approach/styling.png'
import stylingWhite from 'assets/icons/approach/styling-white.png'
import twoDesign from 'assets/icons/approach/twoDesign.png'
import twoDesignWhite from 'assets/icons/approach/twoDesign-white.png'
import threeDesign from 'assets/icons/approach/threeDesign.png'
import threeDesignWhite from 'assets/icons/approach/threeDesign-white.png'

import question from 'assets/icons/process/question.png'
import puzzle from 'assets/icons/process/puzzle.png'
import solution from 'assets/icons/process/solution.png'
import result from 'assets/icons/process/result.png'

export const ABOUT_PAGE = {
  path: '/about',
  icon: <img src={aboutImage} />,
  heading: 'Over mezelf',
  text: {
    preview:
      '‘Beleef JOUW interieur’, dat is waar ik voor sta. Mijn technische achtergrond en praktische blik gaan hand in hand samen met een diepgaand gevoel voor kleuren en materialen. Elkaar leren kennen en begrijpen is de basis om te komen tot een passend interieur dat in harmonie is met jouw levensstijl. Door het samenspel van jouw persoonlijke wensen en mijn kennis komt er een gedetailleerd interieur op maat, aangepast aan jouw behoeften en gewoontes. We beleven samen dit proces naar jouw ultieme droomomgeving.',
    complete:
      'Hi! Mijn naam is Kim, Kim Jansen.. De liefde voor interieurinrichting heb ik geërfd van mijn vader en is er in mijn kindertijd met de paplepel ingegoten. Mijn vader heeft een maatwerkbedrijf waar ik van jongs af aan niet was weg te slaan. Ik was de koning te rijk toen ik op mijn 12de verjaardag mijn eigen gereedschapskist met mijn naam op cadeau kreeg. Vanaf dat moment kon ik ECHT mee gaan timmeren. Op mijn 16de kwam ik er achter dat ik het bedenken en ontwerpen van een interieur, eigenlijk nog veel leuker vond dan het maken zelf. Spelenderwijs ben ik aan de slag gegaan met de tekenprogramma’s op kantoor en al snel had ik de microbe te pakken. Ik wist meteen, dit is wat ik wil doen. Ik heb een bachelor interieur en design behaald, deze aangevuld met een graduaat in vastgoed en een diploma als interieur stylist. Stil zitten staat niet in mijn woordenboek, langs mijn studies heb ik altijd gewerkt als technisch tekenaar in de interieurbouw. Tegenwoordig werk ik als interieurontwerpster voor een prachtig bedrijf met super gave projecten. Nog steeds is mijn honger niet gestild, in de avonduren en weekenden vind ik het fantastisch om mijn eigen ontwerpen te bedenken en uit te werken, deze wil ik graag delen in een online portfolio.',
  },
}

export const APPROACH_PAGE = {
  heading: 'Aanpak',
  text: 'Elke opdracht vergt een aanpak op maat. Wil je grondig renoveren en zelf de handen uit de mouwen steken? Heb je liever een renovatie van A tot Z maar met (gedeeltelijke) begeleiding of wil je liever helemaal ontzorgd worden? Misschien is een kleine styling al genoeg om jouw interieur te doen (her)leven? Ben je benieuwd naar een visuele weergave van jouw ideeën? Ik ondersteun jou bij wat jij nodig hebt !',
}
export const ADVISE_PAGE = {
  path: '/advise',
  icon: <img src={advise} alt='Advise' />,
  iconWhite: <img src={adviseWhite} alt='Advise' width='90px' />,
  heading: 'Advies',
  text: 'Ben je niet bang om zelf aan de slag te gaan? Dan is het soms al genoeg om even de spiegel voor te houden en handvaten aan te geven waar je op kan verder borduren. Dan blazen we op die manier een nieuwe frisse wind door jouw interieur. Wat je van mij kan verwachten? Uitdagende vragen op locatie bij jouw project, mijn visie over de beste aanpak. Achteraf krijg je een uitgewerkte analyse van jouw wensen, met een aantal ingrepen die jouw project tot leven brengen.',
}
export const STYLING_PAGE = {
  path: '/styling',
  icon: <img src={styling} alt='Styling' width='90px' />,
  iconWhite: <img src={stylingWhite} alt='Styling' width='90px' />,
  heading: 'Styling',
  text: 'Wil jij graag de puntjes op de i laten zetten? Verschillende details zorgen voor een rustig totaalbeeld en een comfortabel gevoel. Geef de touwtjes uit handen en ik creëer voor jou een totaal plaatje dat gewoon klopt en af is. Wat ik voor jou hierin kan betekenen? Mijn oog voor detail. Ik ga jouw interieur aankleden volgens jouw wensen en mijn gevoel voor kleur en materiaal. Door de juiste elementen toe te voegen breng ik harmonie doorheen het geheel.',
}
export const TWO_DESIGN_PAGE = {
  path: '/2D',
  icon: <img src={twoDesign} alt='2D' width='90px' />,
  iconWhite: <img src={twoDesignWhite} alt='2D' width='90px' />,
  heading: '2D Ontwerp',
  text: 'Een 2D interieurontwerp gaat weer een stap verder dan alleen advies. Er komen veel meer aspecten aan bod met een concrete uitwerking. Ga je bouwen of renoveren, misschien wel verhuizen? Wanneer je toe bent aan een nieuw interieur aangepast op jouw woonwensen en levensstijl, dan is het zeker verstandig om tijdig een 2D ontwerp te laten maken als rode draad om aan vast te houden doorheen de bouw of verhuizing. Wat ik te bieden heb? Een kennismaking aan huis waar we de opdracht in kaart brengen. Daarna ga ik driftig aan de slag om een aantal 2D plattegronden op te tekenen. Tevens verzamel ik de materialen voor een tastbaar mood board. Dit kom ik graag presenteren met een woordje uitleg. Achteraf gaan we de definitieve plattegrond samenstellen met de gekozen materialen. De uitgewerkte definitieve presentatie bezorg ik jou persoonlijk, je krijgt zowel een papieren versie als een digitale versie. Daarmee kan jij meteen aan de slag.',
}
export const THREE_DESIGN_PAGE = {
  path: '/3D',
  icon: <img src={threeDesign} alt='3D' width='90px' />,
  iconWhite: <img src={threeDesignWhite} alt='3D' width='90px' />,
  heading: '3D Ontwerp',
  text: 'We kunnen jouw project naar een nog hoger niveau tillen met een 3D ontwerp. Hiermee valt alles op zijn plaats en heeft u een fotorealistisch beeld alsof u zelf in de ruimte staat. Alle details, van kleuren, materialen tot meubels en accessoires komen hier tot leven. Dit is natuurlijk de perfecte manier om jouw wensen te visualiseren. Wat ik jou kan laten zien? Een realistische weergave van de ontworpen ruimte met alles erop en eraan. Een 2D-ontwerp aanpak gaat hieraan vooraf zodat we de indeling en materialen helder voor ogen hebben. Op basis daarvan werk ik mijn 3D-ontwerp nauwkeurig tot in detail uit, dit geeft een waarheidsgetrouw beeld van de uitwerking. Alle informatie wordt gebundeld tot een pakket zodat je meteen kan starten aan jouw project. Neem zeker een kijkje in mijn portfolio voor wat voorbeelden.',
}

export const PROCESS_PAGE = {
  heading: 'Proces',
  text: 'Ik vergelijk een interieur ontwerp op maat graag met een ouderwetse legpuzzel. Beeld je een tafel in met veel puzzelstukjes chaotisch door elkaar. Dan zijn er zijn 2 opties: Optie 1: je vliegt erin, begint vol goede moed te puzzelen. Wat blijkt achteraf? Je had vaak de foute stukjes in handen. Het overzicht was regelmatig zoek. De periode van het leggen heeft langer geduurd dan gewenst. Optie 2 We beginnen met het sorteren van de puzzelstukjes om een overzicht te krijgen. Wat gebeurt er? Het creëren van structuur. We nemen de juiste stukjes. Ondergaan een rustig proces. En binnen no time ligt de puzzel in elkaar. Dit is natuurlijk wat we willen, en waarbij ik jou kan ondersteunen.',
}
export const QUESTION_PAGE = {
  path: '/question',
  icon: <img src={question} alt='Question' width='45px' />,
  heading: 'Opgave',
  text: 'We starten dit proces met het verzamelen van die puzzelstukjes. Dat zijn onder andere jouw wensen en ideeën rondom het project, maar ook de praktische info (totaal renovatie, styling, wat is er al, hoeveel ondersteuning wil je, etc) Die puzzelstukjes verzamel ik tijdens een eerste kennismaking aan de hand van een aantal uitdagende vragen. De informatie die ik krijg van jullie, vul ik aan met mijn visie en ervaring. Al deze ideeën / puzzelstukjes ga ik filteren en sorteren zodat we een overzicht hebben voor de volgende fases.',
}
export const PUZZLE_PAGE = {
  path: '/puzzle',
  icon: <img src={puzzle} alt='Puzzle' width='45px' />,
  heading: 'Puzzel',
  text: 'De leukste, en meest uitdagende periode. Het leggen van de puzzel. We hebben de stukjes, er is een overzicht, klaar om er in te vliegen. Wanneer jullie de puzzel uit handen geven, en mij de opdracht opleggen om hier één geheel van te maken ga ik vol enthousiasme aan de gang. Mijn doel is om een mooi passend ontwerp te maken dat naadloos past bij jouw smaak, persoonlijkheid en dagelijkse leven.',
}
export const SOLUTION_PAGE = {
  path: '/solution',
  icon: <img src={solution} alt='Solution' width='45px' />,
  heading: 'Oplossing',
  text: 'De puzzel is gelegd! Alle stukjes zijn perfect bij elkaar gekomen. Door jouw richtlijnen en vertrouwen in mij, hebben we een prachtig compleet plaatje gecreëerd zonder onnodig meerwerk. Een ontspannend proces met een duidelijke structuur. Dat is mijn opzet en daar ga ik voor.',
}
export const RESULT_PAGE = {
  path: '/result',
  icon: <img src={result} alt='Result' width='45px' />,
  heading: 'Besluit',
  text: 'Als we even terugkijken naar dit proces, deze manier van beleven, is mijn doel om alle wensen en de manier van leven in kaart (puzzelstukjes) te brengen zodat jouw interieur aansluit op jouw persoonlijkheid. Een passend interieur gaat zo veel verder dan een mooi plaatje. Samen maken wij de puzzel heel!',
}

export const EXTRAS_PAGE = {
  path: '/extras',
  heading: 'Extra’s',
  text: (
    <p>
      Heb je graag zelf de touwtjes in handen om jouw ontwerp te realiseren? Of
      wil je graag ontzorgd worden en geef je mij het vertrouwen om alles in
      goede banen te leiden? Alles is mogelijk en bespreekbaar. Ondersteuning
      kan ook nog veel gedetailleerder gaan; ik kan je ook bijstaan met advies
      over
      <ul>
        <li>Verlichtingsplannen</li>
        <li>meubelplannen</li>
        <li>groen plannen</li>
        <li>technische uitwerking van het maatwerk</li>
        <li>video realisatie en begeleiding</li>
      </ul>
      Wil je hier meer over weten? Neem dan zeker contact met me op.
    </p>
  ),
}

export const PROJECTS_TEXT = ''

export const POSTS_TEXT = ''
