export const theme = {
  textStyles: {
    brittanySignature: {
      fontSize: '32px',
      fontFamily: 'Brittany Signature',
    },
  },
  fontSizes: ['32px', '16px', '14px'],
  colors: {
    primary: '#35435D',
    secondary: '#8DB1D3',
    tertiary: '#D0B1B6',
    quaternary: '#DAD5CF',
    quinary: '#8E877C',
  },
  buttons: {
    solid: {
      textColor: '',
      textColorHover: '',
      textColorDisabled: '',
      buttonBackground: '',
      buttonBackgroundHover: '',
      buttonBackgroundPressed: '',
      buttonBackgroundDisabled: '',
      borderColor: '',
      borderColorHover: '',
      borderColorPressed: '',
      borderColorDisabled: '',
    },
    outline: {
      textColor: '',
      textColorHover: '',
      textColorDisabled: '',
      buttonBackground: '',
      buttonBackgroundHover: '',
      buttonBackgroundPressed: '',
      buttonBackgroundDisabled: '',
      borderColor: '',
      borderColorHover: '',
      borderColorPressed: '',
      borderColorDisabled: '',
    },
    secondary: {
      textColor: '',
      textColorHover: '',
      textColorDisabled: '',
      buttonBackground: '',
      buttonBackgroundHover: '',
      buttonBackgroundPressed: '',
      buttonBackgroundDisabled: '',
      borderColor: '',
      borderColorHover: '',
      borderColorPressed: '',
      borderColorDisabled: '',
    },
  },
  breakpoints: {
    desktopOrLaptop: 'only screen and (min-width: 1224px)',
    // tabletOrMobile:
    //   'only screen and (min-width: 768px) and (max-width : 1223px)',
    mobile: 'only screen and (max-width : 1223px)',
  },
}

export const adminLevel = {}
