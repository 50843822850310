import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

const PrivateRoutes = () => {
  const user = useSelector((state) => state.auth.user)

  return user ? <Outlet /> : <Navigate to='/admin' />
}

export default PrivateRoutes
