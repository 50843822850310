import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-scroll'

import { SvgIcon } from '@mui/material'
import { ReactComponent as Facebook } from 'assets/icons/Facebook.svg'
import { ReactComponent as Instagram } from 'assets/icons/Instagram.svg'
import heroImage from 'assets/kim/kim-hero.jpg'
import { theme } from '../../theme'

const DesktopLaptop = () => {
  return (
    <HeaderContainer>
      <Overlay />
      <img src={heroImage} alt='Hero' />
      <HeaderText>
        <span>Hey jij, wat leuk dat je er bent!</span>
        {/* <Button to='contact' smooth spy>
          Neem contact op
        </Button> */}
      </HeaderText>
      {/* <Buttons>
        <IconButton>
          <SvgIcon
            style={{ color: theme.colors.primary }}
            component={Instagram}
            fontSize='inherit'
            inheritViewBox
          />
        </IconButton>
        <IconButton>
          <SvgIcon
            style={{ color: theme.colors.primary }}
            component={Facebook}
            fontSize='inherit'
            inheritViewBox
          />
        </IconButton>
      </Buttons> */}
    </HeaderContainer>
  )
}

export default DesktopLaptop

const HeaderContainer = styled.header`
  display: flex;
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
  font-family: 'VL Arch Light Cond Light';
  font-size: 0.9375rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  transform: translateY(-50%);
  width: 100%;
  z-index: 2;
  text-align: center;

  color: ${theme.colors.primary};

  span {
    font-family: 'Brittany Signature';
    font-size: 32px;
    margin-bottom: 10px;
  }
`

const Overlay = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.6);
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 2;
`

const Button = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px auto 0 auto;
  padding: 0;
  width: 120px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: ${theme.colors.tertiary};
  color: white;
  cursor: pointer;
`

const Buttons = styled.div`
  z-index: 2;
  display: flex;
  gap: 10px;
  position: absolute;
  right: 20px;
  bottom: 20px;
`

const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
`
