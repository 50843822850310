import { createAsyncThunk } from '@reduxjs/toolkit'

import { beleefKimApi } from 'service/beleefKim'

const {
  writeDataHandler,
  readDataHandler,
  updateDataHandler,
  deleteDataHandler,
  downloadFileHandler,
  deleteFileHandler,
} = beleefKimApi

const DATABASE_PATH = 'posts/'
const STORAGE_URL = 'posts/files/'

export const fetchPosts = createAsyncThunk('posts/fetchPosts', async () => {
  try {
    const posts = await readDataHandler(DATABASE_PATH)
    return posts
  } catch (error) {
    console.log(error)
  }
})

export const createPost = createAsyncThunk(
  'posts/createPost',
  async (post, { rejectWithValue }) => {
    try {
      const { id } = await writeDataHandler(DATABASE_PATH, post)
      return { id, ...post }
    } catch (error) {
      return rejectWithValue(error.response)
    }
  }
)

export const updatePost = createAsyncThunk(
  'posts/updatePost',
  async ({ newPost, post }, { rejectWithValue }) => {
    try {
      const { id, thumbnail, files } = post

      for (const key in thumbnail) {
        const url = STORAGE_URL + thumbnail[key].name
        const { fileExists } = await downloadFileHandler(url)
        if (!fileExists) await deleteFileHandler(url)
      }
      for (const key in files) {
        const url = STORAGE_URL + files[key].name
        const fileExists = await downloadFileHandler(url)
        if (!fileExists) await deleteFileHandler(url)
      }

      await updateDataHandler(DATABASE_PATH + id, newPost)

      return { id, newPost }
    } catch (error) {
      return rejectWithValue(error.response)
    }
  }
)

export const deletePost = createAsyncThunk(
  'posts/deletePost',
  async ({ id, posts }, { rejectWithValue }) => {
    try {
      const postItem = posts.find((item) => item.id === id)
      const { thumbnail, files } = postItem

      for (const key in thumbnail)
        await deleteFileHandler(STORAGE_URL + thumbnail[key].name)
      for (const key in files)
        await deleteFileHandler(STORAGE_URL + files[key].name)

      await deleteDataHandler(DATABASE_PATH + id)
      return id
    } catch (error) {
      return rejectWithValue(error.response)
    }
  }
)
