import React from 'react'

import * as S from './styles'
import useClickOutside from 'hooks/use-click-outside'

const Dialog = (props) => {
  const { children, setIsOpen, center } = props

  return <S.DialogContent center={center}>{children}</S.DialogContent>
}

export default Dialog
