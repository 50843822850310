import React, { useState } from 'react'
import styled from 'styled-components'
import Fab from '@mui/material/Fab'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import { SvgIcon } from '@mui/material'
import { ReactComponent as BackwardArrow } from 'assets/icons/BackwardArrow.svg'

import { theme } from 'theme'

export const SliderMobile = (props) => {
  const { children, length, names } = props

  const [currentIndex, setCurrentIndex] = useState(0)

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? length - 1 : prevIndex - 1
    )
  }

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === length - 1 ? 0 : prevIndex + 1
    )
  }

  return (
    <SliderContainer>
      {/* <CustomFab onClick={goToPrevious}>
        <ArrowForwardIosIcon
          fontSize='10px'
          style={{ transform: 'rotate(-180deg)', color: 'white' }}
        />
      </CustomFab> */}
      <Header>
        {names[currentIndex] && names[currentIndex].content?.subTitle}
        <Buttons>
          <Button onClick={goToPrevious}>
            <SvgIcon
              style={{ color: theme.colors.secondary }}
              component={BackwardArrow}
              fontSize='inherit'
              inheritViewBox
            />
          </Button>
          <Button onClick={goToNext}>
            <SvgIcon
              style={{
                color: theme.colors.secondary,
                transform: 'rotate(180deg)',
              }}
              component={BackwardArrow}
              fontSize='inherit'
              inheritViewBox
            />
          </Button>
        </Buttons>
      </Header>
      <Slideshow>
        <SlideshowSlider
          style={{ transform: `translate3d(${-currentIndex * 100}%, 0, 0)` }}
        >
          {children}
        </SlideshowSlider>
      </Slideshow>
      <DotWrapper>
        {children.map((_, index) => (
          <Dot
            key={index}
            active={index === currentIndex}
            //onClick={() => handleDotClick(index)}
          />
        ))}
      </DotWrapper>
    </SliderContainer>
  )
}

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //height: 100%;
`

const Slideshow = styled.div`
  /* position: relative; */
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
  //height: 100%;

  margin: 0;
  background-color: transparent;
  border-radius: 8px;
  //border: 1px solid ${theme.colors.quinary};
  //height: 100%;
`

const SlideshowSlider = styled.div`
  display: flex;
  //white-space: nowrap;
  transition: ease 1000ms;
  //height: auto;
  //aspect-ratio: 1 / 1;
  margin: 0;
  padding: 0;
  border-radius: 8px;
  //overflow: hidden;
`

const Buttons = styled.div`
  display: flex;
  gap: 8px;
  margin-left: auto;
`

const Button = styled.button`
  /* position: absolute;
  top: 0;
  right: 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: ${theme.colors.secondary};
  border: 1px solid ${theme.colors.secondary};
  border-radius: 16px;
  height: 46px;
  margin: 0;
  padding: 0 16px;
  font-size: 14px;
  cursor: pointer;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  margin: 16px;

  font-family: 'VL Arch Light Cond Light';
  font-size: 20px;
  color: ${theme.colors.secondary};
`

const DotWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin: 16px auto 0 auto;
`

const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ active }) =>
    active ? theme.colors.secondary : theme.colors.quaternary};
`
