import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
} from 'firebase/auth'
import {
  ref,
  get,
  push,
  update,
  remove,
  limitToFirst,
  limitToLast,
  query,
  orderByChild,
  endAt,
  startAt,
  orderByKey,
  orderByValue,
  startAfter,
  endBefore,
  equalTo,
} from 'firebase/database'
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage'

import { auth, db } from 'firebaseConfig'

export const beleefKimApi = {
  // authentication
  onAuthStateChangedListener: (callback) => onAuthStateChanged(auth, callback),
  signInWithEmailAndPasswordHandler: async (email, password) => {
    if (!email || !password) return
    await signInWithEmailAndPassword(auth, email, password)
  },
  signOutHandler: async () => await signOut(auth),
  sendPasswordResetEmailHandler: async (email) =>
    await sendPasswordResetEmail(auth, email),
  // read and write data
  writeDataHandler: async (_path, _item) => {
    const newItem = await push(ref(db, _path), _item)
    return { id: newItem.key }
  },
  readDataHandler: async (_path, _queries) => {
    let snapshot = null

    if (_queries) snapshot = await get(query(ref(db, _path), ..._queries))
    else snapshot = await get(ref(db, _path))

    const data = []

    snapshot.forEach((childSnapshot) => {
      const childKey = childSnapshot.key
      const childData = childSnapshot.val()
      data.push({ id: childKey, ...childData })
    })

    return data
  },
  updateDataHandler: async (_path, _item) => {
    await update(ref(db, _path), _item)
  },
  deleteDataHandler: async (_path, _id) => {
    await remove(ref(db, _path))
    return _id
  },
  // cloud storage
  uploadFileHandler: async (_url, _file) => {
    const { type } = _file

    const storage = getStorage()

    const metadata = {
      contentType: type,
    }

    await uploadBytes(storageRef(storage, _url), _file, metadata)
  },
  downloadFileHandler: async (_url) => {
    let downloadURL = ''
    let fileExists = false

    try {
      const storage = getStorage()
      downloadURL = await getDownloadURL(storageRef(storage, _url))
      fileExists = true
    } catch (error) {
      if (error.code === 'storage/object-not-found') fileExists = false
    }

    return { downloadURL, fileExists }
  },
  deleteFileHandler: async (_url) => {
    const storage = getStorage()
    await deleteObject(storageRef(storage, _url))
  },
}
// sorting and filtering data
export const sortData = {
  orderByChild,
  orderByKey,
  orderByValue,
}

export const filterData = {
  limitToFirst,
  limitToLast,
  startAt,
  startAfter,
  endAt,
  endBefore,
  equalTo,
}
