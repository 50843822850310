import * as React from 'react'
import styled from 'styled-components'
import Fab from '@mui/material/Fab'
import PlayArrow from '@mui/icons-material/PlayArrow'
import Pause from '@mui/icons-material/Pause'
import ReplayIcon from '@mui/icons-material/Replay'

import { theme } from 'theme'

const MediaPlayer = (props) => {
  const { file, classes } = props

  const videoRef = React.useRef(null)
  const timelineRef = React.useRef(null)
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [progress, setProgress] = React.useState(0)
  const [durationSec, setDurationSec] = React.useState()

  const handlePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play()
      setIsPlaying(true)
    } else {
      videoRef.current.pause()
      setIsPlaying(false)
    }
  }

  React.useEffect(() => {
    const handleVideoPlay = () => {
      setIsPlaying(true)
    }

    const handleVideoPause = () => {
      setIsPlaying(false)
    }

    videoRef.current.addEventListener('play', handleVideoPlay)
    videoRef.current.addEventListener('pause', handleVideoPause)
  }, [])

  return (
    <Container progress={progress} onClick={handlePlay}>
      <TimeLine progress={progress} />
      <video
        ref={videoRef}
        src={file}
        onTimeUpdate={(e) => {
          setProgress(e.target.currentTime / e.target.duration)
        }}
        className={classes}
        autoPlay
      />
      {isPlaying && progress !== 1 && progress > 0 && (
        <CustomFab className='action'>
          <Pause />
        </CustomFab>
      )}

      {!isPlaying && progress !== 1 && (
        <CustomFab
          className='action'
          style={{ visibility: isPlaying ? 'hidden' : 'visible' }}
        >
          <PlayArrow />
        </CustomFab>
      )}

      {progress === 1 && (
        <CustomFab className='action'>
          <ReplayIcon />
        </CustomFab>
      )}
    </Container>
  )
}

export default MediaPlayer

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;

  .action {
    visibility: ${({ progress }) => (progress === 1 ? 'visible' : 'hidden')};
  }

  &:hover .action {
    visibility: visible;
  }
`

const CustomFab = styled(Fab)`
  && {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const TimeLine = styled.div`
  width: 100%;
  height: 0.25rem;
  position: absolute;
  bottom: 0;
  background-color: rgba(128, 128, 128, 0.5);

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: calc(100% - (${({ progress }) => progress}) * 100%);
    background-color: ${theme.colors.tertiary};
  }
`
