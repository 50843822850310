import React from 'react'

import * as S from './styles'

const InputFile = React.forwardRef((props, ref) => {
  const { onChange } = props
  return <S.Input {...props} ref={ref} onChange={onChange} />
})

export default InputFile
