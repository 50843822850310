import React from 'react'

import * as Form from './FormStepper.styled'

const StepperList = (props) => {
  const { children } = props

  return <Form.Stepper>{children}</Form.Stepper>
}

const StepperTrigger = (props) => {
  const { children, onClick, active } = props
  //   <Form.Tab
  //     tab={tab?.title}
  //     active
  //     onClick={(e) => onStepChange(e, { title }, 1)}
  //   >
  //     <span>
  //       <FontAwesomeIcon icon={faFileSignature} />
  //     </span>
  //     Title
  //   </Form.Tab>

  return (
    <Form.Step onClick={onClick} active={active}>
      {children}
    </Form.Step>
  )
}
// Divider
export { StepperList, StepperTrigger }
