import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { SvgIcon } from '@mui/material'
import { ReactComponent as Facebook } from 'assets/icons/Facebook.svg'
import { ReactComponent as Instagram } from 'assets/icons/Instagram.svg'
import { theme } from 'theme'

const Mobile = () => {
  let navigate = useNavigate()

  return (
    <Container>
      {/* <Row>
        <Block>Kim Jansen</Block>
        <Block>Interieur ontwerp</Block>
      </Row>
      <Row>
        <Block>Weebosch 104</Block>
        <Block>5571 NH Bergeijk</Block>
      </Row> */}
      <Row>
        <Title>Contacteer mij</Title>
      </Row>
      <Row>
        <Block>Kim Jansen</Block>
        <Block>Interieur ontwerp</Block>
        <Block>Weebosch 104 </Block>
        <Block>5571NH Bergeijk</Block>
        <Block>info@beleefkim.com</Block>
        <Block>+32 472761566</Block>
        <Block>Beleef JOUW interieur</Block>
      </Row>
      <Button onClick={() => navigate(`/contact`)}>Neem contact op</Button>
      {/* <Row>
        <Block>Beleef UW interieur</Block>
      </Row> */}
      <Buttons>
        <IconButton href='https://www.facebook.com/kim.jansen.142'>
          <SvgIcon
            style={{ color: 'white' }}
            component={Facebook}
            fontSize='inherit'
            inheritViewBox
          />
        </IconButton>
        <IconButton href='https://www.instagram.com/beleefkim/'>
          <SvgIcon
            style={{ color: 'white' }}
            component={Instagram}
            fontSize='inherit'
            inheritViewBox
          />
        </IconButton>
      </Buttons>
    </Container>
  )
}

export default Mobile

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 600px;
  padding: calc(24px + 60px) 20px 20px 20px;

  font-family: 'VL Arch Light Cond Light';
  font-size: 20px;
  background-color: #eee;
`

const Row = styled.div`
  color: ${theme.colors.primary};
  text-align: center;
  line-height: 1.5rem;

  &:last-child {
    margin-top: 24px;
  }
`

const Block = styled.div`
  margin: 10px 0;
`

const Title = styled.h1`
  margin: 0 0 44px 0;
  font-family: 'Brittany Signature';
  font-size: 48px;
`

const Button = styled.button`
  margin: 0 auto;
  padding: 0;
  width: 120px;
  height: 40px;

  border: 1px solid ${theme.colors.tertiary};
  border-radius: 5px;
  background-color: transparent;
  color: ${theme.colors.tertiary};
  font-size: 16px;
  cursor: pointer;
`

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  position: absolute;
  right: 20px;
  bottom: 20px;
`

const IconButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  background-color: ${theme.colors.primary};
  cursor: pointer;
`
