import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { theme } from 'theme'
import blogImage from 'assets/kim/kim-blog.jpg'

const Mobile = () => {
  let navigate = useNavigate()

  const routeChange = () => {
    navigate(`/posts`)
  }

  return (
    <Container>
      {/* <Overlay />
      <img src={blogImage} alt='Blog' /> */}
      <Element>
        <Overlay />
        <img src={blogImage} alt='Blog' />
      </Element>
      <Wrapper>
        <Title>Blog</Title>
        <Block>
          Ik wil jou graag meenemen in mijn passie voor interieur. De markt
          ontwikkeld zich snel, we blijven ons evalueren. Ook in de interieur
          branche. Voor mij een dagelijkse bezigheid, de kennis die ik gaandeweg
          op doe, blijf ik delen via deze weg met mijn eigen visie.
        </Block>
        <Button onClick={routeChange}>Meer lezen</Button>
      </Wrapper>
    </Container>
  )
}

export default Mobile

const Container = styled.section`
  position: relative;
  display: flex;
  width: 100%;
  height: 300px;
  flex-direction: column;
  font-family: 'VL Arch Light Cond Light';
  font-size: 20px;
  padding: 20px 20px 40px 20px;
  color: ${theme.colors.quinary};
`

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 0 20px 40px 20px;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.quaternary};
  border-radius: 5px;
`

const Element = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Title = styled.h1`
  margin: 20px auto;
  font-family: 'Brittany Signature';
  font-size: 32px;
`

const Block = styled.div`
  text-align: center;
`

const Button = styled.button`
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  padding: 0;
  width: 120px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: ${theme.colors.tertiary};
  color: white;
  font-size: 16px;
  cursor: pointer;
`

const Overlay = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
`
