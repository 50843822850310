import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-scroll'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'

import { theme } from 'theme'
import logo from 'assets/logo.png'
import { logoutHandler } from '../../features/auth/authAPI'

// const Link = (props) => {
//   const { to, children } = props
//   return <NavLinkContainer to={to}>{children}</NavLinkContainer>
// }

// scroll to point in page: https://medium.com/how-to-react/scroll-to-an-element-on-click-in-react-js-8424e478bb9
const DesktopLaptop = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth.user)

  return (
    <NavLinksContainer>
      <ListItem>
        <NavLink to='over-mezelf' smooth spy>
          Over mezelf
        </NavLink>
      </ListItem>
      <ListItem>
        <NavLink to='aanpak' smooth spy>
          Aanpak
        </NavLink>
      </ListItem>
      <ListItem>
        <NavLink to='process' smooth spy>
          Proces
        </NavLink>
      </ListItem>
      <ListItem>
        <NavLink to='home' smooth spy>
          <img id='logo' src={logo} alt='Logo' />
        </NavLink>
      </ListItem>
      <ListItem>
        <NavLink to='projecten' smooth spy>
          Ontwerpen
        </NavLink>
      </ListItem>
      <ListItem>
        <NavLink to='blog' smooth spy>
          Blog
        </NavLink>
      </ListItem>
      <ListItem>
        <NavLink to='contact' smooth spy>
          Contact
        </NavLink>
      </ListItem>
      {user && (
        <IconButton
          onClick={() => logoutHandler(dispatch)}
          position='MiddleRight'
          spacing='30px'
          size={{ width: '50px', height: '50px' }}
          rounded='16px'
          outlined={{
            lineWidth: '1px',
            lineStyle: 'solid',
            color: theme.colors.secondary,
          }}
          theme={{ background: theme.colors.secondary, color: '#fff' }}
          onHover={{
            background: theme.colors.secondary,
            color: '#fff',
          }}
        >
          <FontAwesomeIcon icon={faRightFromBracket} />
        </IconButton>
      )}
    </NavLinksContainer>
  )
}

export default DesktopLaptop

const NavLinksContainer = styled.ul`
  position: absolute;
  top: 20px;
  width: 100%;
  z-index: 500;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  font-family: 'VL Arch Light Cond Light';
  font-size: 18px;
`

const ListItem = styled.li`
  margin: 0 15px;
  align-self: center;

  #logo {
    display: block;
    margin: 0;
    padding: 0;
    //width: 60px;
    height: 90px;
  }
`

const NavLink = styled(Link)`
  color: ${theme.colors.primary};
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;

  /* unvisited link */
  &:link {
    color: #35435d;
  }

  /* visited link */
  &:visited {
    color: #35435d;
  }

  /* mouse over link */
  &:hover {
    text-shadow: 0px 0px 1px #35435d;
  }

  /* selected link */
  &:active {
    text-shadow: 0px 0px 1px #35435d;
  }
`

const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ size }) =>
    css`
      width: ${size?.width};
      height: ${size?.height};
    `}

  ${({ outlined }) =>
    outlined
      ? css`
          border: ${outlined?.lineWidth} ${outlined?.lineStyle}
            ${outlined?.color};
        `
      : css`
          border: none;
        `}

  ${({ rounded }) =>
    css`
      border-radius: ${rounded};
    `}

    ${({ theme }) =>
    css`
      background-color: ${theme?.background};
      color: ${theme.color};
    `}

  ${({ onHover }) =>
    onHover &&
    css`
      &:hover {
        width: ${onHover.width};
        height: ${onHover.height};
        border: ${onHover.lineWidth} ${onHover.lineStyle} ${onHover.color};
        border-radius: ${onHover.rounded};
        background-color: ${onHover.background};
        color: ${onHover.color};
      }
    `}

    ${({ onActive }) =>
    onActive &&
    css`
      &:active {
        width: ${onActive.width};
        height: ${onActive.height};
        border: ${onActive.lineWidth} ${onActive.lineStyle} ${onActive.color};
        border-radius: ${onActive.rounded};
        background-color: ${onActive.background};
        color: ${onActive.color};
      }
    `}

  ${({ position, spacing }) =>
    position === 'TopLeft' &&
    css`
      position: absolute;
      top: ${spacing};
      left: ${spacing};
    `}
  ${({ position, spacing }) =>
    position === 'TopCenter' &&
    css`
      position: absolute;
      top: ${spacing};
      left: 50%;
      transform: translateX(-50%);
    `}
  ${({ position, spacing }) =>
    position === 'TopRight' &&
    css`
      position: absolute;
      top: ${spacing};
      right: ${spacing};
    `}
  ${({ position, spacing }) =>
    position === 'MiddleLeft' &&
    css`
      position: absolute;
      top: 50%;
      left: ${spacing};
      transform: translateY(-50%);
    `}
  ${({ position }) =>
    position === 'MiddleCenter' &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
  ${({ position, spacing }) =>
    position === 'MiddleRight' &&
    css`
      position: absolute;
      top: 50%;
      right: ${spacing};
      transform: translateY(-50%);
    `}
  ${({ position, spacing }) =>
    position === 'BottomLeft' &&
    css`
      position: absolute;
      bottom: ${spacing};
      left: ${spacing};
    `}
  ${({ position, spacing }) =>
    position === 'BottomCenter' &&
    css`
      position: absolute;
      bottom: ${spacing};
      left: 50%;
      transform: translateX(-50%);
    `}
  ${({ position, spacing }) =>
    position === 'BottomRight' &&
    css`
      position: absolute;
      right: ${spacing};
      bottom: ${spacing};
    `}
    ${({ position, spacing }) =>
    position === 'LeftTop' &&
    css`
      position: absolute;
      top: ${spacing};
      left: ${spacing};
    `}
    ${({ position, spacing }) =>
    position === 'LeftCenter' &&
    css`
      position: absolute;
      top: 50%;
      left: ${spacing};
      transform: translateY(-50%);
    `}
    ${({ position, spacing }) =>
    position === 'LeftBottom' &&
    css`
      position: absolute;
      left: ${spacing};
      bottom: ${spacing};
    `}
    ${({ position, spacing }) =>
    position === 'RightTop' &&
    css`
      position: absolute;
      top: ${spacing};
      right: ${spacing};
    `}
    ${({ position, spacing }) =>
    position === 'RightCenter' &&
    css`
      position: absolute;
      top: 50%;
      right: ${spacing};
      transform: translateY(-50%);
    `}
    ${({ position, spacing }) =>
    position === 'RightBottom' &&
    css`
      position: absolute;
      right: ${spacing};
      bottom: ${spacing};
    `}
`
