import * as React from 'react'
import styled from 'styled-components'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $createHeadingNode, $createQuoteNode } from '@lexical/rich-text'
import { $setBlocksType } from '@lexical/selection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faBold,
  faItalic,
  faUnderline,
  faLink,
} from '@fortawesome/free-solid-svg-icons'
import useClickOutside from 'hooks/use-click-outside'

import { Button } from 'features/ui/button.component'
import { ListBox, Option } from 'features/ui/select.component'

import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
} from '@lexical/list'
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
} from 'lexical'

import ImagesToolbar from './imagesToolbar'

const formatParagraph = (editor) => {
  editor.update(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      $setBlocksType(selection, () => $createParagraphNode())
    }
  })
}

const formatQuote = (editor) => {
  editor.update(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      $setBlocksType(selection, () => $createQuoteNode())
    }
  })
}

const formatHeading = (editor, headingSize) => {
  editor.update(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      $setBlocksType(selection, () => $createHeadingNode(headingSize))
    }
  })
}

const formatBulletList = (editor) => {
  editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, '')
}

const formatNumberedList = (editor) => {
  editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, '')
}

const BlockFormatDropDown = () => {
  const [isVisible, setIsVisible] = React.useState(false)
  const [blockType, setBlockType] = React.useState('')

  const [editor] = useLexicalComposerContext()

  let domNode = useClickOutside(() => {
    setIsVisible(false)
  })

  const handleIsOpen = (event) => {
    event.preventDefault()
    setIsVisible((prevState) => !prevState)
  }

  return (
    <React.Fragment>
      <DropDown ref={domNode}>
        <Button onClick={handleIsOpen}>
          {blockType === '' ? 'Bullet List' : blockType}
        </Button>
        {isVisible && (
          <DropDownList>
            <Option
              hasChildren
              onClick={() => {
                formatParagraph(editor)
                setBlockType('Normal')
                setIsVisible(false)
              }}
            >
              <span className='text'>Normal</span>
            </Option>
            <Option
              hasChildren
              onClick={() => {
                formatHeading(editor, 'h1')
                setBlockType('h1')
                setIsVisible(false)
              }}
            >
              <span className='text'>Heading 1</span>
            </Option>
            <Option
              hasChildren
              onClick={() => {
                formatHeading(editor, 'h2')
                setBlockType('h2')
                setIsVisible(false)
              }}
            >
              <span className='text'>Heading 2</span>
            </Option>
            <Option
              hasChildren
              onClick={() => {
                formatHeading(editor, 'h3')
                setBlockType('h3')
                setIsVisible(false)
              }}
            >
              <span className='text'>Heading 3</span>
            </Option>
            <Option
              hasChildren
              onClick={() => {
                formatBulletList(editor)
                setBlockType('Bullet List')
                setIsVisible(false)
              }}
            >
              <span className='text'>Bullet List</span>
            </Option>
            <Option
              hasChildren
              onClick={() => {
                formatNumberedList(editor)
                setBlockType('Numbered List')
                setIsVisible(false)
              }}
            >
              <span className='text'>Numbered List</span>
            </Option>
            {/* <Option
              hasChildren
              onClick={() => {
                formatQuote(editor)
                setBlockType('Quote')
                setIsVisible(false)
              }}
            >
              <span className='text'>Quote</span>
            </Option> */}
          </DropDownList>
        )}
      </DropDown>
    </React.Fragment>
  )
}

const FormatElementDropdown = () => {
  const [isVisible, setIsVisible] = React.useState(false)
  const [formatType, setFormatType] = React.useState('')

  const [editor] = useLexicalComposerContext()

  let domNode = useClickOutside(() => {
    setIsVisible(false)
  })

  const handleIsOpen = (event) => {
    event.preventDefault()
    setIsVisible((prevState) => !prevState)
  }

  return (
    <React.Fragment>
      <DropDown ref={domNode}>
        <Button onClick={handleIsOpen}>
          {formatType === '' ? 'Align' : formatType}
        </Button>
        {isVisible && (
          <DropDownList>
            <Option
              hasChildren
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left')
                setFormatType('Left Align')
                setIsVisible(false)
              }}
            >
              <span className='text'>Left Align</span>
            </Option>
            <Option
              hasChildren
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center')
                setFormatType('Center Align')
                setIsVisible(false)
              }}
            >
              <span className='text'>Center Align</span>
            </Option>
            <Option
              hasChildren
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right')
                setFormatType('Right Align')
                setIsVisible(false)
              }}
            >
              <span className='text'>Right Align</span>
            </Option>
            <Option
              hasChildren
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify')
                setFormatType('Justify Align')
                setIsVisible(false)
              }}
            >
              <span className='text'>Justify Align</span>
            </Option>
          </DropDownList>
        )}
      </DropDown>
    </React.Fragment>
  )
}

export const ToolbarPlugin = (props) => {
  const { payload } = props
  const [isVisible, setIsVisible] = React.useState(false)
  const [showDropDown, setShowDropdown] = React.useState(false)
  const [videoSrc, setVideoSrc] = React.useState(null)
  const [isInsertDropDownVisible, setIsInsertDropDownVisible] =
    React.useState(false)

  const [showModal, setShowModal] = React.useState(false)

  const [editor] = useLexicalComposerContext()

  let domNode = useClickOutside(() => {
    setShowDropdown(false)
  })

  const handleIsOpen = (event) => {
    event.preventDefault()
    setShowDropdown((prevState) => !prevState)
  }

  const insertLink = React.useCallback(() => {
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
  }, [editor])

  return (
    <Toolbar>
      <BlockFormatDropDown />
      <Divider />
      <React.Fragment>
        <SelectButton
          type='button'
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
          }}
        >
          <FontAwesomeIcon icon={faBold} />
        </SelectButton>
        <SelectButton
          type='button'
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
          }}
        >
          <FontAwesomeIcon icon={faItalic} />
        </SelectButton>
        <SelectButton
          type='button'
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
          }}
        >
          <FontAwesomeIcon icon={faUnderline} />
        </SelectButton>
        <Divider />
        <div ref={domNode}>
          <Button onClick={handleIsOpen}>
            <FontAwesomeIcon style={{ marginRight: '8px' }} icon={faPlus} />
            Insert
          </Button>
          <DropDown>
            {showDropDown && (
              <DropDownList>
                <ImagesToolbar
                  handleIsOpen={handleIsOpen}
                  setShowDropdown={setShowDropdown}
                />
              </DropDownList>
            )}
          </DropDown>
        </div>
        <Divider />
        <FormatElementDropdown />
      </React.Fragment>
    </Toolbar>
  )
}

const Toolbar = styled.div`
  display: flex;
  margin-bottom: 0;
  background: #fff;
  border: 1px solid #444;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  border-bottom: 1px solid #eee;
  background-color: #444;
  padding: 0 6px;
  align-items: center;
  overflow: auto;
  height: 48px;
  font-size: 14px;
`

const DropDown = styled.div`
  z-index: 1000;
  margin: 0;
  padding: 0;
`

const DropDownList = styled.ul`
  z-index: 1000;
  position: absolute;
  top: 48px;
  margin: 0;
  padding: 8px;
  list-style-type: none;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  width: 214px;
  min-height: 40px;
  background-color: #fff;
`

const SelectButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 36px;
  border: 0;
  background: none;
  border-radius: 8px;
  padding: 0;
  cursor: pointer;
  color: white;

  &:hover {
    background-color: #eee;
    color: #444;
  }
`

const Modal = styled.div`
  z-index: 1000;
  position: absolute;
  top: 48px;
  margin: 0 0 0 0;
  padding: 12px 12px;
  list-style-type: none;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  width: 214px;
  min-height: 40px;
  background-color: #fff;

  input {
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 8px 12px;
    border-radius: 15px;
    background-color: #eee;
    font-size: 14px;
    color: rgb(5, 5, 5);
    border: 0;
    outline: 0;
    position: relative;
    font-family: inherit;
  }
`

const Divider = styled.div`
  width: 1px;
  background-color: #eee;
  margin: 0 6px;
  height: 100%;
`
