import React from 'react'
import styled from 'styled-components'

import Button from '@mui/material/Button'

export const MuiButton = ({ variant, className, style, onClick, children }) => {
  return (
    <StyledButton
      variant={variant}
      className={className}
      style={style}
      onClick={onClick}
    >
      <span>{children}</span>
    </StyledButton>
  )
}

const StyledButton = styled.button`
  && {
    display: flex;
    align-items: center;
    background-color: #d0b1b6;
    color: white;
    vertical-align: middle;

    font-family: 'VL Arch Light Cond Light';
    font-size: 16px;
    text-transform: uppercase;
    line-height: 0;
    width: 200px;
    height: 60px;
    border: none;
    border-radius: 0;
    cursor: pointer;

    span {
      margin: 0 auto;
    }

    &:hover {
      background-color: #969293;
    }
  }
`
