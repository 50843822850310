import styled, { css } from 'styled-components'
import { Button, Link } from 'react-aria-components'

import { buttonColors } from './button.colors'
import {} from './button.sizes'
import { buttonVariants } from './button.variants'

const buttonConfig = css``

const linkConfig = css`
  text-decoration: none;
`

export const StyledButton = styled(Button)`
  ${buttonConfig}
  ${({ variant }) => buttonVariants[variant]}
`

export const StyledLink = styled(Link)`
  ${linkConfig}
  /* ${({ color }) => buttonColors[color]} */
  ${({ variant }) => buttonVariants[variant]}
  height: 60px;
`
