import React from 'react'
import { Label as AriaLabel, Text } from 'react-aria-components'

import './label.css'

const Label = (props) => {
  const { children, description } = props

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <AriaLabel>{children}</AriaLabel>
      {description && <Text slot='description'>{description}</Text>}
    </div>
  )
}

export default Label
