// import styled, { css } from 'styled-components'

import styled, { css } from 'styled-components'

import { theme } from 'theme'

// export const Container = styled.div`
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   width: calc(100% - 120px);
//   height: calc(100vh - 120px);
//   border: 2px solid ${theme.colors.quaternary};
//   margin: 60px;
//   padding: 30px;
//   overflow-y: scroll;
// `

// export const Logo = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   z-index: 5000;
//   position: fixed;
//   top: 10px;
//   left: 50%;
//   transform: translateX(-50%);
//   flex-shrink: 0;
//   width: 100px;
//   height: 100px;
//   border-radius: 50%;
//   border: 2px solid ${theme.colors.quaternary};
//   background-color: #fff;
//   cursor: pointer;

//   img {
//     width: 45px;
//   }
// `

// export const TitleWrapper = styled.div`
//   z-index: 1;
//   position: absolute;
//   left: 2%;
//   top: 50%;
//   transform: translateY(-50%);
//   height: calc(100% - 120px);
// `

// export const Title = styled.h1`
//   margin: 0;
//   font-family: 'Brittany Signature';
//   font-size: 30px;
//   color: white;
// `

// export const TextContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   padding: 0;
//   /* gap: 2%; */
//   height: 100%;
// `

// export const TextWrapper = styled.div`
//   width: 100%;
//   height: calc(${({ height }) => height} * 2);
//   display: flex;
//   align-items: center;
// `

// export const Text = styled.p`
//   margin: 0;
//   font-family: 'VL Arch Light Cond Light';
//   color: ${theme.colors.quinary};
//   text-align: right;
// `

// export const Row = styled.div`
//   display: flex;
//   align-items: center;
//   width: 100%;
//   height: 100%;
// ` // 599,4

// export const Right = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin: auto 0;
//   margin-right: 2%;
//   margin-left: calc(${({ height }) => height} + 2%);
//   width: 65%;
//   height: calc(100% - 120px);
// `

// export const Left = styled.div`
//   position: fixed;
//   top: 60px;
//   left: 60px;
//   bottom: 60px;
//   z-index: -1;
//   width: 25%;
//   background-color: ${theme.colors.primary};
// `

// export const Well = styled.div`
//   width: 100%;
//   display: flex;
//   align-items: center;
//   /* width: calc(75% + (152px * 2) + 15px - (152px / 2 + 7.5px)); */
//   min-height: 152px;
//   background-color: red;
//   margin: 0 0 0 calc(25% + 152px - (152px / 2) - 152px);
//   padding-right: 15px;
//   z-index: 1000;
// `

// export const Content = styled.div`
//   display: flex;
//   align-items: center;
//   flex: 1;
//   min-height: 152px;
//   margin-right: 15px;
//   padding: 15px;
//   /* border: 2px solid ${theme.colors.quaternary}; */
// `

// export const Section = styled.div`
//   display: flex;
//   gap: 15px;
// `

// export const Item = styled.div`
//   display: flex;
//   flex: 0 0 auto;
//   height: 152px;
//   aspect-ratio: 1 / 1;
//   border-radius: 50%;
// `

// export const Flex = styled.div`
//   display: flex;
//   flex-direction: row;
//   width: auto;
//   height: calc(100% - 120px);
//   margin: auto 0;
//   /* gap: 8%; */
//   margin-left: auto;
//   margin-right: calc(-${({ height }) => height} + 4%);
//   gap: 4%;
// `

// export const Col = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   height: 100%;
// `

// export const Icons = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 30px;
//   position: absolute;
//   top: 50%;
//   right: -75px;
//   transform: translateY(-50%);
// `

// export const Card = styled.div`
//   display: flex;
//   gap: 30px;
//   height: 25%;
// `

// export const Icon = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: white;
//   color: ${theme.colors.primary};
//   border: 4px solid ${theme.colors.primary};
//   border-radius: 50%;
//   width: 100%;
//   height: 100%;
//   /* aspect-ratio: 1 / 1;
//   width: 150px; */
//   font-size: 45px;

//   img {
//     width: 30%;
//     object-fit: cover;
//   }
// `

// export const Subtitle = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: ${theme.colors.tertiary};
//   color: #fff;
//   border: 4px solid ${theme.colors.primary};
//   border-radius: 50%;

//   width: 100%;
//   height: 100%;
//   font-family: 'VL Arch Light Cond Light';
//   font-size: 16px;
//   text-transform: uppercase;
// `

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  /* width: calc(100% - 120px);
  height: calc(100vh - 120px); */
  height: calc(100vh - 120px);
  border: 2px solid ${theme.colors.quaternary};
  margin: 60px;
  /* padding: 30px; */
  padding-bottom: 30px;
  overflow-y: scroll;
`

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5000;
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid ${theme.colors.quaternary};
  background-color: #fff;
  cursor: pointer;

  img {
    width: 45px;
  }
`

export const Title = styled.div`
  margin: 40px 30px 25px auto;
`

export const TitleContent = styled.h1`
  margin: 0;
  font-family: 'Brittany Signature';
  font-size: 48px;
  color: ${theme.colors.quinary};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-right: 30px;
`

export const Left = styled.div`
  position: fixed;
  top: 60px;
  left: 60px;
  bottom: 60px;
  z-index: -1;
  /* height: 100%; */
  width: 350px;
  background-color: ${theme.colors.primary};
`

export const Well = styled.div`
  display: flex;
  gap: 15px;
  height: 152px;
  padding: 0 15px;
  ${({ itemHeight }) => css`
    margin-left: calc(
      350px - (${itemHeight} + 15px) - (${itemHeight} / 2 + 15px)
    );
  `}
`

export const TextContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  font-family: 'VL Arch Light Cond Light';
  font-size: 18px;
  color: ${theme.colors.quinary};
`

export const Section = styled.div`
  display: flex;
  gap: 15px;
`

export const Item = styled.div`
  display: flex;
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  border: 4px solid ${theme.colors.primary};
  overflow: hidden;
`

export const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: ${theme.colors.tertiary};
  font-family: 'VL Arch Light Cond Light';
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  background-color: #fff;

  img {
    width: 30%;
    object-fit: cover;
  }
`
