import * as React from 'react'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faFileArrowUp } from '@fortawesome/free-solid-svg-icons'
// import { pdfToImage } from 'react-pdf-to-image'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import Document from 'features/ui/document.component'
import MediaPlayer from './mediaPlayer.component'

const Preview = (props) => {
  const { multiple, files, handleDelete } = props

  const PreviewTypes = (props) => {
    const { file, type, index, styles } = props

    if (type === 'video/mp4') {
      return (
        <div className='previewVideo'>
          <MediaPlayer file={file} />
        </div>
      )
    }

    if (type === 'image/jpeg' || type === 'image/png') {
      return (
        <div className='previewImage'>
          <img src={file} loading='lazy' />
        </div>
      )
    }

    if (type === 'application/pdf') {
      return (
        <div className='previewPDF'>
          <Document file={file} />
        </div>
      )
    }

    return null
  }

  const transformFiles = () => {
    if (Array.isArray(files)) {
      return [...files].map((file, index) => {
        if (!file) return
        const { type } = file

        return (
          <Container key={index}>
            <PreviewTypes
              file={
                file instanceof Blob
                  ? URL.createObjectURL(file)
                  : file.downloadURL
              }
              type={type}
              index={index}
            />
            {multiple && (
              <DeleteBtn type='button' onClick={() => handleDelete(index)}>
                <FontAwesomeIcon icon={faTrash} />
              </DeleteBtn>
            )}
          </Container>
        )
      })
    } else {
      return (
        <PreviewTypes
          file={
            files instanceof Blob
              ? URL.createObjectURL(files?.downloadURL)
              : files?.downloadURL
          }
          type={files?.type}
        />
      )
    }
  }

  return <>{transformFiles()}</>
}

export default Preview

const Container = styled.div`
  position: relative;
  //width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
`

const DeleteBtn = styled.button`
  z-index: 5;
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: #444;
  color: white;
  margin: 20px;
  cursor: pointer;
`
