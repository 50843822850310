import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  PROCESS_PAGE,
  QUESTION_PAGE,
  PUZZLE_PAGE,
  SOLUTION_PAGE,
  RESULT_PAGE,
} from 'pages'
import logo from 'assets/logo.png'
import * as S from './styles'

const { heading: PROCESS_HEADING, text: PROCESS_TEXT } = PROCESS_PAGE
const {
  path: QUESTION_PATH,
  heading: QUESTION_HEADING,
  text: QUESTION_TEXT,
  icon: QUESTION_ICON,
} = QUESTION_PAGE
const {
  path: PUZZLE_PATH,
  heading: PUZZLE_HEADING,
  text: PUZZLE_TEXT,
  icon: PUZZLE_ICON,
} = PUZZLE_PAGE
const {
  path: SOLUTION_PATH,
  heading: SOLUTION_HEADING,
  text: SOLUTION_TEXT,
  icon: SOLUTION_ICON,
} = SOLUTION_PAGE
const {
  path: RESULT_PATH,
  heading: RESULT_HEADING,
  text: RESULT_TEXT,
  icon: RESULT_ICON,
} = RESULT_PAGE

const DesktopLaptop = () => {
  let navigate = useNavigate()

  const itemRef = React.useRef(null)
  const [height, setHeight] = React.useState(0)

  const routeChange = () => {
    navigate(`/`)
  }

  React.useEffect(() => {
    const itemHeight = itemRef.current?.getBoundingClientRect()
    setHeight(`${itemHeight?.height}px`)
  }, [height])

  return (
    <S.Container>
      <S.Logo onClick={routeChange}>
        <img src={logo} alt='Logo' />
      </S.Logo>
      <S.Title>
        <S.TitleContent>{PROCESS_HEADING}</S.TitleContent>
      </S.Title>

      <S.Content>
        <S.Left />
        <S.Well itemHeight={height}>
          <S.Item ref={itemRef}>
            <S.Heading>{QUESTION_HEADING}</S.Heading>
          </S.Item>
          <S.Item>
            <S.Icon>{QUESTION_ICON}</S.Icon>
          </S.Item>
          <S.TextContent>{QUESTION_TEXT}</S.TextContent>
        </S.Well>
        <S.Well itemHeight={height}>
          <S.Item ref={itemRef}>
            <S.Heading>{PUZZLE_HEADING}</S.Heading>
          </S.Item>
          <S.Item>
            <S.Icon>{PUZZLE_ICON}</S.Icon>
          </S.Item>
          <S.TextContent>{PUZZLE_TEXT}</S.TextContent>
        </S.Well>
        <S.Well itemHeight={height}>
          <S.Item ref={itemRef}>
            <S.Heading>{SOLUTION_HEADING}</S.Heading>
          </S.Item>
          <S.Item>
            <S.Icon>{SOLUTION_ICON}</S.Icon>
          </S.Item>
          <S.TextContent>{SOLUTION_TEXT}</S.TextContent>
        </S.Well>
        <S.Well itemHeight={height}>
          <S.Item ref={itemRef}>
            <S.Heading>{RESULT_HEADING}</S.Heading>
          </S.Item>
          <S.Item>
            <S.Icon>{RESULT_ICON}</S.Icon>
          </S.Item>
          <S.TextContent>{RESULT_TEXT}</S.TextContent>
        </S.Well>
      </S.Content>
    </S.Container>
  )
}

export default DesktopLaptop
