import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Tiptap from 'features/ui/editor/editor.component'

import EditIcon from '@mui/icons-material/Edit'
import { ABOUT_PAGE } from 'pages'
import logo from 'assets/logo.png'
import * as S from './styles'
import * as Global from 'pages/pages.styled'
import { theme } from 'theme'
import aboutImage from 'assets/kim/kim-about.jpg'

const {
  path: ABOUT_PATH,
  heading: ABOUT_HEADING,
  icon: ABOUT_ICON,
  text: ABOUT_TEXT,
} = ABOUT_PAGE

const { complete: ABOUT_COMPLETE } = ABOUT_TEXT

const DesktopLaptop = () => {
  const { data } = useSelector((store) => store.about)
  const { user } = useSelector((state) => state.auth)

  let navigate = useNavigate()

  const routeChange = () => {
    navigate(`/`)
  }

  const adminRoute = () => navigate('/about/admin')

  return (
    <S.Container>
      <S.Logo onClick={routeChange}>
        <img src={logo} alt='Logo' />
      </S.Logo>
      {/* {user && (
        <Global.IconButton
          onClick={adminRoute}
          position='TopRight'
          spacing='15px'
          size={{ width: '50px', height: '50px' }}
          rounded='16px'
          outlined={{
            lineWidth: '1px',
            lineStyle: 'solid',
            color: theme.colors.secondary,
          }}
          theme={{ background: 'transparent', color: theme.colors.secondary }}
          hoverState={{ background: theme.colors.secondary, color: '#fff' }}
        >
          <EditIcon />
        </Global.IconButton>
      )} */}
      <S.Left>
        <img src={aboutImage} alt='Kim' />
      </S.Left>
      <S.Right>
        <S.Title>{ABOUT_HEADING}</S.Title>
        {/* <Tiptap value={data[0]?.description} editable={false} isAdmin /> */}
        <S.Text>{ABOUT_COMPLETE}</S.Text>
      </S.Right>
      {/* <Box width='100%'>{ABOUT_ICON}</Box>
      <Paper>
        <Block>
          <h1>{ABOUT_HEADING}</h1>
          <p>{ABOUT_COMPLETE}</p>
        </Block>
      </Paper> */}
    </S.Container>
  )
}

export default DesktopLaptop
