import React, { forwardRef } from 'react'

export const Carousel = (props) => {
  const { children } = props

  return <div className='carousel'>{children}</div>
}

export const CarouselContent = (props) => {
  const { children, currentIndex } = props

  return (
    <div
      className='carouselContent'
      style={{ transform: `translateX(${-currentIndex * 100}%)` }}
    >
      {children}
    </div>
  )
}

export const CarouselItem = ({ children }) => {
  return <div className='carouselItem'>{children}</div>
}

export const CarouselIcon = forwardRef((props, ref) => {
  const { children, onClick, className } = props

  return (
    <button ref={ref} onClick={onClick} className={className}>
      {children}
    </button>
  )
})
