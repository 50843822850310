import {
  DOMConversionOutput,
  DOMExportOutput,
  DecoratorNode,
  EditorConfig,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  SerializedEditor,
  SerializedLexicalNode,
  Spread,
  createEditor,
} from 'lexical'
import * as React from 'react'

const VideoPlayer = (props) => {
  const { url } = props
  return <video src={url} height='200px' controls />
}

export type SerializedVideoNode = Spread<
  {
    url: string
    caption: SerializedEditor
  },
  SerializedLexicalNode
>

export class VideoNode extends DecoratorNode<JSX.Element> {
  __url: string
  __caption: LexicalEditor

  static getType(): string {
    return 'video'
  }

  static clone(node: VideoNode): VideoNode {
    return new VideoNode(node.__url, node.__caption, node.__key)
  }

  static importJSON(serializedNode: SerializedVideoNode): VideoNode {
    const videoNode = new VideoNode(serializedNode.url)

    const caption = serializedNode.caption
    const nestedEditor = videoNode.__caption
    const editorState = nestedEditor.parseEditorState(caption.editorState)
    if (!editorState.isEmpty()) {
      nestedEditor.setEditorState(editorState)
    }

    return videoNode
  }

  constructor(url: string, caption?: LexicalEditor, key?: string) {
    super(key)
    this.__url = url
    this.__caption = caption || createEditor()
  }

  exportJSON(): SerializedVideoNode {
    return {
      caption: this.__caption.toJSON(),
      type: 'video',
      url: this.getUrl(),
      version: 1,
    }
  }

  createDOM(config: EditorConfig): HTMLElement {
    const div = document.createElement('div')
    div.style.display = 'contents'
    return div
  }

  updateDOM(): false {
    return false
  }

  decorate(): JSX.Element {
    return <VideoPlayer url={this.__url} />
  }

  getUrl(): string {
    return this.__url
  }
}

export function $createVideoNode(url: string): VideoNode {
  return new VideoNode(url)
}

export function $isVideoNode(node: LexicalNode): boolean {
  return node instanceof VideoNode
}
