import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { resetCurrentData } from 'features/approach/approachSlice'
import {
  APPROACH_PAGE,
  ADVISE_PAGE,
  STYLING_PAGE,
  TWO_DESIGN_PAGE,
  THREE_DESIGN_PAGE,
} from 'pages'
import Tiptap from 'features/ui/editor/editor.component'
import logo from 'assets/logo.png'
import EditIcon from '@mui/icons-material/Edit'
import * as S from './styles'
import * as Global from 'pages/pages.styled'
import { theme } from 'theme'

const { heading: APPROACH_HEADING } = APPROACH_PAGE
const {
  heading: ADVISE_HEADING,
  text: ADVISE_TEXT,
  icon: ADVISE_ICON,
} = ADVISE_PAGE
const {
  heading: STYLING_HEADING,
  text: STYLING_TEXT,
  icon: STYLING_ICON,
} = STYLING_PAGE
const {
  heading: TWO_DESIGN_HEADING,
  text: TWO_DESIGN_TEXT,
  icon: TWO_DESIGN_ICON,
} = TWO_DESIGN_PAGE
const {
  heading: THREE_DESIGN_HEADING,
  text: THREE_DESIGN_TEXT,
  icon: THREE_DESIGN_ICON,
} = THREE_DESIGN_PAGE

const DesktopLaptop = () => {
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.auth)
  const { data } = useSelector((state) => state.approach)

  let navigate = useNavigate()

  const itemRef = React.useRef(null)

  const [height, setHeight] = React.useState(0)

  console.log('Check: ', height)
  const routeChange = () => {
    navigate(`/`)
  }

  const adminRoute = () => {
    dispatch(resetCurrentData())
    navigate('/approach/admin')
  }

  return (
    <S.Container>
      <S.Logo onClick={routeChange}>
        <img src={logo} alt='Logo' />
      </S.Logo>
      <S.TitleWrapper>
        <S.Title>{APPROACH_HEADING}</S.Title>
      </S.TitleWrapper>
      <S.Right />
      <S.Left>
        <S.Well>
          <S.Content>
            <S.TextContent>{ADVISE_TEXT}</S.TextContent>
          </S.Content>
          <S.Section>
            <S.Item>
              <S.Icon>{ADVISE_ICON}</S.Icon>
            </S.Item>
            <S.Item>
              <S.Subtitle>{ADVISE_HEADING}</S.Subtitle>
            </S.Item>
          </S.Section>
        </S.Well>
        <S.Well>
          <S.Content>
            <S.TextContent>{STYLING_TEXT}</S.TextContent>
          </S.Content>
          <S.Section>
            <S.Item>
              <S.Icon>{STYLING_ICON}</S.Icon>
            </S.Item>
            <S.Item>
              <S.Subtitle>{STYLING_HEADING}</S.Subtitle>
            </S.Item>
          </S.Section>
        </S.Well>
        <S.Well>
          <S.Content>
            <S.TextContent>{TWO_DESIGN_TEXT}</S.TextContent>
          </S.Content>
          <S.Section>
            <S.Item>
              <S.Icon>{TWO_DESIGN_ICON}</S.Icon>
            </S.Item>
            <S.Item>
              <S.Subtitle>{TWO_DESIGN_HEADING}</S.Subtitle>
            </S.Item>
          </S.Section>
        </S.Well>
        <S.Well>
          <S.Content>
            <S.TextContent>{THREE_DESIGN_TEXT}</S.TextContent>
          </S.Content>
          <S.Section>
            <S.Item>
              <S.Icon>{THREE_DESIGN_ICON}</S.Icon>
            </S.Item>
            <S.Item>
              <S.Subtitle>{THREE_DESIGN_HEADING}</S.Subtitle>
            </S.Item>
          </S.Section>
        </S.Well>
        <S.Well>
          <S.Content>
            <S.TextContent>
              <h3>Extra’s</h3> Heb je graag zelf de touwtjes in handen om jouw
              ontwerp te realiseren? Of wil je graag ontzorgd worden en geef je
              mij het vertrouwen om alles in goede banen te leiden? Alles is
              mogelijk en bespreekbaar. Ondersteuning kan ook nog veel
              gedetailleerder gaan; ik kan je ook bijstaan met advies over
              <ul>
                <li>Verlichtingsplannen</li>
                <li>meubelplannen</li>
                <li>groen plannen</li>
                <li>technische uitwerking van het maatwerk</li>
                <li>video realisatie en begeleiding</li>
              </ul>
              Wil je hier meer over weten? Neem dan zeker contact met me op.
            </S.TextContent>
          </S.Content>
          <S.Section>
            <S.Item>
              <S.Icon style={{ visibility: 'hidden' }} />
            </S.Item>
            <S.Item>
              <S.Subtitle style={{ visibility: 'hidden' }} />
            </S.Item>
          </S.Section>
        </S.Well>
      </S.Left>

      {/* {user && (
        <Global.IconButton
          onClick={adminRoute}
          position='BottomRight'
          spacing='15px'
          size={{ width: '50px', height: '50px' }}
          rounded='16px'
          outlined={{
            lineWidth: '1px',
            lineStyle: 'solid',
            color: '#fff',
          }}
          theme={{ background: 'transparent', color: '#fff' }}
          hoverState={{ background: theme.colors.secondary, color: '#fff' }}
        >
          <EditIcon />
        </Global.IconButton>
      )} */}
    </S.Container>
  )
}

export default DesktopLaptop
