import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { Normalize } from 'styled-normalize'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import 'utils/firebase/firebase.utils.js'
import { store, persistor } from 'store'
import { AppProvider } from 'context/state.context'
import { theme } from 'theme'
import App from 'App'
import GlobalStyle from 'globalStyle'

import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Normalize />
          <GlobalStyle />
          <BrowserRouter>
            <AppProvider>
              <App />
            </AppProvider>
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
)
