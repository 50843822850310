import React from 'react'
import styled, { css } from 'styled-components'

export const Box = (props) => {
  const { style, className, gap, width, height, placement, children } = props
  return (
    <Container
      style={style}
      className={className}
      gap={gap}
      width={width}
      height={height}
      placement={placement}
    >
      {children}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: ${({ width }) => width};
  height: ${({ height }) => height};

  ${({ placement }) =>
    (placement === 'top-left' && css``) ||
    (placement === 'top-right' && css``) ||
    (placement === 'bottom-left' && css``) ||
    (placement === 'bottom-right' &&
      css`
        margin-top: auto;
        margin-left: auto;
        align-self: flex-end;
      `) ||
    (placement === 'bottom-center' &&
      css`
        margin-top: auto;
        margin: auto auto 0 auto;
      `)}
`
