import styled from 'styled-components'

export const Container = styled.div``

export const Menu = styled.div`
  position: relative;
  background: #444;
  padding: 0 0 0 6px;
  display: flex;
  align-items: center;
  gap: 6px;
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
  border-radius: 10px;
  height: 44px;
  width: 100%;
`

export const Tabs = styled.div`
  display: flex;
`

export const TabList = styled.div`
  display: flex;
  gap: 6px;
  padding: 8px 12px;
`

export const Tab = styled.div`
  padding: 8px 12px;
  background-color: #eee;
  border-radius: 50px;
  font-size: 14px;
  color: rgb(119, 119, 119);
  cursor: pointer;

  &:hover {
    background-color: rgba(104, 206, 248, 0.3);
  }
`

export const Button = styled.button`
  border: 0;
  display: flex;
  gap: 8px;
  background: #fff;
  border-radius: 8px;
  margin: 0;
  padding: 8px;
  cursor: pointer;
  vertical-align: middle;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: #eee;
  }

  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
  }
`

export const Hr = styled.hr`
  margin: 6px 0;
  width: 1px;
  border: none;
  min-height: calc(100% - 16px);
  background-color: #fff;
`

export const DialogButton = styled.button`
  width: 35px;
  height: 35px;
  border: none;
  background-color: transparent;
  cursor: pointer;
`

export const DialogIcon = styled.img`
  vertical-align: -0.25em;
`

export const InputColor = styled.div`
  position: relative;

  input {
    display: inline-block;
    //z-index: 5;
    position: absolute;
    top: 8px;
    left: 0;
    height: 32px;
    padding-bottom: 8px;
    //margin-bottom: 8px;
    border: none;
    visibility: hidden;
  }
`

export const ColorOption = styled.div`
  display: flex;
  gap: 8px;
`

export const Color = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: ${({ color }) => color && color};
`

export const Paragraph = styled.p`
  margin: 0;
  //margin: 0 0 0 8px;
`

export const Wrapper = styled.div`
  position: relative;
`

export const DropdownWrapper = styled.div``

export const DropdownParagraph = styled.p`
  margin: 0;
  padding: 0;
`

export const DropdownIcon = styled.img`
  margin: 0 8px 0 0;
`
