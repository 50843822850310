import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { ABOUT_PAGE } from 'pages'
import Tab from 'layouts/tab'
import { theme } from 'theme'
import aboutImage from 'assets/kim/kim-about.jpg'

const {
  path: ABOUT_PATH,
  heading: ABOUT_HEADING,
  icon: ABOUT_ICON,
  text: ABOUT_TEXT,
} = ABOUT_PAGE

const { preview: ABOUT_PREVIEW, complete: ABOUT_COMPLETE } = ABOUT_TEXT

const Mobile = () => {
  const [path, setPath] = React.useState('')
  let navigate = useNavigate()

  const routeChange = () => {
    setPath('/about')
  }

  return (
    <React.Fragment>
      {path !== '' && (
        <Tab
          setPath={setPath}
          iconType='photo'
          icon={ABOUT_ICON}
          name={ABOUT_HEADING}
          text={ABOUT_COMPLETE}
        />
      )}
      <Container>
        <Element>{ABOUT_ICON}</Element>
        <Wrapper>
          <Title>{ABOUT_HEADING}</Title>
          <p>{ABOUT_PREVIEW}</p>

          <Button
            variant='contained'
            className='mobile-button'
            onClick={routeChange}
          >
            Meer weten
          </Button>
        </Wrapper>
      </Container>
    </React.Fragment>
  )
}

export default Mobile

const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'VL Arch Light Cond Light';
  font-size: 20px;
  color: ${theme.colors.quinary};
  padding: 20px;

  span {
    display: block;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    padding: 0;
  }
`

const Title = styled.h1`
  margin: 10px 0 20px 0;
  font-family: 'Brittany Signature';
  font-size: 32px;
`

const Element = styled.div`
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  aspect-ratio: 1 / 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Button = styled.button`
  margin: 0;
  margin-top: 20px;
  padding: 0;
  width: 120px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: ${theme.colors.tertiary};
  color: white;
  font-size: 16px;
  cursor: pointer;
`
