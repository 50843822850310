import * as React from 'react'
import styled from 'styled-components'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'

import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { ListNode, ListItemNode } from '@lexical/list'
import { $setBlocksType } from '@lexical/selection'
import {
  $createParagraphNode,
  $getNodes,
  $getRoot,
  $getSelection,
  $isRangeSelection,
} from 'lexical'

import { ToolbarPlugin } from 'features/ui/editor/plugins/toolbarPlugin'
import { ImageNode } from './editor/nodes/imageNode'
import { VideoNode } from './editor/nodes/videoNode'

import ImagesPlugin from './editor/plugins/imagePlugin'
import { VideoPlugin } from './editor/plugins/videosPlugin'
import QuoteLeft from '../../assets/icons/QuoteLeft.svg'

import { theme as styledTheme } from 'theme'

const theme = {
  paragraph: 'editor-paragraph',
  quote: 'editor-quote',
  heading: {
    h1: 'editor-heading-h1',
    h2: 'editor-heading-h2',
    h3: 'editor-heading-h3',
  },
  text: {
    bold: 'editor-textBold',
    italic: 'editor-textItalic',
    underline: 'editor-textUnderline',
  },
  list: {
    ol: 'editor-list-ol',
    ul: 'editor-list-ul',
  },
}

const onError = (error) => {
  console.error(error)
}

const LexicalOnChangePlugin = (props) => {
  const [editor] = useLexicalComposerContext()
  const { onChange } = props

  React.useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      onChange(editorState.toJSON())
    })
  }, [onChange, editor])
}

const LexicalGetValuePlugin = ({ value }) => {
  const [editor] = useLexicalComposerContext()

  React.useEffect(() => {
    if (value) {
      const editorState = editor.parseEditorState(value)
      return editor.setEditorState(editorState)
    }
  }, [editor])
}

const LexicalCreateParagraphNodePlugin = () => {
  const [editor] = useLexicalComposerContext()

  React.useEffect(() => {
    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode())
      }
    })
  }, [editor])
}

const Editor = React.forwardRef((props, ref) => {
  const { className, name, value, onChange, data, isEditable, getVideo } = props

  const [placeholder, setPlaceholder] = React.useState('Begin writing...')

  const initialConfig = {
    namespace: name,
    theme,
    onError,
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      ImageNode,
      VideoNode,
      QuoteNode,
    ],
    editable: isEditable,
  }

  return (
    <Container>
      <LexicalComposer initialConfig={initialConfig}>
        {initialConfig.editable ? (
          <React.Fragment>
            <ToolbarPlugin />
            <ListPlugin />
            <Wrapper>
              <RichTextPlugin
                contentEditable={<ContentEditable className={'editor-input'} />}
                placeholder={
                  <div className='editor-placeholder'>{placeholder}</div>
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
            </Wrapper>
            <HistoryPlugin />
            {/* <OnChangePlugin onChange={(editorState) => onChange(editorState)} /> */}
            <LexicalOnChangePlugin
              onChange={(editorState) => onChange(editorState)}
            />
            <LexicalGetValuePlugin value={value} />
            <ImagesPlugin />
            {/* <VideoPlugin getVideo={getVideo} /> */}
            <LexicalCreateParagraphNodePlugin />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Wrapper className={className}>
              <RichTextPlugin
                contentEditable={
                  <ContentEditable
                    ref={ref}
                    className={className ? className : 'editor-input'}
                  />
                }
                placeholder={
                  <div className='editor-placeholder'>Begin writing...</div>
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
              <span className='editor-target' />
            </Wrapper>
            <LexicalGetValuePlugin value={value} />
            <ImagesPlugin />
            {/* <VideoPlugin getVideo={getVideo} /> */}
            <LexicalCreateParagraphNodePlugin />
          </React.Fragment>
        )}
      </LexicalComposer>
    </Container>
  )
})

export default Editor

const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
`

const Wrapper = styled.div`
  position: relative;

  .editor-input {
    counter-reset: all;
    background-color: white;
    //display: inline-block;
    width: 100%;
    min-height: 150px;
    border: 1px solid #444;
    border-top: none;
    resize: none;
    cursor: text;
    display: block;

    outline: 0px;
    padding: 16px;
    //user-select: text;
    font-size: 16px;
    /* white-space: pre-wrap;
    word-break: break-word; */
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    &:focus {
      outline: none;
    }
  }

  .editor-placeholder {
    font-size: 15px;
    color: #999;
    //overflow: hidden;
    position: absolute;
    top: 16px;
    left: 16px;
  }

  .editor-paragraph {
    margin: 0;
    font-family: 'VL Arch Light Cond Light';
    font-size: 20px;
    color: ${styledTheme.colors.quinary};
    //line-height: 1.3;
  }

  .editor-heading-h1 {
    text-align: left;
    margin: 20px 0;
    padding: 0;
    font-family: 'Brittany Signature';
    font-size: 32px;
    color: ${styledTheme.colors.quinary};
  }

  .editor-heading-h2 {
    margin: 20px 0;
    padding: 0;
    font-family: 'Brittany Signature';
    font-size: 30px;
    color: ${styledTheme.colors.quinary};
  }

  .editor-heading-h3 {
    margin: 20px 0;
    padding: 0;
    font-family: 'Brittany Signature';
    font-size: 28px;
    color: ${styledTheme.colors.quinary};
  }

  .editor-list-ol {
    margin: 20px 0;
    padding: 0;
    font-family: 'Brittany Signature';
    font-size: 32px;
    color: ${styledTheme.colors.quinary};
    list-style-position: inside;
    list-style: none;

    li {
      margin: 0;
      padding: 0;
      &::before {
        counter-increment: all;
        content: counter(all);
        margin: 0 20px 0 0;
        padding: 0;
      }
    }
  }

  .editor-list-ul {
    margin: 20px 0;
    padding: 0;
    font-family: 'Brittany Signature';
    font-size: 32px;
    color: ${styledTheme.colors.quinary};

    list-style-position: inside;
    list-style: none;

    /* &::before {
      margin: 0 20px 0 0;
      padding: 0;
      content: '•';
    }
  } */
    li::before {
      content: '•';
      margin: 0 20px 0 0;
      padding: 0;
    }
  }

  .editor-textBold {
    text-shadow: 0px 0px 1px ${styledTheme.colors.quinary};
  }

  .editor-textItalic {
  }

  .editor-textUnderline {
    text-decoration: underline;
  }

  .editor-quote {
    background-image: url('../../assets/icons/QuoteLeft.svg');

    height: 100px;
    margin: 0;
  }
`
