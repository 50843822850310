import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInstagramSquare,
  faFacebookSquare,
} from '@fortawesome/free-brands-svg-icons'

import * as S from './styles'
import { sendEmail } from 'service/emailService'
import contactImage from 'assets/kim/kim-contact.jpg'

const DesktopLaptop = () => {
  const form = React.useRef()

  const onSendEmail = (e) => {
    e.preventDefault()
    sendEmail(form)
  }

  return (
    <S.Wrapper>
      <S.Container>
        <S.Column>
          <S.Form ref={form} onSubmit={onSendEmail}>
            <S.Title>Contact</S.Title>
            <S.Input type='text' name='naam' placeholder='NAAM' />
            <S.Input type='email' name='eMail' placeholder='E-MAIL ADRES' />
            <S.Input
              type='text'
              name='telefoonnummer'
              placeholder='TELEFOONNUMMER'
            />
            <S.Input type='text' name='onderwerp' placeholder='ONDERWERP' />
            <S.Textarea name='bericht' placeholder='BERICHT' />
            <S.Block>
              <S.Brands>
                <span className='icon'>
                  <a href='https://www.instagram.com/beleefkim/'>
                    <FontAwesomeIcon icon={faInstagramSquare} />
                  </a>
                </span>
                <span className='icon'>
                  <a href='https://www.facebook.com/kim.jansen.142'>
                    <FontAwesomeIcon icon={faFacebookSquare} />
                  </a>
                </span>
              </S.Brands>
              <S.Button type='submit'>NEEM CONTACT OP</S.Button>
            </S.Block>
          </S.Form>
        </S.Column>
        <S.Column>
          <S.Image src={contactImage} />
        </S.Column>
      </S.Container>
    </S.Wrapper>
  )
}

export default DesktopLaptop

const Container = styled.div``

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 50%;
  margin: 60px;
`
