import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { theme } from 'theme'
import { SliderMobile } from 'features/ui/sliderMobile.component'
import Preview from 'features/ui/preview.component'
import Editor from 'features/ui/editor.component'

const ProjectDetail = ({ list, path, data, element }) => {
  const transformData = () => {
    let newElements = []

    if (element) {
      const { files, content } = element

      if (files && files.length > 0) {
        const newFiles = files.map((file) => ({ file: file, type: 'file' }))
        newElements = newElements.concat(newFiles)
      }

      if (content && content.length > 0) {
        newElements = newElements.concat(
          content.map((value) => ({ content: value, type: 'default' }))
        )
      }
    }

    return newElements
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0 })
  }

  React.useEffect(() => {
    scrollToTop()
  }, [])

  return (
    <Container>
      {element && (
        <>
          <SliderWrapper>
            <SliderMobile length={transformData().length}>
              {transformData().map((value, index) => (
                <SliderItem key={index}>
                  {value.type === 'default' && (
                    <>
                      <SubTitle>{value.content?.subTitle}</SubTitle>
                      <Editor
                        className='editor-shell'
                        isEditable={false}
                        data={value.content?.description}
                      />
                    </>
                  )}
                  {value.type === 'file' && <Preview files={value.file} />}
                </SliderItem>
              ))}
            </SliderMobile>
          </SliderWrapper>
          {data.length > 0 && (
            <>
              <Hr />
              <MoreBy>
                <PrimaryFont>Projecten</PrimaryFont>
              </MoreBy>
            </>
          )}
        </>
      )}

      {list ? (
        <DataGrid>
          {data.map((element) => (
            <CustomLink
              key={element.id}
              onClick={scrollToTop}
              to={`${path}/${element.id}`}
            >
              <Card>
                <Preview files={element.thumbnail} />
                {/* <CardFooter>
                  <CardTitle>{element.title}</CardTitle>
                  <Button>Nieuwsgierig</Button>
                </CardFooter> */}
              </Card>
              <CardFooter>
                <CardTitle>{element.title}</CardTitle>
                <Button>Nieuwsgierig</Button>
              </CardFooter>
            </CustomLink>
          ))}
        </DataGrid>
      ) : (
        <DataGrid>
          {data.map((element) => (
            <CustomLink
              key={element.id}
              onClick={scrollToTop}
              to={`${path}/${element.id}`}
            >
              <Wrapper>
                <Preview files={element.thumbnail} />
              </Wrapper>
            </CustomLink>
          ))}
        </DataGrid>
      )}
    </Container>
  )
}

export default ProjectDetail

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .previewPDF {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    background-color: ${theme.colors.quaternary};
  }

  .react-pdf__Page__canvas {
    width: 100% !important;
    min-height: 100% !important;
    object-fit: cover;
  }

  .previewImage {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    background-color: ${theme.colors.quaternary};
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .previewVideo {
    flex-shrink: 0;
    width: 100%;
    height: 100%;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .editor-paragraph {
    margin: 0;
    font-family: 'VL Arch Light Cond Light';
    font-size: 16px;
    //line-height: 1.3;
  }

  .editor-list-ol {
    margin: 0;
    margin-top: 10px;
    padding: 0;
    font-family: 'Brittany Signature';
    font-size: 32px;
    color: ${theme.colors.quinary};
    list-style-position: inside;
    list-style: none;
    counter-increment: css-counter 1;

    li {
      margin: 0;
      padding: 0;
      &::before {
        content: counter(css-counter);
        margin: 0 20px 0 0;
        padding: 0;
      }
    }
  }

  .editor-shell {
    padding: 0 !important;
    margin: 0 !important;
    padding-top: 0 !important;
    margin-right: 10px !important;
    padding-left: 10px !important;
    padding-bottom: 10px !important;
    width: 100%;
    height: 100%;
    padding: 16px;
    font-size: 16px !important;
  }
`

const Title = styled.h1`
  margin: 0;
  padding: 0;
  text-align: none;
`

const SubTitle = styled.h2`
  /* position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%); */
  padding: 0;
  /* padding-left: 10px; */
  font-family: 'Brittany Signature';
  font-size: 32px;
  text-align: center;
`

const SliderWrapper = styled.div`
  display: flex;
  width: 100%;
`

const SliderItem = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: 495px;
  position: relative;
  background-color: ${theme.colors.quaternary};
  overflow-y: scroll;
`

const DataGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* margin: 0 16px; */
  padding-bottom: 20px;
  width: 100%;
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  .previewPDF {
    width: 100%;
    height: 395px;
  }

  .previewImage {
    width: 100%;
    height: 395px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:last-child {
    margin-bottom: 16px;
  }
`

const Hr = styled.hr`
  width: calc(100% - 32px);
  margin: 0 16px;
  margin-top: 16px;
  border: 1px solid ${theme.colors.quaternary};
`

const MoreBy = styled.div`
  padding: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
`

const PrimaryFont = styled.h1`
  font-family: 'VL Arch Light Cond Light';
  font-size: 20px;
  color: ${theme.colors.primary};
`

const CustomLink = styled(Link)`
  text-decoration: none;
`

const Card = styled.div`
  font-family: 'VL Arch Light Cond Light';
  font-size: 16px;
  position: relative;
  width: 100%;
  height: 487.5px;
  //border: 1px solid ${theme.colors.primary};
  /* border-radius: 8px; */
  overflow: hidden;

  .previewPDF {
    border-radius: 0;
    width: 100%;

    canvas {
      width: 100% !important;
      height: auto !important;
    }
  }

  .previewImage {
    width: 100%;
    height: 100%;
    border-radius: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:last-child {
    margin-right: 20px;
  }
`

const CardFooter = styled.div`
  font-family: 'VL Arch Light Cond Light';
  font-size: 20px;
  /* position: absolute;
  bottom: 0; */
  display: flex;
  align-items: center;
  margin-top: auto;
  padding: 0 10px 0 10px;
  //padding: 20px;
  padding-top: 10px;
  width: 100%;
`

const CardTitle = styled.div`
  font-size: 1.1rem;
  text-transform: uppercase;
  color: ${theme.colors.quinary};
`

const Button = styled.button`
  font-size: 16px;
  margin: 0 0 0 auto;
  padding: 0;
  width: 120px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: ${theme.colors.primary};
  color: white;
  cursor: pointer;
`
