import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import {
  APPROACH_PAGE,
  ADVISE_PAGE,
  STYLING_PAGE,
  TWO_DESIGN_PAGE,
  THREE_DESIGN_PAGE,
  EXTRAS_PAGE,
} from 'pages'
import { theme } from 'theme'
import Approach from 'pages/approach/tablet-mobile'

const { heading: APPROACH_HEADING, text: APPROACH_TEXT } = APPROACH_PAGE
const {
  path: ADVISE_PATH,
  heading: ADVISE_HEADING,
  iconWhite: ADVISE_ICON,
} = ADVISE_PAGE
const {
  path: STYLING_PATH,
  heading: STYLING_HEADING,
  iconWhite: STYLING_ICON,
} = STYLING_PAGE
const {
  path: TWO_DESIGN_PATH,
  heading: TWO_DESIGN_HEADING,
  iconWhite: TWO_DESIGN_ICON,
} = TWO_DESIGN_PAGE
const {
  path: THREE_DESIGN_PATH,
  heading: THREE_DESIGN_HEADING,
  iconWhite: THREE_DESIGN_ICON,
} = THREE_DESIGN_PAGE
const {
  path: EXTRAS_PATH,
  heading: EXTRAS_HEADING,
  // iconWhite: THREE_DESIGN_ICON,
} = EXTRAS_PAGE

const Mobile = () => {
  const [path, setPath] = React.useState('')

  const tabs = [
    { index: 0, path: ADVISE_PATH, name: ADVISE_HEADING },
    { index: 1, path: STYLING_PATH, name: STYLING_HEADING },
    { index: 2, path: TWO_DESIGN_PATH, name: TWO_DESIGN_HEADING },
    { index: 3, path: THREE_DESIGN_PATH, name: THREE_DESIGN_HEADING },
    { index: 4, path: EXTRAS_PATH, name: EXTRAS_HEADING },
  ]

  return (
    <React.Fragment>
      {path !== '' && <Overlay onClick={() => setPath('')} />}
      {path !== '' && <Approach path={path} setPath={setPath} tabs={tabs} />}
      <Container>
        <Title>{APPROACH_HEADING}</Title>
        <Block>{APPROACH_TEXT}</Block>
        <Flexbox>
          <Card>
            <span className='icon'>{ADVISE_ICON}</span>
            <CardFooter>
              <CardTitle>{ADVISE_HEADING}</CardTitle>
              <Button onClick={() => setPath(ADVISE_PATH)}>Nieuwsgierig</Button>
            </CardFooter>
          </Card>
          <Card>
            <span className='icon'>{STYLING_ICON}</span>
            <CardFooter>
              <CardTitle>{STYLING_HEADING}</CardTitle>
              <Button onClick={() => setPath(STYLING_PATH)}>
                Nieuwsgierig
              </Button>
            </CardFooter>
          </Card>
          <Card>
            <span className='icon'>{TWO_DESIGN_ICON}</span>
            <CardFooter>
              <CardTitle>{TWO_DESIGN_HEADING}</CardTitle>
              <Button onClick={() => setPath(TWO_DESIGN_PATH)}>
                Nieuwsgierig
              </Button>
            </CardFooter>
          </Card>
          <Card>
            <span className='icon'>{THREE_DESIGN_ICON}</span>
            <CardFooter>
              <CardTitle>{THREE_DESIGN_HEADING}</CardTitle>
              <Button onClick={() => setPath(THREE_DESIGN_PATH)}>
                Nieuwsgierig
              </Button>
            </CardFooter>
          </Card>
          <Card>
            {/* <span className='icon'>{THREE_DESIGN_ICON}</span> */}
            <CardFooter>
              <CardTitle>{EXTRAS_HEADING}</CardTitle>
              <Button onClick={() => setPath(EXTRAS_PATH)}>Nieuwsgierig</Button>
            </CardFooter>
          </Card>
        </Flexbox>
      </Container>
    </React.Fragment>
  )
}

export default Mobile

const Container = styled.section`
  display: flex;
  flex-direction: column;
  font-family: 'VL Arch Light Cond Light';
  font-size: 20px;
  padding: 20px;
  background-color: ${theme.colors.primary};
  color: white;
`

const Title = styled.h1`
  margin: 0 0 20px 0;
  font-family: 'Brittany Signature';
  font-size: 32px;
`

const Block = styled.div`
  display: flex;
  width: 100%;
  line-height: 1.5rem;
`

const Flexbox = styled.div`
  overflow: scroll;
  display: flex;
  gap: 20px;
  margin: 20px -20px 0 0;

  &::-webkit-scrollbar {
    display: none;
  }
`

const Card = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  width: 300px;
  height: 340px;
  background-color: transparent;
  border: 1px solid ${theme.colors.quaternary};
  /* border-radius: 5px; */
  overflow: hidden;

  .icon {
    position: absolute;
    top: 90px;
    left: 50%;
    transform: translateX(-50%);
  }

  &:last-child {
    margin-right: 20px;
  }
`

const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  padding: 20px;
  width: 100%;
  background-color: ${theme.colors.quaternary};
  color: black;
`

const CardTitle = styled.div`
  font-size: 1.1rem;
  text-transform: uppercase;
`

const Button = styled.button`
  margin: 0 0 0 auto;
  padding: 0;
  width: 120px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: ${theme.colors.tertiary};
  color: white;
  font-size: 16px;
  cursor: pointer;
`

const Overlay = styled.div`
  z-index: 500;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #444;
  opacity: 0.5;
  cursor: zoom-out;
`
