import * as React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import { theme } from 'theme'
import { signInAuthUserWithEmailAndPassword } from 'utils/firebase/firebase.utils'
import useClickOutside from 'hooks/use-click-outside'
import Input from 'features/ui/input.component'
import { passwordReset } from 'utils/firebase/firebase.utils'

const SignIn = () => {
  const navigate = useNavigate()

  let domNode = useClickOutside(() => navigate(-1))

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit = async (data) => {
    const { email, password } = data
    try {
      await signInAuthUserWithEmailAndPassword(email, password)
      navigate(-1)
    } catch (error) {
      switch (error.code) {
        case 'auth/wrong-password':
          alert('incorrect password for email')
          break
        case 'auth/user-not-found':
          alert('no user associated with this email')
          break
        default:
          console.log(error)
      }
    }
  }

  return (
    <React.Fragment>
      <Container ref={domNode}>
        <Wrapper>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Field>
              <Input
                schema={{ required: true }}
                placeholder='Email'
                type='email'
                name='email'
                label='Email'
                register={register}
                validationSchema={{
                  required: true,
                }}
              />
              {errors.email && <ErrorMessage>Email is required</ErrorMessage>}
            </Field>
            <Field>
              <Input
                schema={{ required: true }}
                placeholder='Password'
                type='password'
                name='password'
                label='Password'
                register={register}
                validationSchema={{
                  required: true,
                }}
              />
              {errors.password && (
                <ErrorMessage>Password is required</ErrorMessage>
              )}
            </Field>
            <Buttons>
              <Button type='submit'>Sign In</Button>
              <Link type='button' onClick={() => navigate('/forgot-password')}>
                Wachtwoord vergeten?
              </Link>
            </Buttons>
          </form>
        </Wrapper>
      </Container>
      <Close>
        <IconButton type='button' outline>
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
      </Close>
    </React.Fragment>
  )
}

export default SignIn

const Container = styled.div`
  display: flex;
  width: calc(100% - 86px);
  min-height: 100vh;
  padding: 20px 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  background-color: ${theme.colors.quaternary};

  h1 {
    text-align: center;
    margin: 0;
    font-family: 'Brittany Signature';
    font-size: 32px;
  }

  form {
    width: 100%;
    margin: 0 auto;
  }
`

const Field = styled.div`
  width: 100%;
  margin-bottom: 10px;

  .editor-shell {
    border-radius: 15px;
    overflow: hidden;
    background-color: yellow;
  }

  &:last-child {
    display: flex;
    margin-top: 10px;
  }
`

const ErrorMessage = styled.span`
  color: red;
`

const Button = styled.button`
  //margin-left: auto;
  background-color: ${theme.colors.secondary};
  color: white;
  width: 150px;
  height: 50px;
  border: none;
  border-radius: 15px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
    color: ${theme.colors.secondary};
    border: 1px solid ${theme.colors.secondary};
  }
`

const IconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ solid }) =>
    solid ? theme.colors.secondary : 'transparent'};
  color: ${({ solid }) => (solid ? 'white' : theme.colors.secondary)};
  border: 1px solid ${theme.colors.secondary};
  border-radius: 16px;
  height: 46px;
  margin: 0;
  padding: 0 16px;
  font-size: 14px;
  cursor: pointer;

  span {
    margin-right: 8px;
  }

  &:hover {
    background-color: ${theme.colors.secondary};
    color: white;
  }
`

const Close = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 86px;
  padding-top: 20px;
  min-height: 100%;
  cursor: zoom-out;
`

const Wrapper = styled.div`
  border-radius: 8px;
  padding: 20px;
  background-color: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const Buttons = styled.div`
  display: flex;
  //display: inline-block;
`

const Link = styled.button`
  margin: 0;
  margin-left: 10px;
  padding: 0 20px;
  background-color: transparent;
  color: ${theme.colors.secondary};
  //width: 150px;
  height: 50px;
  border: 1px solid ${theme.colors.secondary};
  border-radius: 15px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.secondary};
    color: white;
    border: 1px solid ${theme.colors.secondary};
  }
  /* background-color: transparent;
  border: none;
  margin: 0;
  margin-left: 10px;
  padding: 0; */
  //margin-right: auto;
`
