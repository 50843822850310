import uuid from 'react-uuid'

export async function blobFromUrl(url, type) {
  const newName = uuid()
  const blob = await fetch(url)
    .then((res) => res.blob())
    .then((blob) => new File([blob], newName, { type }))
  console.log('blobFromUrl: ', blob)
  return blob
}
