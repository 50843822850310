import { createSlice, createListenerMiddleware } from '@reduxjs/toolkit'

const initialState = {
  selectedKey: 1,
  tabs: [],
  isEditable: false,
  error: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
}

// fetchData instead of fetchPage
export const tabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    setDefaultTabListState: (state, action) => {
      state.tabs = action.payload
    },
    setSelectedKey: (state, action) => {
      state.selectedKey = action.payload
    },
    addTab: (state, action) => {
      state.tabs = [...state.tabs, action.payload]
    },
    removeTab: (state, action) => {
      state.tabs = state.tabs.filter((tab) => tab.id !== action.payload)
    },
    updateTab: (state, action) => {
      const { id, title } = action.payload
      const index = state.tabs.findIndex((tab) => tab.id === id)
      if (index !== -1) {
        const activeTab = state.tabs[index]
        if (title !== '' && title !== activeTab.title) {
          state.tabs[index] = { ...activeTab, ...action.payload }
        }
      }
    },
  },
})

export const {
  setDefaultTabListState,
  // setIsEditable,
  setSelectedKey,
  addTab,
  removeTab,
  updateTab,
} = tabsSlice.actions

export default tabsSlice.reducer
