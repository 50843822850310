import styled, { css } from 'styled-components'

const commonStyles = css`
  ${({ size }) =>
    css`
      width: ${size?.width};
      height: ${size?.height};
    `}

  ${({ outlined }) =>
    outlined
      ? css`
          border: ${outlined?.lineWidth} ${outlined?.lineStyle}
            ${outlined?.color};
        `
      : css`
          border: none;
        `}

  ${({ rounded }) =>
    css`
      border-radius: ${rounded};
    `}

    ${({ theme }) =>
    css`
      background-color: ${theme?.background};
      color: ${theme.color};
    `}
`

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    height: 40px;
    object-fit: cover;
  }

  ${commonStyles}

  ${({ hoverState }) =>
    hoverState &&
    css`
      &:hover {
        width: ${hoverState.width};
        height: ${hoverState.height};
        border: ${hoverState.lineWidth} ${hoverState.lineStyle}
          ${hoverState.color};
        border-radius: ${hoverState.rounded};
        background-color: ${hoverState.background};
        color: ${hoverState.color};
      }
    `}

    ${({ onActive }) =>
    onActive &&
    css`
      &:active {
        width: ${onActive.width};
        height: ${onActive.height};
        border: ${onActive.lineWidth} ${onActive.lineStyle} ${onActive.color};
        border-radius: ${onActive.rounded};
        background-color: ${onActive.background};
        color: ${onActive.color};
      }
    `}

  ${({ position, spacing }) =>
    position === 'TopLeft' &&
    css`
      z-index: 2000;
      position: absolute;
      top: ${spacing};
      left: ${spacing};
    `}
  ${({ position, spacing }) =>
    position === 'TopCenter' &&
    css`
      z-index: 2000;
      position: absolute;
      top: ${spacing};
      left: 50%;
      transform: translateX(-50%);
    `}
  ${({ position, spacing }) =>
    position === 'TopRight' &&
    css`
      z-index: 2000;
      position: absolute;
      top: ${spacing};
      right: ${spacing};
    `}
  ${({ position, spacing }) =>
    position === 'MiddleLeft' &&
    css`
      z-index: 2000;
      position: absolute;
      top: 50%;
      left: ${spacing};
      transform: translateY(-50%);
    `}
  ${({ position }) =>
    position === 'MiddleCenter' &&
    css`
      z-index: 2000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
  ${({ position, spacing }) =>
    position === 'MiddleRight' &&
    css`
      z-index: 2000;
      position: absolute;
      top: 50%;
      right: ${spacing};
      transform: translateY(-50%);
    `}
  ${({ position, spacing }) =>
    position === 'BottomLeft' &&
    css`
      z-index: 2000;
      position: absolute;
      bottom: ${spacing};
      left: ${spacing};
    `}
  ${({ position, spacing }) =>
    position === 'BottomCenter' &&
    css`
      z-index: 2000;
      position: absolute;
      bottom: ${spacing};
      left: 50%;
      transform: translateX(-50%);
    `}
  ${({ position, spacing }) =>
    position === 'BottomRight' &&
    css`
      z-index: 2000;
      position: absolute;
      right: ${spacing};
      bottom: ${spacing};
    `}
    ${({ position, spacing }) =>
    position === 'LeftTop' &&
    css`
      z-index: 2000;
      position: absolute;
      top: ${spacing};
      left: ${spacing};
    `}
    ${({ position, spacing }) =>
    position === 'LeftCenter' &&
    css`
      z-index: 2000;
      position: absolute;
      top: 50%;
      left: ${spacing};
      transform: translateY(-50%);
    `}
    ${({ position, spacing }) =>
    position === 'LeftBottom' &&
    css`
      z-index: 2000;
      position: absolute;
      left: ${spacing};
      bottom: ${spacing};
    `}
    ${({ position, spacing }) =>
    position === 'RightTop' &&
    css`
      z-index: 2000;
      position: absolute;
      top: ${spacing};
      right: ${spacing};
    `}
    ${({ position, spacing }) =>
    position === 'RightCenter' &&
    css`
      z-index: 2000;
      position: absolute;
      top: 50%;
      right: ${spacing};
      transform: translateY(-50%);
    `}
    ${({ position, spacing }) =>
    position === 'RightBottom' &&
    css`
      z-index: 2000;
      position: absolute;
      right: ${spacing};
      bottom: ${spacing};
    `}
`
