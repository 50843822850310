import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import Quote from 'features/ui/editor/features/Block'

export default Node.create({
  name: 'blockStyle',

  group: 'block',

  atom: true,

  addAttributes() {
    return {
      className: {
        default: null,
      },
    }
  },
  parseHTML() {
    return [
      {
        tag: 'react-component',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['react-component', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return ReactNodeViewRenderer(Quote)
  },

  addCommands() {
    return {
      setStyle:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          })
        },
    }
  },
})
