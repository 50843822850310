import * as React from 'react'
import styled from 'styled-components'

export const ListBox = ({
  ref,
  collection,
  handleChange,
  onOpenChange,
  onActionChange,
}) => {
  const onChange = (item) => {
    handleChange(item.rendered)
    onActionChange(item.rendered)
    onOpenChange(false)
  }

  return (
    <ul ref={ref} style={{ backgroundColor: 'red' }}>
      {[...collection].map((item) => (
        <Option key={item.key} item={item} onClick={() => onChange(item)} />
      ))}
    </ul>
  )
}

export const Option = (props) => {
  const { hasChildren, children, onClick, item } = props

  return (
    <>
      {hasChildren ? (
        <List onClick={onClick}>{children}</List>
      ) : (
        <List onClick={onClick}>{item.rendered}</List>
      )}
    </>
  )
}

const List = styled.li`
  border: 0;
  background: none;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  color: #777;

  &:hover {
    background-color: #eee;
  }
`
