import React from 'react'
import styled, { css } from 'styled-components'
import { useEditor, EditorContent } from '@tiptap/react'

import { extensions } from 'tiptap/extensions'
import MenuBar from './features/MenuBar'
import { Tiptap } from 'tiptap/styles'

const Editor = (props) => {
  const { children, onChange, value, editable, isAdmin, style } = props

  const editor = useEditor({
    editable,
    extensions,
    onUpdate({ editor }) {
      onChange(editor.getHTML())
    },
    content: value,
  })

  return (
    <Tiptap editable={editable} isAdmin={isAdmin}>
      {editable && <MenuBar editor={editor} />}
      <EditorContent editor={editor} />
      {children}
    </Tiptap>
  )
}

export default Editor
