import React from 'react'
import { NodeViewWrapper } from '@tiptap/react'

import * as S from './styles'

const Block = (props) => {
  return (
    <NodeViewWrapper contentEditable={false}>
      <S.Wrapper className={props.node.attrs.className} />
    </NodeViewWrapper>
  )
}

export default Block
