import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInstagramSquare,
  faFacebookSquare,
} from '@fortawesome/free-brands-svg-icons'

import { theme } from 'theme'
import { Box } from 'features/ui/box.component'
import { MuiButton } from 'features/ui/button.component.mui'
import { BrittanySignature } from 'features/ui/typography.component'
import contactImage from 'assets/kim/kim-contact.jpg'

const DesktopLaptop = () => {
  let navigate = useNavigate()

  const routeChange = () => {
    navigate(`/contact`)
  }

  return (
    <Container>
      <Box width='calc(50%)'>
        <img src={contactImage} alt='Hero' />
      </Box>
      <Paper>
        <BrittanySignatureCustom>Contact</BrittanySignatureCustom>
        <div className='info'>
          <span>
            <p>Kim Jansen</p>
            <p>Interieur ontwerp</p>
          </span>
          <span>
            <p>Weebosch 104</p>
            <p>5571 NH Bergeijk</p>
          </span>
          <span>
            <p>info@beleefkim.com</p>
            <p>+32 4 72 76 15 66</p>
          </span>
          <span>
            <p>Beleef JOUW interieur</p>
          </span>
        </div>
        <Brands>
          <span className='icon'>
            <a href='https://www.instagram.com/beleefkim/'>
              <FontAwesomeIcon icon={faInstagramSquare} />
            </a>
          </span>
          <span className='icon'>
            <a href='https://www.facebook.com/kim.jansen.142'>
              <FontAwesomeIcon icon={faFacebookSquare} />
            </a>
          </span>
        </Brands>
        <Box style={{ position: 'absolute', bottom: '-30px', left: '100px' }}>
          {/* margin: 'auto 0 -20px 120px' */}
          <MuiButton variant='contained' onClick={routeChange}>
            Neem contact op
          </MuiButton>
        </Box>
      </Paper>
    </Container>
  )
}

export default DesktopLaptop

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  font-family: 'VL Arch Light Cond Light';
  font-size: 20px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Paper = styled.div`
  display: flex;
  /* flex-direction: column; */
  position: relative;
  border: 5px solid #dad5cf;
  border-left: none;
  margin: 100px 100px 100px 0;

  width: 70%;

  .info {
    margin: auto 0 auto 100px;

    span {
      display: block;
      margin: 30px 0;

      &:first-child {
        margin: 0;
      }

      &:last-child {
        margin: 0;
        color: ${theme.colors.quaternary};
      }

      p {
        margin: 10px 0;
      }
    }
  }
`

const BrittanySignatureCustom = styled(BrittanySignature)`
  font-size: 40px;
  color: ${theme.colors.primary};
  margin: 0;
  position: absolute;
  top: -32px;
  right: 5%;
`

const Brands = styled.div`
  position: absolute;
  bottom: 0;
  margin: 100px;
  margin-bottom: 50px;
  display: flex;
  gap: 10px;
  font-size: 40px;
  color: ${theme.colors.quaternary};

  a:link {
    color: ${theme.colors.quaternary};
  }

  a:visited {
    color: ${theme.colors.quaternary};
  }

  a:hover {
    color: ${theme.colors.primary};
    cursor: pointer;
  }

  .icon:first-child:hover {
    color: ${theme.colors.primary};
    cursor: pointer;
  }

  .icon:last-child:hover {
    color: ${theme.colors.primary};
    cursor: pointer;
  }
`
