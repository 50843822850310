import React from 'react'
import styled from 'styled-components'

import logo from 'assets/logo.png'
import { Link } from 'react-scroll'

import Hamburger from 'hamburger-react'
import { SvgIcon } from '@mui/material'
import { ReactComponent as Menu } from 'assets/icons/Menu.svg'
import { ReactComponent as Cancel } from 'assets/icons/Cancel.svg'
import { theme } from 'theme'

// scroll to point in page: https://medium.com/how-to-react/scroll-to-an-element-on-click-in-react-js-8424e478bb9
const Links = (props) => {
  const { setIsVisible } = props
  return (
    <NavLinksContainer>
      <ListItem>
        <NavLink
          onClick={() => setIsVisible(false)}
          to='over-mezelf'
          smooth
          spy
        >
          Over mezelf
        </NavLink>
      </ListItem>
      <ListItem>
        <NavLink onClick={() => setIsVisible(false)} to='aanpak' smooth spy>
          Aanpak
        </NavLink>
      </ListItem>
      <ListItem>
        <NavLink onClick={() => setIsVisible(false)} to='proces' smooth spy>
          Proces
        </NavLink>
      </ListItem>
      <ListItem>
        <NavLink onClick={() => setIsVisible(false)} to='projecten' smooth spy>
          Ontwerpen
        </NavLink>
      </ListItem>
      <ListItem>
        <NavLink onClick={() => setIsVisible(false)} to='blog' smooth spy>
          Blog
        </NavLink>
      </ListItem>
      <ListItem>
        <NavLink onClick={() => setIsVisible(false)} to='contact' smooth spy>
          Contact
        </NavLink>
      </ListItem>
    </NavLinksContainer>
  )
}

const Nav = (props) => {
  const { title } = props
  const [isVisible, setIsVisible] = React.useState(false)

  return (
    <React.Fragment>
      <Header>
        <Title>
          <img src={logo} />
        </Title>
        <IconButton onClick={() => setIsVisible(!isVisible)}>
          <Hamburger
            color={theme.colors.primary}
            size='20'
            toggled={isVisible}
            toggle={setIsVisible}
          />
        </IconButton>
      </Header>
      {isVisible && (
        <React.Fragment>
          <Links setIsVisible={setIsVisible} />
          <Backdrop onClick={() => setIsVisible(false)} />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default Nav

const Header = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 10px;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100px;
  background-color: ${theme.colors.quaternary};
`

const Title = styled.div`
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  img {
    width: 40px;
  }
`

const NavLinksContainer = styled.ul`
  z-index: 500;
  display: flex;
  flex-direction: column;
  //gap: 10px;
  position: fixed;
  top: 100px;

  list-style-type: none;
  margin: 0;
  padding: 0;
  //padding: 20px;
  background-color: white;
  width: 100%;
  //height: 100%;
`

const ListItem = styled.li`
  display: flex;
  align-items: center;
  //padding-left: 20px;
  //background-color: white;
  color: #444;
  //width: 100%;
  //height: 40px;
  font-family: 'VL Arch Light Cond Light';
  font-size: 0.9375rem;
`

const NavLink = styled(Link)`
  font-size: 18px;
  text-decoration: none;
  //text-transform: uppercase;
  width: 100%;
  height: 100%;
  padding: 20px;
  cursor: pointer;

  /* unvisited link */
  &:link {
    color: #444;
  }

  /* visited link */
  &:visited {
    color: #444;
  }

  /* mouse over link */
  &:hover {
    text-shadow: 0px 0px 1px #444;
  }

  /* selected link */
  &:active {
    text-shadow: 0px 0px 1px #444;
  }
`

const IconButton = styled.button`
  /* display: flex;
  align-items: center;
  justify-content: center; */
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  z-index: 1;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  /* height: 40px;
  width: 40px; */
  cursor: pointer;
`

const Backdrop = styled.div`
  z-index: 5;
  position: fixed;
  top: 130px;
  bottom: 0;
  background-color: #444;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  cursor: zoom-out;
`
