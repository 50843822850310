import styled, { css } from 'styled-components'
import theme from 'theme'

export const buttonColors = {
  primary: '#D0B1B6',
  secondary: '',
  accent: '#DAD5CF',
  destructive: '',
  muted: '',
  mutedForeground: '',
  light: '#fff',
  dark: '',
}
