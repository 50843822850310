import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faArrowLeft,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'

import { StyledTextField } from '../components/tabs/tabs.styled'
import { useKeyboard } from 'react-aria'
import { Collection } from 'react-aria-components'
import { Tab, TabPanel, TabList, Tabs } from 'components/tabs/tabs'
import { Item } from 'react-stately'
import Label from 'components/label/label'
import { RACModal as Modal, RACButton } from 'components/modal/modal'
import TextField from 'components/textField/textField'
import Tiptap from 'features/ui/editor/editor.component'
import styled from 'styled-components'
import {
  setSelectedKey,
  addTab,
  updateTab,
  removeTab,
} from '../components/tabs/tabsSlice'

const AdminForm = (props) => {
  const { defaultTabListState } = props
  const dispatch = useDispatch()

  const [isEditable, setIsEditable] = React.useState(false)
  const [isOpen, setOpen] = React.useState(false)
  const [tabs, setTabs] = React.useState(0)

  const { getValues, control } = useForm({
    defaultValues: {
      tabs: defaultTabListState,
    },
  })

  const { fields, append, update, prepend, remove, swap, move, insert } =
    useFieldArray({
      control,
      name: 'tabs',
    })

  const createTiptapEditor = () => {
    return (
      <Controller
        control={control}
        name='describe'
        render={({ field }) => {
          return <Tiptap {...field} editable={true} />
        }}
      />
    )
  }

  const onCloseModal = () => {
    append({
      id: getValues('tabs').length + 1,
      title: getValues('title'), // Change this to an input on Enter
      describe: getValues('describe'),
      content: (
        <Controller
          control={control}
          name='describe'
          render={({ field }) => {
            return <Tiptap {...field} editable={true} />
          }}
        />
      ),
    })
    setOpen(false)
  }

  const writableTab = (item, tabInputRef) => {
    return (
      <Controller
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, onBlur, value, ref } }) => {
          return (
            <TextField
              aria-label={item.title}
              style={{ marginBottom: '8px' }}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              validationbehavior='aria'
              // onKeyDown={(e) => {
              //   if (e.key === 'Enter') setIsReadonly(true)
              // }}
              ref={(e) => {
                ref(e)
                tabInputRef.current = e
              }}
            />
          )
        }}
        name={item.title}
      />
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'red',
        height: '100vh',
      }}
    >
      <div>
        <Tabs
          onCreateNewTab={() => setOpen(true)}
          aria-label='Dynamic tabs'
          selectedKey={tabs}
          onSelectionChange={setTabs}
          items={getValues('tabs')}
        >
          {(item) => (
            <Item title={item.title} writableTab={writableTab}>
              {item.content}
            </Item>
          )}
        </Tabs>
      </div>
      <button onClick={() => console.log('It Works!')}>oe,ov</button>

      <Modal isOpen={isOpen} setOpen={setOpen} onPress={onCloseModal}>
        <Controller
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <TextField
                style={{ marginBottom: '8px' }}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                validationbehavior='aria'
                description='Enter a subtitle.'
              />
            )
          }}
          name='title'
        />
        {createTiptapEditor()}
      </Modal>
    </div>
  )
}

export default AdminForm
