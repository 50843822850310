import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'

import { fetchPostById, resetForm } from './postSlice'
import { theme } from 'theme'
import useMediaQuery from 'hooks/use-media-query'
import ItemView from 'components/ItemView'
import ItemViewMobile from 'layouts/details/mobile'

const PostItem = () => {
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const { breakpoints } = theme
  const { desktopOrLaptop, mobile } = breakpoints

  const isDesktopOrLaptop = useMediaQuery(desktopOrLaptop)
  const isMobile = useMediaQuery(mobile)

  let { id } = useParams()

  const { user } = useSelector((state) => state.auth)
  const { posts, postItem } = useSelector((store) => store.posts)

  React.useEffect(() => {
    dispatch(fetchPostById(id))
  }, [dispatch, id, posts, postItem])

  return (
    <React.Fragment>
      {isDesktopOrLaptop && (
        <ItemView
          scrollArea={true}
          pageTitle='Blog'
          user={user}
          item={postItem}
          toUpdateForm={() => {
            dispatch(resetForm())
            navigate(`/posts/form/${id}`)
          }}
        />
      )}
      {isMobile && (
        <ItemViewMobile
          isText
          elements={posts}
          element={postItem}
          title='Blog'
        />
      )}
    </React.Fragment>
  )
}

export default PostItem
