import emailjs from 'emailjs-com'

export const sendEmail = (formRef) => {
  emailjs
    .sendForm(
      'service_nbhsz4a',
      'template_60lcdrh',
      formRef.current,
      'FjUkBX1PJjiNIhpyN'
    )
    .then(
      (result) => {
        alert('Bericht is verzonden')
      },
      (error) => {
        console.log(error.text)
      }
    )
}
