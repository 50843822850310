import styled from 'styled-components'

import Logo from '../../../../../assets/logo.png'

export const Wrapper = styled.div`
  /* background-image: url(${Logo});
  width: 200px;
  height: 100px;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 50px;
  display: block;
  background-color: red;
  width: 100%;

  &:focus {
    outline: 3px solid #68cef8;
  } */
`
