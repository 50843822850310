import React from 'react'
import styled from 'styled-components'
import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const MyDocument = (props) => {
  const { file } = props
  return (
    <Container>
      <Document file={file}>
        <Page pageNumber={1} />
      </Document>
    </Container>
  )
}

export default MyDocument

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  .react-pdf__Document {
    width: 100% !important;
    height: 100% !important;
  }

  .react-pdf__Page {
    width: 100% !important;
    height: 100% !important;
  }

  .react-pdf__Page canvas {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
  }
`
