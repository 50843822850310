import * as React from 'react'
import styled, { css } from 'styled-components'
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { DatabaseService } from 'service/databaseService'
import Preview from 'features/ui/preview.component'
import { SvgIcon } from '@mui/material'
import { ReactComponent as BackwardArrow } from 'assets/icons/BackwardArrow.svg'
import { theme } from 'theme'

const Mobile = () => {
  const { projects } = useSelector((store) => store.projects)

  const { readData } = DatabaseService

  // const fetchData = async () => {
  //   try {
  //     const docs = await readData('projects/')
  //     setProjects(docs)
  //   } catch (error) {
  //     // Handle error if necessary
  //     console.log(error)
  //   }
  // }

  // React.useEffect(() => {
  //   fetchData()
  // }, [])

  return (
    <Container>
      <Header>
        <Title>Projecten</Title>
        {/* <Buttons>
          <IconButton>
            <SvgIcon
              style={{ color: theme.colors.primary }}
              component={BackwardArrow}
              fontSize='inherit'
              inheritViewBox
            />
          </IconButton>
          <IconButton>
            <SvgIcon
              style={{
                color: theme.colors.primary,
                transform: 'rotate(180deg)',
              }}
              component={BackwardArrow}
              fontSize='inherit'
              inheritViewBox
            />
          </IconButton>
        </Buttons> */}
      </Header>
      <Flexbox>
        {projects.map((project) => (
          <Card to={`/projects/${project.id}`}>
            <Overlay />
            <Preview files={project.thumbnail} />
            <CardFooter>
              <CardTitle>{project.title}</CardTitle>
            </CardFooter>
          </Card>
        ))}
        {/* <Card>
          <CardFooter>
            <CardTitle>Opgave</CardTitle>
          </CardFooter>
        </Card>
        <Card>
          <CardFooter>
            <CardTitle>Puzzel</CardTitle>
          </CardFooter>
        </Card>
        <Card>
          <CardFooter>
            <CardTitle>Oplossing</CardTitle>
          </CardFooter>
        </Card>
        <Card>
          <CardFooter>
            <CardTitle>Besluit</CardTitle>
          </CardFooter>
        </Card> */}
      </Flexbox>
    </Container>
  )
}

export default Mobile

const Container = styled.section`
  display: flex;
  flex-direction: column;
  font-family: 'VL Arch Light Cond Light';
  font-size: 20px;
  padding: 20px;
  background-color: ${theme.colors.primary};
  color: ${theme.colors.quinary};
`

const Header = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.h1`
  margin: 0;
  margin-bottom: 20px;
  font-family: 'Brittany Signature';
  font-size: 32px;
`

const Flexbox = styled.div`
  overflow: scroll;
  display: flex;
  gap: 20px;
  margin: 20px -20px 0 0;

  &::-webkit-scrollbar {
    display: none;
  }
`

const Card = styled(Link)`
  position: relative;
  display: flex;
  flex-shrink: 0;
  width: 300px;
  height: 300px;
  background-color: transparent;
  border: 1px solid ${theme.colors.quinary};
  /* border-radius: 5px; */
  overflow: hidden;

  .previewPDF {
    margin: 0;
    padding: 0;
    flex-shrink: 0;
    width: 100%;
    aspect-ratio: 1 /1;
    background-color: ${theme.colors.quaternary};
  }

  .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 1 / 1 !important;
  }

  .previewImage {
    flex-shrink: 0;
    width: 100%;
    aspect-ratio: 1 /1;
    background-color: ${theme.colors.quaternary};
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:last-child {
    margin-right: 20px;
  }
`

const Overlay = styled.div`
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, rgba(255, 0, 0, 0), #35435d);
  opacity: 0.5;
`

const CardFooter = styled.div`
  display: flex;
  z-index: 2;
  position: absolute;
  top: 0;
  //right: 0;
  //padding: 20px;
  width: 100%;
`

const CardTitle = styled.div`
  margin: 20px 20px 20px auto;
  /* position: absolute;
  bottom: 20px;
  right: 20px; */
  font-size: 1.1rem;
  text-transform: uppercase;
  color: white;
`

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  margin-left: auto;
`

const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
`
