import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLightbulb,
  faPuzzlePiece,
  faHouseChimneyWindow,
} from '@fortawesome/free-solid-svg-icons'

import {
  APPROACH_PAGE,
  ADVISE_PAGE,
  STYLING_PAGE,
  TWO_DESIGN_PAGE,
  THREE_DESIGN_PAGE,
} from 'pages'
import { theme } from 'theme'
import { BrittanySignature } from 'features/ui/typography.component'
import { Box } from 'features/ui/box.component'
import { MuiButton } from 'features/ui/button.component.mui'

const { heading: APPROACH_HEADING } = APPROACH_PAGE
const {
  heading: ADVISE_HEADING,
  text: ADVISE_TEXT,
  iconWhite: ADVISE_ICON,
} = ADVISE_PAGE
const {
  heading: STYLING_HEADING,
  text: STYLING_TEXT,
  iconWhite: STYLING_ICON,
} = STYLING_PAGE
const {
  heading: TWO_DESIGN_HEADING,
  text: TWO_DESIGN_TEXT,
  iconWhite: TWO_DESIGN_ICON,
} = TWO_DESIGN_PAGE
const {
  heading: THREE_DESIGN_HEADING,
  text: THREE_DESIGN_TEXT,
  iconWhite: THREE_DESIGN_ICON,
} = THREE_DESIGN_PAGE

const DesktopLaptop = () => {
  let navigate = useNavigate()

  const routeChange = () => {
    navigate(`/approach`)
  }

  return (
    <Container>
      <Block>
        <span>Elke opdracht vergt een aanpak op maat.</span> Wil je grondig
        renoveren en zelf de handen uit de mouwen steken? Heb je liever een
        renovatie van A tot Z maar met (gedeeltelijke) begeleiding of wil je
        liever helemaal ontzorgd worden? Misschien is een kleine styling al
        genoeg om jouw interieur te doen (her)leven? Ben je benieuwd naar een
        visuele weergave van jouw ideeën? Ik ondersteun jou bij wat jij nodig
        hebt !
      </Block>

      <Grid>
        <Box style={{ marginBottom: '-30px' }} />
        <Box style={{ marginBottom: '-30px' }} />
        <Box style={{ marginBottom: '-30px' }} />
        <Box style={{ marginBottom: '-30px', display: 'flex' }}>
          <BrittanySignatureCustom>Aanpak</BrittanySignatureCustom>
        </Box>
        <Box style={{ marginBottom: '-30px' }} />
        <Card>
          <Icon>{ADVISE_ICON}</Icon>
          <CardTitle>Advies</CardTitle>
          {/* <Box placement='bottom-center' style={{ marginBottom: '-25px' }}>
            <CardTitle>Advies</CardTitle>
          </Box> */}
        </Card>
        <Card>
          <Icon>{STYLING_ICON}</Icon>
          <CardTitle>Styling</CardTitle>
        </Card>
        <Card>
          <Icon>{TWO_DESIGN_ICON}</Icon>
          <CardTitle>2D ontwerp</CardTitle>
        </Card>
        <Card>
          <Icon>{THREE_DESIGN_ICON}</Icon>
          <CardTitle>3D ontwerp</CardTitle>
        </Card>
        <Card>
          <CustomButton variant='contained' onClick={routeChange}>
            Nieuwsgierig
          </CustomButton>
          {/* <Box placement='bottom-center' style={{ marginBottom: '-20px' }}>
            <MuiButton variant='contained' onClick={routeChange}>
              Nieuwsgierig
            </MuiButton>
          </Box> */}
        </Card>
      </Grid>
    </Container>
  )
}

export default DesktopLaptop

const Container = styled.section`
  display: grid;
  min-height: 100vh;
  padding: 60px;
  background-color: ${theme.colors.primary};
  font-family: 'VL Arch Light Cond Light';
  font-size: 18px;
`

const Block = styled.p`
  width: 50%;
  font-size: 20px;
  //line-height: 21px;
  color: white;

  span {
    display: block;
  }
`

const BrittanySignatureCustom = styled(BrittanySignature)`
  z-index: 2;
  font-size: 40px;
  color: ${theme.colors.quinary};

  margin: 0 0 0 auto;
`

const Grid = styled.div`
  margin-top: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 200px;
  grid-gap: 30px;
`

const Card = styled.div`
  position: relative;
  display: flex;
  border: 5px solid ${theme.colors.quaternary};
  aspect-ratio: 1;

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 90px;
  }

  &:last-child {
    border: none;
    margin-top: auto;
  }
`

const Icon = styled.span`
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    width: 115px;
  }
`

const CardTitle = styled.div`
  margin: auto auto -30px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.quaternary};
  color: ${theme.colors.quinary};

  text-transform: uppercase;
  font-size: 16px;
  width: 80%;
  height: 60px;
`

const CustomButton = styled(MuiButton)`
  position: absolute;
  bottom: -25px;
`
