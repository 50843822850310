import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'

import { theme } from 'theme'
import useMediaQuery from 'hooks/use-media-query'
import { fetchProjectById, resetForm } from 'features/projects/projectSlice'
import ItemView from 'components/ItemView'
import ItemViewMobile from 'layouts/details/mobile'

const ProjectItem = () => {
  const dispatch = useDispatch()

  const navigate = useNavigate()

  let { id } = useParams()

  const { user } = useSelector((state) => state.auth)
  const { projects, projectItem } = useSelector((store) => store.projects)

  const { breakpoints } = theme
  const { desktopOrLaptop, mobile } = breakpoints

  const isDesktopOrLaptop = useMediaQuery(desktopOrLaptop)
  const isMobile = useMediaQuery(mobile)

  React.useEffect(() => {
    dispatch(fetchProjectById(id))
  }, [dispatch, id, projects])

  return (
    <React.Fragment>
      {isDesktopOrLaptop && (
        <ItemView
          user={user}
          item={projectItem}
          toUpdateForm={() => {
            dispatch(resetForm())
            navigate(`/projects/form/${id}`)
          }}
        />
      )}
      {isMobile && (
        <ItemViewMobile
          elements={projects}
          element={projects.find((p) => p.id === id)}
          title='Projecten'
        />
      )}
    </React.Fragment>
  )
}

export default ProjectItem
