import { createSlice } from '@reduxjs/toolkit'

import { fetchPage, createPage, updatePage } from './createAction'

const initialState = {
  data: [],
  currentData: {},
  selectedKey: 'advies',
  tabs: {
    advies: {
      title: 'advies',
      content: '',
    },
    styling: {
      title: 'styling',
      content: '',
    },
    '2DOntwerp': {
      title: '2D Ontwerp',
      content: '',
    },
    '3DOntwerp': {
      title: '3D Ontwerp',
      content: '',
    },
  },
  error: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
}

// fetchData instead of fetchPage
export const aboutSlice = createSlice({
  name: 'about',
  initialState,
  reducers: {
    createItem: (state, action) => {
      state.tabs[state.selectedKey] = action.payload
    },
    selectedItem: (state, action) => {
      const { selectedKey } = action.payload
      state.selectedKey = selectedKey
    },
    // onSubmit: (state, action) => {
    //   state.currentData = { ...action.payload, ...state.tabs }
    // },
    resetCurrentData: (state) => {
      const { currentData } = initialState
      state.currentData = currentData
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPage.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchPage.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
    })
    builder.addCase(createPage.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(createPage.fulfilled, (state, action) => {
      state.isLoading = false
      state.currentData = { ...action.payload, ...state.tabs }
      state.data = { ...state.currentData, ...state.data }
    })
    builder.addCase(updatePage.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updatePage.fulfilled, (state, action) => {
      state.isLoading = false
      const { id } = action.payload
      state.currentData = { ...action.payload, ...state.tabs }
      state.data = state.data.map((item) =>
        item.id === id ? state.currentData : item
      )
    })
  },
})

export const { createItem, selectedItem, onSubmit, resetCurrentData } =
  aboutSlice.actions

export default aboutSlice.reducer
