import React from 'react'
import styled from 'styled-components'

import { theme } from 'theme'
import { Box } from 'features/ui/box.component'
import aboutImage from 'assets/kim/kim-about.jpg'

const TabletMobile = () => {
  return (
    <Container>
      <Box width='100%'>
        <img src={aboutImage} alt='About' />
      </Box>
      <Paper>
        <Block>
          <h1>Over mezelf</h1>
          <p>
            <span>Hi! Mijn naam is Kim, Kim Jansen.</span>
            <span>
              De liefde voor interieurs is er in mijn kindertijd met de paplepel
              ingegoten. deze heb ik geerft van mijn vader, hij heeft een
              maatwerk bedrijf waar ik van jongs af aan niet was weg te slaan.
              Op mijn 12 kreeg ik een eigen gereedschapskist met mijn naam op en
              vanaf dat moment kon ik ECHT mee gaan timmeren.
            </span>
            <span>
              Op mijn 16 kwam ik er achter dat ik het bedenken, eigenlijk veel
              leuker vond dan het maken zelf. Spelenderwijs ben ik gaan
              ontwerpen met de tekenprogramma’s op kantoor. Hierdoor begon het
              helemaal te kriebelen. Ik wist meteen, dit is wat ik wil doen.
            </span>
            <span>
              Ik heb een bachelor interieur en design behaald, deze aangevuld
              met een graduaat in vastgoed en een diploma als interieur stylist.
              Stil zitten staat niet in mijn woordenboek, langs mijn studies heb
              ik altijd gewerkt als technisch tekenaar in de interieurbouw.
              Tegenwoordig werk ik als interieur ontwerpster voor een prachtig
              bedrijf met super gave projecten.
            </span>
            Nog steeds is mijn honger niet gestild, in de avonduren en weekenden
            vind ik het fantastisch om mijn eigen ontwerpen te bedenken en uit
            te werken, deze wil ik graag delen in een online portfolio.
          </p>
        </Block>
      </Paper>
    </Container>
  )
}

export default TabletMobile

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;

  img {
    background-color: red;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Paper = styled.div`
  color: ${theme.colors.quinary};
  width: 150%;
  padding: 60px;
  text-align: right;
`

const Block = styled.div`
  h1 {
    font-family: 'Brittany Signature';
    font-size: 32px;
    margin: 0 0 60px 0;
  }

  p {
    width: 80%;
    margin-left: auto;

    span {
      display: block;
      margin-bottom: 15px;
    }
  }
`
