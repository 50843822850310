import React from 'react'

import { theme } from 'theme'
import * as S from './styles'
import useDropdown from 'hooks/use-dropdown'
import useClickOutside from 'hooks/use-click-outside'
import { DropdownMenu, DropdownItem } from 'components/dropdownMenu'
import Input from 'components/input'
import Dialog from 'components/dialog'
import ArrowCounterclockwise from 'assets/icons/arrow-counterclockwise.svg'
import ArrowClockwise from 'assets/icons/arrow-clockwise.svg'
import TextParagraph from 'assets/icons/text-paragraph.svg'
import TypeH1 from 'assets/icons/type-h1.svg'
import TypeH2 from 'assets/icons/type-h2.svg'
import TypeH3 from 'assets/icons/type-h3.svg'
import ListUl from 'assets/icons/list-ul.svg'
import ListOl from 'assets/icons/list-ol.svg'
import TypeBold from 'assets/icons/type-bold.svg'
import TypeItalic from 'assets/icons/type-italic.svg'
import TypeUnderline from 'assets/icons/type-underline.svg'
import TypeLink from 'assets/icons/link.svg'
import FontFamily from 'assets/icons/font-family.svg'
import Plus from 'assets/icons/plus.svg'
import FileImage from 'assets/icons/file-image.svg'
import TextLeft from 'assets/icons/text-left.svg'
import TextCenter from 'assets/icons/text-center.svg'
import TextRight from 'assets/icons/text-right.svg'
import TextJustify from 'assets/icons/text-justify.svg'
import Close from 'assets/icons/close.svg'
import Success from 'assets/icons/success.svg'
import Pencil from 'assets/icons/pencil.svg'
import Trash from 'assets/icons/trash.svg'
import BgColor from 'assets/icons/bg-color.svg'
import ChatSquareQuote from 'assets/icons/chat-square-quote.svg'

const MenuBar = ({ editor, tabs }) => {
  const inputFileRef = React.useRef(null)
  const colorRef = React.useRef(null)

  const [openDialog, setOpenDialog] = React.useState(false)
  const [isEditable, setIsEditable] = React.useState(false)
  // const [state, setState] = React.useState({
  //   fontSize: '',
  //   link: 'https://',
  // })
  const [fontSize, setFontSize] = React.useState('8px')
  const [link, setLink] = React.useState('https://')
  const [color, setColor] = React.useState('')

  const handleDefaultColors = (color, hex) => {
    return (
      <S.ColorOption>
        <S.Color color={hex} /> <S.Paragraph>{color}</S.Paragraph>
      </S.ColorOption>
    )
  }

  const fontFamilyDropDown = [
    {
      uid: 0,
      value: 'Brittany Signature',
      onAction: () =>
        editor.chain().focus().setFontFamily('Brittany Signature').run(),
    },
    {
      uid: 1,
      value: 'VL Arch Light Cond Light',
      onAction: () =>
        editor.chain().focus().setFontFamily('VL Arch Light Cond Light').run(),
    },
    {
      uid: 2,
      value: 'Arial',
      onAction: () => editor.chain().focus().setFontFamily('Arial').run(),
    },
    {
      uid: 3,
      value: 'Courier New',
      onAction: () => editor.chain().focus().setFontFamily('Courier New').run(),
    },
    {
      uid: 4,
      value: 'Georgia',
      onAction: () => editor.chain().focus().setFontFamily('Georgia').run(),
    },
    {
      uid: 5,
      value: 'Times New Roman',
      onAction: () =>
        editor.chain().focus().setFontFamily('Times New Roman').run(),
    },
    {
      uid: 6,
      value: 'Trebuchet MS',
      onAction: () =>
        editor.chain().focus().setFontFamily('Trebuchet MS').run(),
    },
    {
      uid: 7,
      value: 'Verdana',
      onAction: () => editor.chain().focus().setFontFamily('Verdana').run(),
    },
  ]

  // 30 => Title, 20, text
  const fontSizeDropDown = [
    {
      uid: 0,
      value: '8px',
      onAction: () => editor.chain().focus().setFontSize('8px').run(),
    },
    {
      uid: 1,
      value: '9px',
      onAction: () => editor.chain().focus().setFontSize('9px').run(),
    },
    {
      uid: 2,
      value: '10px',
      onAction: () => editor.chain().focus().setFontSize('10px').run(),
    },
    {
      uid: 3,
      value: '11px',
      onAction: () => editor.chain().focus().setFontSize('11px').run(),
    },
    {
      uid: 4,
      value: '12px',
      onAction: () => editor.chain().focus().setFontSize('12px').run(),
    },
    {
      uid: 5,
      value: '14px',
      onAction: () => editor.chain().focus().setFontSize('14px').run(),
    },
    {
      uid: 6,
      value: '16px',
      onAction: () => editor.chain().focus().setFontSize('16px').run(),
    },
    {
      uid: 7,
      value: '18px',
      onAction: () => editor.chain().focus().setFontSize('18px').run(),
    },
    {
      uid: 8,
      value: '20px',
      onAction: () => editor.chain().focus().setFontSize('20px').run(),
    },
    {
      uid: 9,
      value: '24px',
      onAction: () => editor.chain().focus().setFontSize('24px').run(),
    },
    {
      uid: 10,
      value: '28px',
      onAction: () => editor.chain().focus().setFontSize('28px').run(),
    },
    {
      uid: 11,
      value: '36px',
      onAction: () => editor.chain().focus().setFontSize('36px').run(),
    },
    {
      uid: 12,
      value: '48px',
      onAction: () => editor.chain().focus().setFontSize('48px').run(),
    },
    {
      uid: 13,
      value: '72px',
      onAction: () => editor.chain().focus().setFontSize('72px').run(),
    },
  ]

  const insertDropDown = [
    {
      uid: 0,
      value: 'Image',
      icon: { path: FileImage },
      onAction: () => addImage(),
    },
    {
      uid: 1,
      value: 'Quote',
      icon: { path: ChatSquareQuote },
      onAction: () =>
        editor.chain().focus().setStyle({ className: 'tiptap-quote' }).run(),
      // editor.commands.insertContent('<span class="editor-quote" />'),
    },
  ]

  const alignDropDown = [
    {
      uid: 0,
      value: 'Left',
      icon: { path: TextLeft },
      onAction: () => editor.chain().focus().setTextAlign('left').run(),
    },
    {
      uid: 2,
      value: 'Center',
      icon: { path: TextCenter },
      onAction: () => editor.chain().focus().setTextAlign('center').run(),
    },
    {
      uid: 1,
      value: 'Right',
      icon: { path: TextRight },
      onAction: () => editor.chain().focus().setTextAlign('right').run(),
    },
    {
      uid: 3,
      value: 'Justify',
      icon: { path: TextJustify },
      onAction: () => editor.chain().focus().setTextAlign('justify').run(),
    },
  ]

  const colorDropDown = [
    {
      uid: 0,
      value: handleDefaultColors('Primary Color', theme.colors.primary),
      onAction: () =>
        editor.chain().focus().setColor(theme.colors.primary).run(),
    },
    {
      uid: 1,
      value: handleDefaultColors('Secondary Color', theme.colors.secondary),
      onAction: () =>
        editor.chain().focus().setColor(theme.colors.secondary).run(),
    },
    {
      uid: 2,
      value: handleDefaultColors('Tertiary Color', theme.colors.tertiary),
      onAction: () =>
        editor.chain().focus().setColor(theme.colors.tertiary).run(),
    },
    {
      uid: 3,
      value: handleDefaultColors('Quaternary Color', theme.colors.quaternary),
      onAction: () =>
        editor.chain().focus().setColor(theme.colors.quaternary).run(),
    },
    {
      uid: 4,
      value: handleDefaultColors('Quinary Color', theme.colors.quinary),
      onAction: () =>
        editor.chain().focus().setColor(theme.colors.quinary).run(),
    },
    {
      uid: 4,
      value: handleDefaultColors('Pick your Color', color),
      onAction: () => colorRef.current.click(),
    },
  ]

  let domNode = useClickOutside(() => setOpenDialog(false))

  const blockFormatDropDown = [
    {
      uid: 0,
      value: 'Normal',
      icon: { path: TextParagraph },
      onAction: () => formatParagraph(),
    },
    {
      uid: 1,
      value: 'Heading 1',
      icon: { path: TypeH1 },
      onAction: () => formatHeading({ level: 1 }),
    },
    {
      uid: 2,
      value: 'Heading 2',
      icon: { path: TypeH2 },
      onAction: () => formatHeading({ level: 2 }),
    },
    // {
    //   uid: 3,
    //   value: 'Heading 3',
    //   icon: { path: TypeH3 },
    //   onAction: () => formatHeading({ level: 3 }),
    // },
    {
      uid: 4,
      value: 'Bullet List',
      icon: { path: ListUl },
      onAction: () => formatBulletList(),
    },
    {
      uid: 5,
      value: 'Numbered List',
      icon: { path: ListOl },
      onAction: () => formatNumberedList(),
    },
  ]

  if (!editor) {
    return null
  }

  const formatParagraph = () => {
    editor.chain().focus().setParagraph().run()
  }

  const formatHeading = (headingSize) => {
    editor.chain().focus().toggleHeading(headingSize).run()
  }

  const formatBulletList = () => {
    editor.chain().focus().toggleBulletList().run()
  }

  const formatNumberedList = () => {
    editor.chain().focus().toggleOrderedList().run()
  }

  const onFontSizeChange = (e) => {
    const { value } = e.target
    setFontSize(value)
  }

  const onFontSizeKeyDown = (e) => {
    if (e.key === 'Enter') {
      editor.chain().focus().setFontSize(`${fontSize}px`).run()
    }
  }

  const onLinkChange = (e) => {
    const { value } = e.target
    setLink(value)
  }

  const handleInputFileChange = (e) => {
    if (e.target.files[0]) {
      editor
        .chain()
        .focus()
        .setImage({ src: URL.createObjectURL(e.target.files[0]) })
        .run()
    }
  }

  const addImage = () => {
    inputFileRef.current.click()
  }

  return (
    <S.Container>
      <S.Menu>
        <Input
          hidden
          type='file'
          onChange={handleInputFileChange}
          ref={(e) => {
            inputFileRef.current = e
          }}
        />
        <S.Button
          type='button'
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          <img src={ArrowCounterclockwise} alt='Undo' />
        </S.Button>
        <S.Button
          type='button'
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          <img src={ArrowClockwise} alt='Redo' />
        </S.Button>
        <S.Hr />
        <DropdownMenu
          button={{
            highlightedItem: 0,
            buttonIcon: blockFormatDropDown[0].icon?.path,
            buttonLabel: blockFormatDropDown[0].value,
          }}
        >
          {blockFormatDropDown.map((item, index) => {
            const { value, icon, onAction } = item
            return (
              <DropdownItem
                index={index}
                buttonIcon={icon?.path}
                buttonLabel={value}
                onAction={onAction}
              >
                <S.DropdownIcon src={icon?.path} />
                <S.DropdownParagraph>{value}</S.DropdownParagraph>
              </DropdownItem>
            )
          })}
        </DropdownMenu>
        <S.Hr />
        <DropdownMenu
          button={{
            highlightedItem: 0,
            buttonIcon: FontFamily,
            buttonLabel: fontFamilyDropDown[0].value,
          }}
        >
          {fontFamilyDropDown.map((item, index) => {
            const { value, icon, onAction } = item
            return (
              <DropdownItem
                index={index}
                buttonLabel={value}
                onAction={onAction}
              >
                <S.DropdownIcon src={icon?.path} />
                <S.DropdownParagraph>{value}</S.DropdownParagraph>
              </DropdownItem>
            )
          })}
        </DropdownMenu>
        <DropdownMenu
          button={{
            onChange: onFontSizeChange,
            onKeyDown: (value) =>
              editor.chain().focus().setFontSize(`${value}px`).run(),
            highlightedItem: 0,
            buttonLabel: fontSizeDropDown[0].value,
            value: fontSize,
            width: '90px',
          }}
        >
          {fontSizeDropDown.map((item, index) => {
            const { value, onAction } = item
            return (
              <DropdownItem
                index={index}
                buttonLabel={value}
                onAction={onAction}
              >
                <S.DropdownParagraph>{value}</S.DropdownParagraph>
              </DropdownItem>
            )
          })}
        </DropdownMenu>
        <S.Hr />
        <S.Button
          type='button'
          onClick={() => editor.chain().focus().toggleBold().run()}
        >
          <img src={TypeBold} alt='Bold' />
        </S.Button>
        <S.Button
          type='button'
          onClick={() => editor.chain().focus().toggleItalic().run()}
        >
          <img src={TypeItalic} alt='Italic' />
        </S.Button>
        <S.Button
          type='button'
          onClick={() => editor.chain().focus().toggleUnderline().run()}
        >
          <img src={TypeUnderline} alt='Underline' />
        </S.Button>
        <S.Wrapper ref={domNode}>
          <S.Button type='button' onClick={() => setOpenDialog(!openDialog)}>
            <img src={TypeLink} alt='Underline' />
          </S.Button>
          {openDialog && (
            <Dialog setIsOpen={setOpenDialog}>
              {isEditable ? (
                <>
                  <Input
                    type='text'
                    name='link'
                    defaultValue={link}
                    onChange={onLinkChange}
                  />
                  <S.DialogButton
                    type='button'
                    onClick={() => setIsEditable(false)}
                  >
                    <S.DialogIcon src={Close} />
                  </S.DialogButton>
                  <S.DialogButton
                    type='button'
                    onClick={() => {
                      editor
                        .chain()
                        .focus()
                        .extendMarkRange('link')
                        .setLink({ href: link })
                        .run()
                      setIsEditable(false)
                    }}
                  >
                    <S.DialogIcon src={Success} />
                  </S.DialogButton>
                </>
              ) : (
                <>
                  <Input type='text' name='link' value={link} readonly />
                  <S.DialogButton
                    type='button'
                    onClick={() => setIsEditable(true)}
                  >
                    <S.DialogIcon src={Pencil} />
                  </S.DialogButton>
                  <S.DialogButton
                    type='button'
                    onClick={() => {
                      editor.chain().focus().unsetLink().run()
                      setLink('https://')
                      setOpenDialog(false)
                    }}
                  >
                    <S.DialogIcon src={Trash} />
                  </S.DialogButton>
                </>
              )}
            </Dialog>
          )}
        </S.Wrapper>
        <S.InputColor>
          <DropdownMenu
            button={{
              buttonIcon: BgColor,
              buttonLabel: '',
            }}
          >
            {colorDropDown.map((item, index) => {
              const { value, icon, onAction } = item
              return (
                <DropdownItem
                  index={index}
                  // buttonIcon={icon?.path}
                  buttonLabel={value}
                  onAction={onAction}
                >
                  {/* <S.DropdownIcon src={icon?.path} /> */}
                  <S.DropdownParagraph>{value}</S.DropdownParagraph>
                </DropdownItem>
              )
            })}
          </DropdownMenu>
          <input
            ref={colorRef}
            type='color'
            onInput={(event) => {
              editor.chain().focus().setColor(event.target.value).run()
              setColor(event.target.value)
            }}
            value={editor.getAttributes('textStyle').color}
          />
        </S.InputColor>
        <S.Hr />
        {/* <DropdownMenu
        button={{
          buttonIcon: Plus,
          buttonLabel: 'Insert',
        }}
      >
        {insertDropDown.map((item, index) => {
          const { value, icon, onAction } = item
          return (
            <DropdownItem index={index} buttonLabel={value} onAction={onAction}>
              <S.DropdownIcon src={icon?.path} />
              <S.DropdownParagraph>{value}</S.DropdownParagraph>
            </DropdownItem>
          )
        })}
      </DropdownMenu> */}
        {/* <S.Hr /> */}
        <DropdownMenu
          button={{
            highlightedItem: 0,
            buttonIcon: alignDropDown[0].icon?.path,
            buttonLabel: alignDropDown[0].value,
          }}
        >
          {alignDropDown.map((item, index) => {
            const { value, icon, onAction } = item
            return (
              <DropdownItem
                index={index}
                buttonIcon={icon?.path}
                buttonLabel={value}
                onAction={onAction}
              >
                <S.DropdownIcon src={icon?.path} />
                <S.DropdownParagraph>{value}</S.DropdownParagraph>
              </DropdownItem>
            )
          })}
        </DropdownMenu>
      </S.Menu>
      <S.Tabs>
        <S.TabList>
          {tabs?.map((tab, index) => (
            <S.Tab key={index} onClick={tab?.onAction}>
              {tab?.value}
            </S.Tab>
          ))}
          {/* <S.Tab>Advies</S.Tab>
          <S.Tab>Styling</S.Tab>
          <S.Tab>2D Ontwerp</S.Tab>
          <S.Tab>3D Ontwerp</S.Tab> */}
        </S.TabList>
        <S.TabList style={{ marginLeft: 'auto' }}>
          {tabs?.options.map((tab, index) => (
            <S.Tab key={index} onClick={tab?.onAction}>
              {tab?.value}
            </S.Tab>
          ))}
          {/* <S.Tab>Preview</S.Tab>
          <S.Tab>Complete</S.Tab> */}
        </S.TabList>
      </S.Tabs>
    </S.Container>
  )
}

export default MenuBar
