import styled from 'styled-components'

export const Button = (props) => {
  let { style, buttonProps, onClick, children, type } = props

  return (
    <Container style={style} type={type} {...buttonProps} onClick={onClick}>
      {children}
    </Container>
  )
}

const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;

  background: none;
  border-radius: 8px;
  //width: 60px;
  height: 36px;
  padding: 0 8px !important;
  cursor: pointer;
  color: white;

  &:hover {
    background-color: #eee;
    color: #444;
  }
  /* vertical-align: middle; */
  /* flex-shrink: 0; */
  /* align-items: center; */
  /* justify-content: space-between; */
`
