import * as React from 'react'
import { QUESTION_PAGE, PUZZLE_PAGE, SOLUTION_PAGE, RESULT_PAGE } from 'pages'
import Tab from 'layouts/tab'

const TabletMobile = (props) => {
  const { tabs, path, setPath } = props

  const handlePageData = () => {
    let page = {}

    switch (path) {
      case '/puzzle':
        page = PUZZLE_PAGE
        break
      case '/solution':
        page = SOLUTION_PAGE
        break
      case '/result':
        page = RESULT_PAGE
        break
      default:
        page = QUESTION_PAGE
        break
    }

    return page
  }

  const { icon, heading, text } = handlePageData()

  return (
    <Tab
      tabs={tabs}
      path={path}
      setPath={setPath}
      icon={icon}
      name={heading}
      text={text}
    />
  )
}

export default TabletMobile
