import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileSignature,
  faFile,
  faCircleInfo,
  faArrowLeft,
  faCheck,
  faXmark,
  faPlus,
  faTrash,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'

import * as F from './Form.styled'
import { StepperList, StepperTrigger } from 'components/ui/form/FormStepper'
import { Separator } from 'components/ui/separator/Separator'
import useClickOutside from 'hooks/use-click-outside'
import Input from 'features/ui/input.component'
import Tiptap from 'features/ui/editor/editor.component'

const Form = (props) => {
  const dispatch = useDispatch()

  const {
    id,
    isVisible,
    loading,
    form,
    item,
    routeGroup,
    onPrevStep,
    onSubmit,
    updateEditor,
    updateStep,
  } = props

  const [content, setContent] = React.useState(form?.content)

  const navigate = useNavigate()

  const contentEl = React.useRef(null)

  let domNode = useClickOutside(() => {
    if (id) {
      navigate(`${routeGroup}/${id}`)
    } else {
      navigate(routeGroup)
    }
  })

  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors, isDirty, isValid },
    control,
  } = useForm({
    defaultValues: {
      content: [],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'content',
    rules: {
      required: true,
    },
  })

  React.useEffect(() => {
    if (form.editor) setValue('content', form?.editor)

    if (id) {
      if (item.content.length > 0 && form.editor.length < 1) {
        setValue('content', item?.content)
      }
    }
  }, [id, item, form])

  React.useEffect(() => {
    if (contentEl) {
      contentEl.current.addEventListener('DOMNodeInserted', (event) => {
        const { currentTarget: target } = event
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' })
      })
    }
  }, [])

  const handleAppend = (event) => {
    event.preventDefault()
    append({ subTitle: '', description: '' })
  }

  const handleLoadingSpinner = (isLoading, onSubmit) => {
    if (isLoading)
      return (
        <F.Button type='button' disabled={isLoading}>
          <FontAwesomeIcon icon={faSpinner} spin />
        </F.Button>
      )
    if (onSubmit)
      return (
        <F.Button type='submit'>
          <FontAwesomeIcon icon={faCheck} />
        </F.Button>
      )
  }

  const isNotEmpty = (value) => {
    const clearHTMLTags = value.replace(/(<([^>]+)>)/gi, '')

    return clearHTMLTags.trim() !== ''
  }

  const onStepChange = (data, step) => {
    dispatch(updateEditor(data))
    dispatch(updateStep(step))
  }

  return (
    <React.Fragment>
      <F.Container ref={domNode}>
        <StepperList>
          {isVisible?.title && (
            <StepperTrigger
              onClick={() => onStepChange(getValues('content'), 'title')}
            >
              <span>
                <FontAwesomeIcon icon={faFileSignature} />
              </span>
              Title
            </StepperTrigger>
          )}
          {isVisible?.files && (
            <React.Fragment>
              <Separator />
              <StepperTrigger
                onClick={() => onStepChange(getValues('content'), 'files')}
              >
                <span>
                  <FontAwesomeIcon icon={faFile} />
                </span>
                Files
              </StepperTrigger>
            </React.Fragment>
          )}
          {isVisible?.details && (
            <React.Fragment>
              <Separator />
              <StepperTrigger
                active
                onClick={() => onStepChange(getValues('content'), 'details')}
              >
                <span>
                  <FontAwesomeIcon icon={faCircleInfo} />
                </span>
                Details
              </StepperTrigger>
            </React.Fragment>
          )}
        </StepperList>
        <F.Form
          onSubmit={(e) =>
            handleSubmit(onSubmit(e, { editor: getValues('content') }))
          }
        >
          <F.Body ref={contentEl}>
            {fields.length === 0 && (
              <F.Button
                style={{ marginLeft: 'auto' }}
                solid
                type='button'
                onClick={handleAppend}
              >
                Write a Post
              </F.Button>
            )}
            {fields.map((field, index) => (
              <div
                key={field.id}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <F.Field style={{ marginBottom: '10px' }}>
                  <Input
                    schema={{ required: true }}
                    placeholder='Subtitle'
                    type='text'
                    name={`content.${index}.subTitle`}
                    label='Subtitle'
                    register={register}
                    validationSchema={{
                      required: true,
                    }}
                  />
                  {errors.subTitle && (
                    <F.ErrorMessage>Subtitle is required</F.ErrorMessage>
                  )}
                </F.Field>
                <F.Field>
                  <Controller
                    control={control}
                    name={`content.${index}.description`}
                    {...register(`content.${index}.description`)}
                    rules={{
                      required: true,
                      validate: isNotEmpty,
                    }}
                    render={({ field }) => {
                      return (
                        <Tiptap
                          {...field}
                          editable={true}
                          // onChange={(e) => {
                          //   onChange(e)
                          // }}
                          // value={value}
                        />
                      )
                    }}
                  />
                </F.Field>
                <F.ActionGroup>
                  <F.Button type='button' outline onClick={() => remove(index)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </F.Button>
                  {fields.length - 1 === index && (
                    <F.Button
                      solid
                      type='button'
                      disabled={!isValid}
                      onClick={handleAppend}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </F.Button>
                  )}
                </F.ActionGroup>
                {fields.length - 1 !== index && <F.Hr />}
              </div>
            ))}
          </F.Body>
          <F.ActionGroup>
            <F.Button
              type='button'
              outline
              onClick={() => onStepChange(getValues('content'), 'files')}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </F.Button>
            {handleLoadingSpinner(loading, onSubmit)}
          </F.ActionGroup>
        </F.Form>
      </F.Container>
      <F.Close>
        <F.Button type='button' outline>
          <FontAwesomeIcon icon={faXmark} />
        </F.Button>
      </F.Close>
    </React.Fragment>
  )
}

export default Form
