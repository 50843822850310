import React, { forwardRef } from 'react'
import { useTextField } from 'react-aria'

import './textField.css'
import styled from 'styled-components'

const TextField = forwardRef((props, ref) => {
  let { label } = props
  let {
    labelProps,
    inputProps,
    descriptionProps,
    errorMessageProps,
    isInvalid,
    validationErrors,
  } = useTextField(props, ref)

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <label {...labelProps}>{label}</label>
      <Input {...inputProps} ref={ref} />
      {props.description && (
        <div {...descriptionProps} style={{ fontSize: 12 }}>
          {props.description}
        </div>
      )}
      {isInvalid && (
        <div {...errorMessageProps} style={{ color: 'red', fontSize: 12 }}>
          {validationErrors.join(' ')}
        </div>
      )}
    </div>
  )
})

export default TextField

const Input = styled.input`
  min-width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid rgb(141, 177, 211);
  margin-top: 8px;
  padding: 0 16px;
`
