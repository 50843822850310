import { createGlobalStyle } from 'styled-components'

import BrittanySignature from 'assets/fonts/Brittany-Signature/Brittany-Signature.ttf'
import VLArchLightCondLight from 'assets/fonts/VlArchlightcondLight/VLArchLightCondLight.OTF'
import QuoteLeftDesktop from 'assets/icons/quote-left-desktop.svg'
import QuoteLeftMobile from 'assets/icons/quote-left-mobile.svg'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Brittany Signature';
    src: url(${BrittanySignature});
  }

  @font-face {
    font-family: 'VL Arch Light Cond Light';
    src: url(${VLArchLightCondLight});
  }
  
  * {
    box-sizing: border-box;
  }

  @media only screen and (min-width: 1224px) {
    .tiptap-quote {
      background-image: url(${QuoteLeftDesktop});
      height: 30px;
      background-position: left;
      background-size: contain;
      background-repeat: no-repeat;
      margin-bottom: 60px;
      display: block;
    }
  }

  @media only screen and (max-width : 1223px) {
    .tiptap-quote {
      background-image: url(${QuoteLeftDesktop});
      height: 32px;
      background-position: left;
      background-size: contain;
      background-repeat: no-repeat;
      margin-bottom: 32px;
      display: block;
    }
  }
`

const FormStyle = createGlobalStyle``

export default GlobalStyle
