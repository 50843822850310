import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useNavigate, Link } from 'react-router-dom'

import logo from 'assets/logo.png'
import { SliderMobile } from 'features/ui/sliderMobile.component'
import Preview from 'features/ui/preview.component'
import Editor from 'features/ui/editor.component'
import Tiptap from 'features/ui/editor/editor.component'
import { theme } from 'theme'

const Detail = (props) => {
  const { elements, element, title, titlePath, isText } = props
  const navigate = useNavigate()
  const sliderRef = React.useRef(null)

  const [loading, setLoading] = useState(false)

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0 })
  }

  React.useEffect(() => {
    scrollToTop()
  }, [])

  const transformProject = () => {
    let newElements = []

    if (element) {
      const { files, content } = element

      if (files && files.length > 0) {
        const newFiles = files.map((file) => ({ file: file, type: 'file' }))
        newElements = newElements.concat(newFiles)
      }

      if (content && content.length > 0) {
        newElements = newElements.concat(
          content.map((value) => ({ content: value, type: 'default' }))
        )
      }
    }

    return newElements
  }

  const subTitle = transformProject()

  return (
    <Container>
      <Header>
        <Logo src={logo} onClick={() => navigate('/')} />
        <Title>{element.title}</Title>
      </Header>
      <Wrapper>
        <SliderWrapper ref={sliderRef}>
          <SliderMobile names={subTitle} length={transformProject().length}>
            {/* [currentIndex].content?.subTitle */}
            {transformProject().map((value) => {
              if (value.type === 'default') {
                return (
                  <SliderItem>
                    <Tiptap
                      value={value.content?.description}
                      editable={false}
                    />
                    {/* <Editor
                      className='editor-shell'
                      isEditable={false}
                      data={value.content?.description}
                    /> */}
                  </SliderItem>
                )
              }

              if (value.type === 'file') {
                return (
                  <SliderItem isText={isText}>
                    <Preview files={value.file} />
                  </SliderItem>
                )
              }
            })}
          </SliderMobile>
        </SliderWrapper>
        {/* {elements.length > 0 && (
          <>
            <Hr />
            <MoreBy>
              <PrimaryFont>{title}</PrimaryFont>
            </MoreBy>
            <DataGrid>
              {elements.map((element) => (
                <Link onClick={scrollToTop} to={`${titlePath}/${element.id}`}>
                  <Card>
                    <Preview files={element.thumbnail} />
                  </Card>
                </Link>
              ))}
            </DataGrid>
          </>
        )} */}
      </Wrapper>
    </Container>
  )
}

export default Detail

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  bottom: 16px;

  /* span {
    max-width: 20px !important;
    height: 20px;
    background-color: yellow;
  } */
`

const SubTitle = styled.h2`
  /* position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%); */
  margin: 30px 0 30px 20px;
  padding: 0;
  /* padding-left: 10px; */
  font-family: 'Brittany Signature';
  font-size: 32px;
  //text-align: center;
  color: white;
`

const SliderWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 16px;
`

const SliderItem = styled.div`
  //display: inline-block;
  flex-shrink: 0;
  width: 100%;
  height: calc(100vh - 100px - 46px - (16px * 2) - 38px);
  /* margin: 0;
  padding: 0; */
  position: relative;
  //background-color: ${theme.colors.primary};
  overflow-y: auto;
  line-height: 1.3;

  .small-device {
    max-width: 10px !important;
    height: 100%;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  //padding-bottom: 10px;

  .previewPDF {
    //flex-shrink: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    background-color: ${theme.colors.quaternary};
  }

  /* .react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  } */

  .previewImage {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    /* overflow: hidden; */
    /* background-color: ${theme.colors.quaternary}; */
    img {
      width: 100%;
      height: 100%;
      ${({ isText }) =>
        isText
          ? css`
              object-fit: contain;
            `
          : css`
              object-fit: cover;
            `}
    }
  }

  .previewVideo {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;

    video {
      //flex-shrink: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      /* object-fit: cover;
      margin: 0;
      padding: 0; */
    }
  }

  .editor-shell {
    width: 100%;
    //height: 100%;
    margin: 0 !important;
    padding: 10px 10px;
    /* padding: 10px !important;
    padding-top: 0 !important; */
    font-size: 16px !important;
    border: 0 !important;
  }

  .editor-paragraph {
    margin: 0;
    padding: 0;
    font-family: 'VL Arch Light Cond Light';
    font-size: 20px;
    color: white;
    //line-height: 1.3;
  }

  .editor-list-ol {
    margin: 0;
    //margin-top: 10px;
    margin-bottom: 20px;
    padding: 0;
    padding-top: 10px;
    font-family: 'Brittany Signature';
    font-size: 24px;
    color: white;
    list-style-position: inside;
    list-style: none;
    counter-increment: css-counter 1;

    li {
      margin: 0;
      padding: 0;
      &::before {
        content: counter(css-counter);
        margin: 0 20px 0 0;
        padding: 0;
      }
    }
  }
`

const DataGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 16px;
`

const Card = styled.div`
  .previewPDF {
    width: 100%;
    aspect-ratio: 1 / 1;
    /* height: 395px; */
    border-radius: 8px;
    overflow: hidden;
    background-color: ${theme.colors.quaternary};
  }

  .previewImage {
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 8px;
    overflow: hidden;
    background-color: ${theme.colors.quaternary};

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:last-child {
    margin-bottom: 16px;
  }
`

const Hr = styled.hr`
  width: calc(100% - 32px);
  margin: 0 16px;
  margin-top: 16px;
  border: 1px solid ${theme.colors.quaternary};
`

const MoreBy = styled.div`
  padding: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
`

const PrimaryFont = styled.h1`
  font-family: 'VL Arch Light Cond Light';
  font-size: 20px;
  color: ${theme.colors.secondary};
  margin: 0;
`

const Logo = styled.img`
  width: 40px;
  margin-left: 20px;
  cursor: pointer;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100px;
  background-color: ${theme.colors.quaternary};
`

const Wrapper = styled.div`
  position: absolute;
  top: 116px;
  width: 100%;
`

const Title = styled.h1`
  margin: 0 20px 0 0;
  margin-left: auto;
  font-family: 'Brittany Signature';
  font-size: 32px;
  color: ${theme.colors.primary};
`
