import styled, { css } from 'styled-components'

const GLOBAL = css`
  .ProseMirror {
    counter-reset: all;

    p {
      margin: 0;
      font-family: 'VL Arch Light Cond Light';
      font-size: 18px;
      color: #8e877c;
    }
  }

  .ProseMirror h1 {
    margin: 24px 0;
    padding: 0;
    font-family: 'Brittany Signature';
    font-size: 32px;
    text-align: left;
    color: #8e877c;
  }

  .ProseMirror h2 {
    margin: 24px 0;
    padding: 0;
    font-family: 'Brittany Signature';
    font-size: 24px;
    text-align: left;
    color: #8e877c;
  }

  .ProseMirror ol {
    margin: 0;
    padding: 0;
    font-family: 'Brittany Signature';
    font-size: 32px;
    color: #8e877c;
    list-style-position: inside;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      margin: 24px 0;
      padding: 0;
      font-family: 'Brittany Signature';
      font-size: 32px;

      &::before {
        font-family: 'Brittany Signature';
        font-size: 32px;
        counter-increment: all;
        content: counter(all);
        margin: 0 24px 0 0;
        padding: 0;
      }

      p {
        font-family: 'Brittany Signature';
        font-size: 32px;
      }
    }
  }

  .ProseMirror ul {
    margin: 24px 0;
    padding: 0;
    font-family: 'Brittany Signature';
    font-size: 32px;
    color: #8e877c;

    list-style-position: inside;
    list-style: none;

    li {
      display: flex;
      align-items: center;

      &::before {
        content: '•';
        margin: 0 24px 0 0;
        padding: 0;
      }

      p {
        font-family: 'Brittany Signature';
        font-size: 32px;
      }
    }
  }

  .ProseMirror img {
    height: auto;
    max-width: 100%;
  }

  .ProseMirror a {
    color: #68cef8;
    cursor: pointer;
  }
`

const READ_ONLY = css`
  .ProseMirror {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 40px;
    overflow-y: scroll;
    position: absolute;
    bottom: 0;
  }

  &:focus-visible {
    outline: 0;
    border: 0 !important;
  }
`

const IS_ADMIN = css`
  .ProseMirror {
    width: 100%;
    /* height: 100%; */
    margin: 0;
    /* overflow-y: scroll; */

    &:focus {
      outline: none;
    }
  }
`

const IS_EDITABLE = css`
  .ProseMirror {
    margin: 0;
    padding: 8px 16px;
    background: #fff;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; */
    border: none;
    //border-left: 1px solid #444;
    width: 100%;
    min-height: 150px;
    outline: none;
    resize: none;
  }

  .ProseMirror p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  .ProseMirror .ProseMirror-selectednode {
    outline: 3px solid #68cef8;
  }
`

export const Tiptap = styled.div`
  width: 100%;
  height: 100%;
  ${GLOBAL}
  ${({ isAdmin, editable }) =>
    isAdmin ? IS_ADMIN : editable ? IS_EDITABLE : READ_ONLY} /* ${({
    editable,
  }) => (editable ? IS_EDITABLE : READ_ONLY)} */
`
