import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

import logo from 'assets/logo.png'
import { theme } from 'theme'
import useMediaQuery from 'hooks/use-media-query'
import GridView from 'components/GridView'
import GridViewMobile from 'layouts/grid'
import { deletePost } from './createAction'
import { resetForm } from './postSlice'

const ROUTE_GROUP = '/posts'

const Posts = () => {
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const { breakpoints } = theme
  const { desktopOrLaptop, tabletOrMobile, mobile } = breakpoints

  const isDesktopOrLaptop = useMediaQuery(desktopOrLaptop)
  const isTabletOrMobile = useMediaQuery(tabletOrMobile)
  const isMobile = useMediaQuery(mobile)

  const { user } = useSelector((state) => state.auth)
  const { posts, isLoading } = useSelector((store) => store.posts)

  const handleDeleteData = (e, id) => {
    e.preventDefault()
    // findByID
    // const project = data.find((item) => item.id === id)
    if (window.confirm('Are you sure you want to delete this post?')) {
      dispatch(deletePost({ id, posts }))
      // dispatch(deleteData({ key, data }))
    }
  }

  if (isLoading)
    return (
      <Loading>
        <FontAwesomeIcon icon={faSpinner} spin />
      </Loading>
    )

  // Set loading state isLoading && to fix issue
  return (
    <React.Fragment>
      {isDesktopOrLaptop && (
        <GridView
          title='Blog'
          user={user}
          data={posts}
          toDetails={(id) => `${ROUTE_GROUP}/${id}`}
          toCreateForm={() => {
            dispatch(resetForm())
            navigate(`${ROUTE_GROUP}/form`)
          }}
          handleDeleteData={handleDeleteData}
        />
      )}
      {isMobile && (
        <Container>
          <Header>
            <Logo src={logo} onClick={() => navigate('/')} />
            <Title>Posts</Title>
          </Header>
          {/* <Header>
            <Title>Posts</Title>
          </Header> */}
          <Wrapper>
            <GridViewMobile list data={posts} path='/posts' />
          </Wrapper>
        </Container>
      )}
    </React.Fragment>
  )
}

export default Posts

const Container = styled.div``

const Header = styled.div`
  position: fixed;
  z-index: 1000;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 20px;
  padding-left: 10px;
  width: 100%;
  height: 100px;
  background-color: ${theme.colors.quaternary};
`

const Title = styled.h1`
  margin: 0 20px 0 0;
  margin-left: auto;
  font-family: 'Brittany Signature';
  font-size: 32px;
  color: ${theme.colors.primary};
`

const Logo = styled.img`
  width: 40px;
  margin-left: 20px;
  cursor: pointer;
`

const Wrapper = styled.div`
  padding-top: 120px;
`

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  color: ${theme.colors.secondary};
  font-size: 30px;
`
