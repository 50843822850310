import React from 'react'
import styled from 'styled-components'

import { theme } from 'theme'

const TextField = React.forwardRef((props, ref) => {
  const { className, name, label, register, validationSchema } = props

  return (
    <div className={className}>
      {label && <Label htmlFor={name}>{label}</Label>}
      {register ? (
        <Input
          {...props}
          name={name}
          className='textField-input'
          {...register(name, validationSchema)}
        />
      ) : (
        <Input {...props} ref={ref} />
      )}
    </div>
  )
})

export default TextField

const Label = styled.label`
  display: inline-block;
  font-family: 'VL Arch Light Cond Light';
  font-size: 18px;
  margin: 0 0 8px 0;
  padding: 0;
  color: ${theme.colors.quinary};
  line-height: 1;
`

const Input = styled.input`
  /* width: 100%;
  height: 50px;
  margin-bottom: 10px;
  padding: 0 30px;
  border-radius: 8px;
  font-family: 'VL Arch Light Cond Light';
  font-size: 18px;
  line-height: 1;
  background-color: #444;
  color: white;
  border: none;
  outline: 0; */

  border: 1px solid #444;
  border-radius: 8px;
  width: 100%;
  height: 44px;
  margin: 0;
  padding: 0 16px;
  font-size: 12px;
  background-color: white;
  color: #444;

  &:focus {
    outline: none;
    border: 2px solid ${theme.colors.secondary};
  }
`
