import React, {
  useRef,
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react'

export const AppStateContext = createContext()

export function AppProvider({ children }) {
  const [state, setState] = useState({})
  const previousValueRef = useRef(null)

  useEffect(() => {
    // Update the previous value ref whenever the value changes
    previousValueRef.current = state
  }, [state])

  return (
    <AppStateContext.Provider
      value={{ state, setState, previousState: previousValueRef }}
    >
      {children}
    </AppStateContext.Provider>
  )
}

export function useAppState() {
  const context = useContext(AppStateContext)
  if (!context) {
    throw new Error('useAppState must be used within the AppProvider')
  }
  return context
}

// import { createContext, useState, useEffect } from 'react'

// import { onAuthStateChangedListener } from 'utils/firebase/firebase.utils'

// export const UserContext = createContext({
//   setCurrentUser: () => null,
//   currentUser: null,
// })

// export const UserProvider = ({ children }) => {
//   const [currentUser, setCurrentUser] = useState(null)
//   const value = { currentUser, setCurrentUser }

//   useEffect(() => {
//     const unsubscribe = onAuthStateChangedListener((user) => {
//       console.log(user)
//       setCurrentUser(user)
//     })

//     return unsubscribe
//   }, [])

//   return <UserContext.Provider value={value}>{children}</UserContext.Provider>
// }
