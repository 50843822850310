import React from 'react'
import {
  Button,
  Dialog,
  DialogTrigger,
  Heading,
  Input,
  Label,
  Modal,
  TextField,
} from 'react-aria-components'

export const RACModal = (props) => {
  const { children, isOpen, setOpen, onPress } = props

  return (
    <Modal isDismissable isOpen={isOpen} onOpenChange={setOpen}>
      <Dialog>
        <Heading slot='title'>Notice</Heading>
        {children}
      </Dialog>
      <RACButton onPress={onPress}>Submit</RACButton>
    </Modal>
  )
}

export const RACButton = (props) => {
  const { children, onPress } = props

  return <Button onPress={onPress}>{children}</Button>
}
