import React from 'react'
import styled from 'styled-components'

import { theme } from 'theme'

const { brittanySignature } = theme.textStyles

export const BrittanySignature = (props) => {
  const { children } = props
  return (
    // style={{...brittanySignature}}
    <BrittanySignatureContainer {...props}>
      {children}
    </BrittanySignatureContainer>
  )
}

const BrittanySignatureContainer = styled.h1`
  ${brittanySignature}
  margin: 20px 0;
`
