import * as React from 'react'

import Input from 'components/input'
import useClickOutside from 'hooks/use-click-outside'
import ChevronDown from 'assets/icons/chevron-down.svg'
import * as S from './styles'

export const DropdownMenu = (props) => {
  const { children, button } = props

  const [isOpen, setIsOpen] = React.useState(false)

  const [item, setItem] = React.useState({
    isOpen: false,
    index: 0,
    active: 0,
    buttonIcon: '',
    buttonLabel: '',
    onAction: null,
  })

  React.useEffect(() => {
    setItem({
      active: button?.highlightedItem,
      ...button,
    })
  }, [])

  const clickOutside = useClickOutside(() => setIsOpen(false))

  const handleMenuTrigger = (e) => {
    e.preventDefault()

    setIsOpen(!isOpen)
  }

  const handleOnValueChange = (props) => {
    const { index, buttonIcon, buttonLabel, onAction } = props

    if (button.highlightedItem !== undefined) {
      setItem((prevState) => ({
        active: index,
        buttonIcon,
        buttonLabel,
        onAction,
      }))
    } else {
      setItem((prevState) => ({
        ...prevState,
        active: index,
        onAction,
      }))
    }

    setIsOpen(false)
  }

  const handleOnInputChange = (e) => {
    const { value } = e.target
    setItem((prevState) => ({
      ...prevState,
      buttonLabel: value,
    }))
  }

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      button.onKeyDown(item.buttonLabel)
      setIsOpen(false)
    }
  }

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        onClick: handleOnValueChange,
        active: item.active,
      })
    }
    return child
  })

  return (
    <S.DropdownMenu ref={clickOutside}>
      {button.onChange ? (
        <div onClick={handleMenuTrigger}>
          <Input
            onChange={handleOnInputChange}
            onKeyDown={handleOnKeyDown}
            type='text'
            // defaultValue={button.buttonLabel}
            value={item.buttonLabel}
            style={{ width: button.width }}
          />
        </div>
      ) : (
        <S.DropdownMenuTrigger type='button' onClick={handleMenuTrigger}>
          <S.DropdownIcon
            buttonLabel={button?.buttonLabel}
            src={item.buttonIcon || button?.buttonIcon}
          />
          <S.Paragraph>{item.buttonLabel}</S.Paragraph>
          <S.DropdownMore src={ChevronDown} alt='Chevron Down' />
        </S.DropdownMenuTrigger>
      )}

      {isOpen && (
        <S.DropdownMenuContent>{childrenWithProps}</S.DropdownMenuContent>
      )}
    </S.DropdownMenu>
  )
}

export const DropdownItem = (props) => {
  const {
    children,
    onClick,
    buttonIcon,
    buttonLabel,
    onAction,
    index,
    active,
  } = props

  return (
    <S.DropdownMenuItem
      active={active === index}
      onClick={() =>
        onClick({ buttonIcon, buttonLabel, onAction: onAction(), index })
      }
    >
      {children}
    </S.DropdownMenuItem>
  )
}
