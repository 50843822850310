import React, { useEffect, useContext } from 'react'
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

// import { setCurrentUser, logout } from 'app/user/user.slice'
import { fetchPage as fetchAboutPage } from 'features/about/createAction'
import { fetchPage as fetchApproachPage } from 'features/approach/createAction'
import { onAuthStateChangedListener } from './features/auth/authAPI'
import { fetchProjects } from 'features/projects/createAction'
import { fetchPosts } from 'features/posts/createAction'
import Home from 'pages/home'
import About from 'pages/about'
import AboutForm from 'features/about/web/Form'
import ApproachForm from 'features/approach/web/Form'
import Approach from 'pages/approach'
import Process from 'pages/process'
import Contact from 'pages/contact'
import ProjectForm from 'features/projects/ProjectForm'
import ProjectList from './features/projects/Projects'
import ProjectItem from './features/projects/ProjectItem'

import PostForm from 'features/posts/PostForm'
import PostList from 'features/posts/Posts'
import PostItem from 'features/posts/PostItem'
import SignIn from 'pages/auth/signIn'
import PrivateRoutes from './pages/auth/privateRoutes'
import ForgotPassword from 'pages/auth/forgotPassword'
import styled, { css } from 'styled-components'
import { theme } from 'theme'

const App = () => {
  const projects = useSelector((store) => store.projects.data)
  const { loading, data } = useSelector((store) => store.projects)
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth.user)

  // const [projects, setProjects] = React.useState([])

  // const logoutHandler = () => {
  //   dispatch(logout(user))
  //   signOutHandler()
  // }

  // const fetchCurrentUser = () => {
  //   const unsubscribe = onAuthStateChangedListener((user) => {
  //     const pickedUser =
  //       user &&
  //       (({ accessToken, email }) => ({
  //         accessToken,
  //         email,
  //       }))(user)
  //     dispatch(setCurrentUser(pickedUser))
  //   })

  //   return unsubscribe
  // }

  // const fecthProjectData = async () => {
  //   try {
  //     const response = await readData('projects/')
  //     setProjects(response)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  useEffect(() => {
    // fetchCurrentUser()
    // fecthProjectData()
    dispatch(fetchAboutPage())
    dispatch(fetchApproachPage())
    onAuthStateChangedListener(dispatch)
    dispatch(fetchProjects())
    dispatch(fetchPosts())
  }, [dispatch])

  if (loading)
    return (
      <Loading>
        <FontAwesomeIcon icon={faSpinner} spin />
      </Loading>
    )

  return (
    <Container>
      {/* {user && (
        <Header path={location.pathname === '/'} className='admin'>
          <Button onClick={logoutHandler}>Afmelden</Button>
        </Header>
      )} */}
      <Routes>
        <Route path='/'>
          <Route index element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path='approach' element={<Approach />} />
          <Route path='process' element={<Process />} />
          <Route path='contact' element={<Contact />} />
          <Route path='forgot-password' element={<ForgotPassword />} />
        </Route>
        <Route path='/projects'>
          <Route index element={<ProjectList />} />
          <Route path=':id' element={<ProjectItem />} />
        </Route>
        <Route path='/posts'>
          <Route index element={<PostList />} />
          <Route path=':id' element={<PostItem />} />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route path='/about/admin' element={<AboutForm />} />
          <Route path='/approach/admin' element={<ApproachForm />} />
          <Route path='/projects/form' element={<ProjectForm />} />
          <Route path='/projects/form/:id' element={<ProjectForm />} />
          <Route path='/posts/form' element={<PostForm />} />
          <Route path='/posts/form/:id' element={<PostForm />} />
          {/* <Route path='/form'>
            <Route path='title/:id' element={<Title />} />
            <Route path='files/:id' element={<Files />} />
            <Route path='details/:id' element={<Details />} />
            <Route path='title' element={<Title />} />
            <Route path='files' element={<Files />} />
            <Route path='details' element={<Details />} />
          </Route> */}
          {/* <Route path='/posts/form'>
            <Route path='title/:id' element={<PostTitle />} />
            <Route path='files/:id' element={<PostFiles />} />
            <Route path='details/:id' element={<PostDetails />} />
            <Route path='title' element={<PostTitle />} />
            <Route path='files' element={<PostFiles />} />
            <Route path='details' element={<PostDetails />} />
          </Route> */}
        </Route>
        <Route path='/admin' element={<SignIn />} />
      </Routes>
    </Container>
  )
}

export default App

const Container = styled.div`
  @media only screen and (max-width: 1223px) {
    .admin {
      display: none;
    }
  }
`

const Header = styled.div`
  ${({ path }) =>
    path &&
    css`
      position: fixed;
      top: 0;
      z-index: 1000;
    `}
  display: flex;
  width: 100%;
  margin: 0;
  padding: 10px;
  background-color: white;
  border-bottom: 1px solid ${theme.colors.secondary};
  //opacity: 0.9;
`

const Button = styled.button`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  margin: 0;
  margin-left: auto;
  padding: 0;
  background-color: transparent;
  color: ${theme.colors.secondary};
  width: 150px;
  height: 50px;
  border: 1px solid ${theme.colors.secondary};
  border-radius: 15px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.secondary};
    color: white;
    border: 1px solid ${theme.colors.secondary};
  }
`

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  color: ${theme.colors.secondary};
  font-size: 30px;
`
