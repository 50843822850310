import styled, { css } from 'styled-components'

import { theme } from 'theme'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% - 120px);
  height: calc(100vh - 120px);
  border: 2px solid ${theme.colors.quaternary};
  margin: 60px;
  padding: 30px;
  overflow-y: scroll;
`

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5000;
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid ${theme.colors.quaternary};
  background-color: #fff;
  cursor: pointer;

  img {
    width: 45px;
  }
`

export const TitleWrapper = styled.div`
  margin-bottom: 15px;
  /* position: absolute;
  right: 2%;
  top: 50%;
  transform: translateY(-50%);
  height: calc(100% - 120px); */
`

export const Title = styled.h1`
  margin: 0;
  font-family: 'Brittany Signature';
  font-size: 48px;
  color: ${theme.colors.quinary};
`

export const Row = styled.div`
  width: 100%;
`

export const Right = styled.div`
  position: fixed;
  top: 60px;
  right: 60px;
  bottom: 60px;
  z-index: -1;
  width: 25%;
  /* height: 100%; */
  background-color: ${theme.colors.primary};
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* gap: 30px; */
  /* width: 90%; */
`

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100%; */
  gap: 4%;
`

export const Well = styled.div`
  display: flex;
  align-items: center;
  /* height: 152px; */
  /* background-color: green; */
  width: calc(75% + (152px * 2) + 15px - (152px / 2 + 7.5px));
  min-height: 152px;
  /* min-width: 0;
  overflow-wrap: break-word; */
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  min-height: 152px;
  margin-right: 15px;
  padding: 15px;
  /* border: 2px solid ${theme.colors.quaternary}; */
`

export const TextContent = styled.div`
  font-family: 'VL Arch Light Cond Light';
  font-size: 18px;
  color: ${theme.colors.quinary};
  h3 {
    margin: 0;
  }
`

export const Section = styled.div`
  display: flex;
  gap: 15px;
`

export const Item = styled.div`
  display: flex;
  flex: 0 0 auto;
  height: 152px;
  aspect-ratio: 1 / 1;
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 4px solid ${theme.colors.primary};

  background-color: #fff;

  img {
    width: 60%;
    object-fit: cover;
  }
`

export const Subtitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  width: 100%;
  height: 45px;
  background-color: ${theme.colors.tertiary};
  font-family: 'VL Arch Light Cond Light';
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  /* display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  font-family: 'VL Arch Light Cond Light';
  font-size: 14px;
  text-transform: uppercase;
  background-color: ${theme.colors.tertiary};
  color: white;
  width: 100%;
  height: 35%; */
`
