// Container
// Logo
// Header
// Grid
// IconButton(color, rounded, solid, outlined)
// Card(hidden)

import styled, { css } from 'styled-components'

import { theme } from 'theme'

// const checkCommonStyles = css`
//   ${({ size }) =>
//     size &&
//     css`
//       width: ${size.width};
//       height: ${size.height};
//     `}

//   ${({ outlined }) =>
//     outlined
//       ? css`
//           border: ${outlined.lineWidth} ${outlined.lineStyle} ${outlined.color};
//         `
//       : css`
//           border: none;
//         `}

//   ${({ rounded }) =>
//     rounded &&
//     css`
//       border-radius: ${rounded};
//     `}

//     ${({ theme }) =>
//     theme &&
//     css`
//       background-color: ${theme.background};
//       color: ${theme.color};
//     `}
// `

const commonStyles = css`
  ${({ size }) =>
    css`
      width: ${size?.width};
      height: ${size?.height};
    `}

  ${({ outlined }) =>
    outlined
      ? css`
          border: ${outlined?.lineWidth} ${outlined?.lineStyle}
            ${outlined?.color};
        `
      : css`
          border: none;
        `}

  ${({ rounded }) =>
    css`
      border-radius: ${rounded};
    `}

    ${({ theme }) =>
    css`
      background-color: ${theme?.background};
      color: ${theme.color};
    `}
`

export const Container = styled.div`
  display: flex;
  position: relative;
  width: calc(100% - 124px);
  min-height: calc(100vh - 120px);
  border: 2px solid ${theme.colors.quaternary};
  margin: 60px;
  /* padding: 0 80px; */
`

export const Logo = styled.div`
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  margin-top: -50px;
  border-radius: 50%;
  border: 2px solid ${theme.colors.quaternary};
  background-color: #fff;
  cursor: pointer;

  img {
    width: 45px;
  }
`

export const QuoteLeft = styled.div`
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
  /* margin: auto 0;
  margin-left: 5%; */
  width: 40px;
  height: ${({ height }) => height && height};
  padding-top: 70px;

  img {
    width: 100%;
    object-fit: cover;
  }
`

export const QuoteRight = styled.div`
  font-size: 40px;
  padding: 0;
  position: absolute;
  z-index: 1000;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
  /* margin: auto 0;
  margin-left: 5%; */
  padding-top: 80px;
  width: 40px;
  height: ${({ height }) => height && height};
  font-size: 40px;
  margin-top: auto;

  img {
    width: 100%;
    object-fit: cover;
    position: absolute;
    bottom: 0;
  }
`

export const Header = styled.div`
  position: relative;
  display: flex;
  width: 90%;
  height: ${({ height }) => height && height};
  z-index: 2000;
  padding: 0 calc(5% + 50px);
  margin: auto auto;
  pointer-events: none;
`

export const Overlay = styled.div`
  position: absolute;
  left: 0;
  z-index: -1;
  background-color: white;
  height: 70px;
  width: 100%;
`

export const Title = styled.h1`
  margin: 10px 0;
  font-family: 'Brittany Signature';
  font-size: 32px;
  color: ${theme.colors.quinary};
  ${({ title }) =>
    title === 'Blog' &&
    css`
      margin-left: auto;
    `}
`

export const Grid = styled.div`
  /* margin: auto auto; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  //height: calc(100% - 120px);
  max-height: calc(100% - 120px);
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: space-between;
  overflow-y: scroll;
  padding: 0 50px;
  padding-top: 70px;
  padding-bottom: 25px;
  //padding-bottom: 21px;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const CardWrapper = styled.div`
  ${({ isVisible }) =>
    isVisible === false &&
    css`
      visibility: hidden;
    `}

  position: relative;
  display: flex;
  box-sizing: border-box;
  border: 4px solid ${theme.colors.quinary};
  flex: 1 0 calc(25% - 50px);
  aspect-ratio: 1 / 1;
`

export const Card = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  .previewPDF {
    height: 100%;

    .react-pdf__Page__canvas {
      height: 100%;
    }

    .react-pdf__Page__textContent {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .previewImage {
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

export const CardTitleWrapper = styled.div`
  position: absolute;
  z-index: 1000;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0;
  width: 60%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  //margin-top: -23px;
  background-color: ${theme.colors.tertiary};
`

export const CardTitle = styled.h1`
  margin: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
  text-transform: uppercase;
  font-family: 'VL Arch Light Cond Light';
  font-size: 16px;
`

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    height: 40px;
    object-fit: cover;
  }

  ${commonStyles}

  ${({ hoverState }) =>
    hoverState &&
    css`
      &:hover {
        width: ${hoverState.width};
        height: ${hoverState.height};
        border: ${hoverState.lineWidth} ${hoverState.lineStyle}
          ${hoverState.color};
        border-radius: ${hoverState.rounded};
        background-color: ${hoverState.background};
        color: ${hoverState.color};
      }
    `}

    ${({ onActive }) =>
    onActive &&
    css`
      &:active {
        width: ${onActive.width};
        height: ${onActive.height};
        border: ${onActive.lineWidth} ${onActive.lineStyle} ${onActive.color};
        border-radius: ${onActive.rounded};
        background-color: ${onActive.background};
        color: ${onActive.color};
      }
    `}

  ${({ position, spacing }) =>
    position === 'TopLeft' &&
    css`
      z-index: 2000;
      position: absolute;
      top: ${spacing};
      left: ${spacing};
    `}
  ${({ position, spacing }) =>
    position === 'TopCenter' &&
    css`
      z-index: 2000;
      position: absolute;
      top: ${spacing};
      left: 50%;
      transform: translateX(-50%);
    `}
  ${({ position, spacing }) =>
    position === 'TopRight' &&
    css`
      z-index: 2000;
      position: absolute;
      top: ${spacing};
      right: ${spacing};
    `}
  ${({ position, spacing }) =>
    position === 'MiddleLeft' &&
    css`
      z-index: 2000;
      position: absolute;
      top: 50%;
      left: ${spacing};
      transform: translateY(-50%);
    `}
  ${({ position }) =>
    position === 'MiddleCenter' &&
    css`
      z-index: 2000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
  ${({ position, spacing }) =>
    position === 'MiddleRight' &&
    css`
      z-index: 2000;
      position: absolute;
      top: 50%;
      right: ${spacing};
      transform: translateY(-50%);
    `}
  ${({ position, spacing }) =>
    position === 'BottomLeft' &&
    css`
      z-index: 2000;
      position: absolute;
      bottom: ${spacing};
      left: ${spacing};
    `}
  ${({ position, spacing }) =>
    position === 'BottomCenter' &&
    css`
      z-index: 2000;
      position: absolute;
      bottom: ${spacing};
      left: 50%;
      transform: translateX(-50%);
    `}
  ${({ position, spacing }) =>
    position === 'BottomRight' &&
    css`
      z-index: 2000;
      position: absolute;
      right: ${spacing};
      bottom: ${spacing};
    `}
    ${({ position, spacing }) =>
    position === 'LeftTop' &&
    css`
      z-index: 2000;
      position: absolute;
      top: ${spacing};
      left: ${spacing};
    `}
    ${({ position, spacing }) =>
    position === 'LeftCenter' &&
    css`
      z-index: 2000;
      position: absolute;
      top: 50%;
      left: ${spacing};
      transform: translateY(-50%);
    `}
    ${({ position, spacing }) =>
    position === 'LeftBottom' &&
    css`
      z-index: 2000;
      position: absolute;
      left: ${spacing};
      bottom: ${spacing};
    `}
    ${({ position, spacing }) =>
    position === 'RightTop' &&
    css`
      z-index: 2000;
      position: absolute;
      top: ${spacing};
      right: ${spacing};
    `}
    ${({ position, spacing }) =>
    position === 'RightCenter' &&
    css`
      z-index: 2000;
      position: absolute;
      top: 50%;
      right: ${spacing};
      transform: translateY(-50%);
    `}
    ${({ position, spacing }) =>
    position === 'RightBottom' &&
    css`
      z-index: 2000;
      position: absolute;
      right: ${spacing};
      bottom: ${spacing};
    `}
`
