import styled, { css } from 'styled-components'
import theme from 'theme'

import { buttonColors } from './button.colors'

const {
  primary,
  secondary,
  accent,
  destructive,
  muted,
  mutedForeground,
  light,
  dark,
} = buttonColors

const buttonColorsSolid = {
  textColor: '',
  textColorHover: '',
  textColorDisabled: '',
  buttonBackground: '',
  buttonBackgroundHover: '',
  buttonBackgroundPressed: '',
  buttonBackgroundDisabled: '',
  borderColor: '',
  borderColorHover: '',
  borderColorPressed: '',
  borderColorDisabled: '',
}

const buttonColorsOutline = {
  textColor: '',
  textColorHover: '',
  textColorDisabled: '',
  buttonBackground: '',
  buttonBackgroundHover: '',
  buttonBackgroundPressed: '',
  buttonBackgroundDisabled: '',
  borderColor: '',
  borderColorHover: '',
  borderColorPressed: '',
  borderColorDisabled: '',
}

const buttonColorsSecondary = {
  textColor: '',
  textColorHover: '',
  textColorDisabled: '',
  buttonBackground: '',
  buttonBackgroundHover: '',
  buttonBackgroundPressed: '',
  buttonBackgroundDisabled: '',
  borderColor: '',
  borderColorHover: '',
  borderColorPressed: '',
  borderColorDisabled: '',
}

const buttonColorsGhost = {}

const buttonColorsLink = {}

export const buttonVariants = {
  solid: css`
    background-color: ${primary};
    color: ${light};

    &:hover {
      background-color: ${accent};
    }
  `,
  outline: css`
    color: blue;
    background-color: 'transparent';
    border: 1px solid blue;
  `,
  secondary: css`
    ${buttonColors.secondaryColor}
  `,
  ghost: css``,
  link: css``,
}
