import { useDispatch, useSelector } from 'react-redux'
import {
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from 'firebase/auth'

import { login, logout } from './authSlice'

export const auth = getAuth()

export const onAuthStateChangedListener = (dispatch) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      dispatch(
        login({
          email: user.email,
          uid: user.uid,
        })
      )
    } else {
      dispatch(logout())
    }
  })
}

export const logoutHandler = (dispatch) => {
  signOut(auth)
    .then(() => {
      dispatch(logout())
    })
    .catch((error) => {
      console.log(error)
    })
}
