import { useState } from 'react'

function useCarousel(carouselItems) {
  const [currentIndex, setCurrentIndex] = useState(0)

  const scrollNext = () => {
    setCurrentIndex((prevState) => prevState + 1)

    if (currentIndex === carouselItems.length - 1) setCurrentIndex(0)
  }

  const scrollPrev = () => {
    setCurrentIndex((prevState) => prevState - 1)

    if (currentIndex === 0) setCurrentIndex(carouselItems.length - 1)
  }

  return {
    currentIndex,
    scrollNext,
    scrollPrev,
  }
}

export default useCarousel
