import styled from 'styled-components'

export const Input = styled.input`
  width: 100%;
  padding: 8px 12px;
  border-radius: 50px;
  border: none;
  background-color: ${({ readonly }) => (readonly ? 'transparent' : '#eee')};
  pointer-events: ${({ readonly }) => readonly && 'none'};

  &:focus {
    outline: none;
    //outline: ${({ readonly }) => readonly && 'none'};
    outline: 2px solid rgb(104, 206, 248);
  }
`
