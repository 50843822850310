import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { SvgIcon } from '@mui/material'

import { theme } from 'theme'
import { BrittanySignature } from 'features/ui/typography.component'
import blogImage from 'assets/kim/kim-blog.jpg'
import QuoteLeft from 'assets/icons/QuoteLeft.png'
import { Box } from 'features/ui/box.component'
import { MuiButton } from 'features/ui/button.component.mui'

const DesktopLaptop = () => {
  let navigate = useNavigate()

  const routeChange = () => {
    navigate(`/posts`)
  }
  return (
    <Container>
      <Overlay />
      <Image>
        <img className='image' src={blogImage} alt='Blog' />
      </Image>
      <Latest>
        <Block>
          <FontWrapper>
            <BrittanySignatureCustom>Blog</BrittanySignatureCustom>
          </FontWrapper>
          <span className='quoteLeft'>
            <img src={QuoteLeft} style={{ transform: 'scaleX(-1)' }} />
          </span>
          <span>
            <span>De interieurbranche ontwikkelt zich snel;</span> er komen
            steeds nieuwere trends en slimmere mogelijkheden, die evolutie staat
            niet stil. Ik ben dan ook elke dag bezig met het opvolgen van deze
            ontwikkelingen. Via deze weg wil ik jou meenemen in mijn passie voor
            interieur en wil ik mijn kennis met je delen.
          </span>
          <span className='quoteLeft'>
            <img src={QuoteLeft} />
          </span>
        </Block>
        <Box placement='bottom-center' style={{ marginBottom: '-30px' }}>
          <MuiButton variant='contained' onClick={routeChange}>
            Meer lezen
          </MuiButton>
        </Box>
      </Latest>
    </Container>
  )
}

export default DesktopLaptop

const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  font-family: 'VL Arch Light Cond Light';
  font-size: 18px;
`

const Image = styled.div`
  position: absolute;
  .image {
    /* display: block; */
    width: 120%;
    height: 100%;
    transform: scaleX(-1);
    object-fit: cover;
    object-position: calc(50%) calc(50% - 900px);
  }
`

const Overlay = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.6);
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 2;
`

const Latest = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  z-index: 3;
  width: 790px;
  height: 530px;
  background-color: ${theme.colors.quaternary};

  .quoteLeft {
    /* text-align: left;
    font-size: 40px; */
    width: 40px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:last-child {
      float: right;
    }
  }
`

const FontWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`

const BrittanySignatureCustom = styled(BrittanySignature)`
  font-size: 40px;
  color: ${theme.colors.quinary};
`

const Block = styled.p`
  margin: 0 auto;
  font-size: 20px;
  line-height: 21px;
  text-align: center;
  color: ${theme.colors.quinary};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;

  span {
    display: block;
  }
`
