import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import * as S from './styles'

const Tab = (props) => {
  const { tabs, path, setPath, iconType, icon, name, text } = props

  const handleSwitchTab = (path) => {
    setPath(path)
  }

  const handleCloseTab = () => {
    setPath('')
    document.body.style.overflowY = 'scroll'
  }

  React.useEffect(() => {
    if (path !== '') {
      document.body.style.overflowY = 'hidden'
    }
  }, [path])

  return (
    <S.Wrapper>
      {path !== '' && <S.Overlay path={path} onClick={handleCloseTab} />}
      <S.Container>
        <S.Header>
          {tabs && (
            <S.Tabs tabs={tabs}>
              {tabs.map((tab) => (
                <S.Tab
                  onClick={() => handleSwitchTab(tab.path)}
                  active={tab.path === path}
                >
                  {tab.name}
                </S.Tab>
              ))}
            </S.Tabs>
          )}

          <S.Button type='button' onClick={handleCloseTab} outline>
            <FontAwesomeIcon icon={faXmark} />
          </S.Button>
        </S.Header>
        <S.Overflow>
          {icon && (
            <S.Card>
              <S.Icon type={iconType}>{icon}</S.Icon>
            </S.Card>
          )}
          <S.TextBlock>
            <S.Title>{name}</S.Title>
            <S.Paragraph>{text}</S.Paragraph>
          </S.TextBlock>
        </S.Overflow>
      </S.Container>
    </S.Wrapper>
  )
}

export default Tab
