import { createAsyncThunk } from '@reduxjs/toolkit'

import { beleefKimApi } from 'service/beleefKim'

const {
  writeDataHandler,
  readDataHandler,
  updateDataHandler,
  deleteDataHandler,
  downloadFileHandler,
  deleteFileHandler,
} = beleefKimApi

const DATABASE_PATH = 'pages/approach/'
const STORAGE_URL = 'pages/approach/files/'

export const fetchPage = createAsyncThunk(
  'pages/approach/fetchPage',
  async () => {
    try {
      const pages = await readDataHandler(DATABASE_PATH)
      return pages
    } catch (error) {
      console.log(error)
    }
  }
)

export const createPage = createAsyncThunk(
  'pages/approach/createPage',
  async (page, { rejectWithValue }) => {
    try {
      const { id } = await writeDataHandler(DATABASE_PATH, page)
      return { id, ...page }
    } catch (error) {
      return rejectWithValue(error.response)
    }
  }
)

export const updatePage = createAsyncThunk(
  'pages/approach/updatePage',
  async (page, { rejectWithValue }) => {
    try {
      const { id } = page

      await updateDataHandler(DATABASE_PATH + id, page)

      return page
    } catch (error) {
      return rejectWithValue(error.response)
    }
  }
)
