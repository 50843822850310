import React from 'react'

import { theme } from 'theme'
import useMediaQuery from 'hooks/use-media-query'
import DesktopLaptop from './desktop-laptop'
import TabletMobile from './tablet-mobile'

const Index = () => {
  const { breakpoints } = theme
  const { desktopOrLaptop, mobile } = breakpoints

  const isDesktopOrLaptop = useMediaQuery(desktopOrLaptop)
  const isTabletOrMobile = useMediaQuery(mobile)

  return (
    <React.Fragment>
      {isDesktopOrLaptop && <DesktopLaptop />}
      {isTabletOrMobile && <TabletMobile />}
    </React.Fragment>
  )
}

export default Index
