import React from 'react'
import styled from 'styled-components'

import {
  PROCESS_PAGE,
  QUESTION_PAGE,
  PUZZLE_PAGE,
  SOLUTION_PAGE,
  RESULT_PAGE,
} from 'pages'
import Process from 'pages/process/tablet-mobile'
import { theme } from 'theme'

const { heading: PROCESS_HEADING, text: PROCESS_TEXT } = PROCESS_PAGE
const {
  path: QUESTION_PATH,
  heading: QUESTION_HEADING,
  icon: QUESTION_ICON,
} = QUESTION_PAGE
const {
  path: PUZZLE_PATH,
  heading: PUZZLE_HEADING,
  icon: PUZZLE_ICON,
} = PUZZLE_PAGE
const {
  path: SOLUTION_PATH,
  heading: SOLUTION_HEADING,
  icon: SOLUTION_ICON,
} = SOLUTION_PAGE
const {
  path: RESULT_PATH,
  heading: RESULT_HEADING,
  icon: RESULT_ICON,
} = RESULT_PAGE

const Mobile = () => {
  const [path, setPath] = React.useState('')

  const tabs = [
    { index: 0, path: QUESTION_PATH, name: QUESTION_HEADING },
    { index: 1, path: PUZZLE_PATH, name: PUZZLE_HEADING },
    { index: 2, path: SOLUTION_PATH, name: SOLUTION_HEADING },
    { index: 3, path: RESULT_PATH, name: RESULT_HEADING },
  ]

  return (
    <React.Fragment>
      {path !== '' && <Overlay onClick={() => setPath('')} />}
      {path !== '' && <Process path={path} setPath={setPath} tabs={tabs} />}
      <Container>
        <Title>{PROCESS_HEADING}</Title>
        <Block>{PROCESS_TEXT}</Block>
        <Flexbox>
          <Card>
            <span className='icon'>{QUESTION_ICON}</span>
            <CardFooter>
              <CardTitle>{QUESTION_HEADING}</CardTitle>
              <Button onClick={() => setPath(QUESTION_PATH)}>
                Nieuwsgierig
              </Button>
            </CardFooter>
          </Card>
          <Card>
            <span className='icon'>{PUZZLE_ICON}</span>
            <CardFooter>
              <CardTitle>{PUZZLE_HEADING}</CardTitle>
              <Button onClick={() => setPath(PUZZLE_PATH)}>Nieuwsgierig</Button>
            </CardFooter>
          </Card>
          <Card>
            <span className='icon'>{SOLUTION_ICON}</span>
            <CardFooter>
              <CardTitle>{SOLUTION_HEADING}</CardTitle>
              <Button onClick={() => setPath(SOLUTION_PATH)}>
                Nieuwsgierig
              </Button>
            </CardFooter>
          </Card>
          <Card>
            <span className='icon'>{RESULT_ICON}</span>
            <CardFooter>
              <CardTitle>{RESULT_HEADING}</CardTitle>
              <Button onClick={() => setPath(RESULT_PATH)}>Nieuwsgierig</Button>
            </CardFooter>
          </Card>
        </Flexbox>
      </Container>
    </React.Fragment>
  )
}

export default Mobile

const Container = styled.section`
  display: flex;
  flex-direction: column;
  font-family: 'VL Arch Light Cond Light';
  font-size: 20px;
  padding: 20px;
  background-color: white;
  color: ${theme.colors.quinary};
`

const Title = styled.h1`
  margin: 0 0 20px 0;
  font-family: 'Brittany Signature';
  font-size: 32px;
`

const Block = styled.div`
  display: flex;
  width: 100%;
  line-height: 1.5rem;
`

const Flexbox = styled.div`
  overflow: scroll;
  display: flex;
  gap: 20px;
  margin: 20px -20px 0 0;

  &::-webkit-scrollbar {
    display: none;
  }
`

const Card = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  width: 300px;
  height: 340px;
  background-color: transparent;
  border: 1px solid ${theme.colors.primary};
  overflow: hidden;

  .icon {
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    width: 90px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:last-child {
    margin-right: 20px;
  }
`

const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  padding: 20px;
  width: 100%;
  background-color: ${theme.colors.quaternary};
  color: black;
`

const CardTitle = styled.div`
  font-size: 1.1rem;
  text-transform: uppercase;
  color: ${theme.colors.quinary};
`

const Button = styled.button`
  margin: 0 0 0 auto;
  padding: 0;
  width: 120px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: ${theme.colors.tertiary};
  color: white;
  font-size: 16px;
  cursor: pointer;
`

const Overlay = styled.div`
  z-index: 500;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #444;
  opacity: 0.5;
  cursor: zoom-out;
`
