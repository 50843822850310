import styled, { css } from 'styled-components'

import { theme } from 'theme'

const SPACING = 50
const SPACING_OPTIONS = [`${SPACING}px`, `${SPACING * 2}px`]

const FONTSIZE_OPTIONS = ['20px', '40px']

const BUTTON_HEIGHT = '60px'

const BORDER_THICKNESS = '5px'

const primaryFont = css`
  margin: 0;
  padding: 0;
  font-family: 'VL Arch Light Cond Light';
  font-size: ${FONTSIZE_OPTIONS[0]};
  color: ${theme.colors.quinary};
`

export const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-family: 'Brittany Signature';
  font-size: ${FONTSIZE_OPTIONS[1]};
  color: ${theme.colors.quinary};

  position: absolute;
  top: 0;
  left: 0;
  margin: calc(${SPACING_OPTIONS[1]} - ${FONTSIZE_OPTIONS[1]}) 0 0
    calc(${SPACING_OPTIONS[1]} + ${SPACING_OPTIONS[0]});
`

export const TextBlock = styled.span`
  display: block;
`

export const Button = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0 ${SPACING_OPTIONS[1]}
    calc(
      ${SPACING_OPTIONS[1]} - (${BUTTON_HEIGHT} / 2) + (${BORDER_THICKNESS} / 2)
    )
    0;
`

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
  ${primaryFont}

  span {
    display: block;
    margin-bottom: 10px;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
`

export const Column = styled.div`
  width: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const Wrapper = styled.div`
  width: calc(100% - ${SPACING_OPTIONS[1]});
  height: calc(100% - ${SPACING_OPTIONS[1]} * 2);
  margin: ${SPACING_OPTIONS[1]} 0 ${SPACING_OPTIONS[0]} ${SPACING_OPTIONS[1]};
  padding: ${SPACING_OPTIONS[0]} 0 ${SPACING_OPTIONS[0]} ${SPACING_OPTIONS[0]};
  padding-right: ${SPACING_OPTIONS[1]};
  border: ${BORDER_THICKNESS} solid ${theme.colors.quaternary};
  border-right: none;
`
