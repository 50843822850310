import styled, { css } from 'styled-components'

import { theme } from 'theme'

export const DropdownMenu = styled.div``

export const DropdownMenuTrigger = styled.button`
  border: 0;
  display: flex;
  background: #fff;
  color: #777;
  border-radius: 8px;
  margin: 0;
  padding: 8px;
  font-size: 14px;
  cursor: pointer;
  vertical-align: middle;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;

  &:hover input {
    background-color: #eee;
  }

  input {
    margin: -8px;
    border: none;
    //height: 100%;
  }

  &:hover {
    background-color: #eee;
  }
`

export const InputWrapper = styled.div`
  display: flex;
`

export const Input = styled.input`
  width: 60px;
  border: 0;
  display: flex;
  background: #fff;
  color: #777;
  border-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin: 0;
  padding: 8px;
  font-size: 14px;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;

  &:focus {
    outline: 0;
  }
`

export const InputTrigger = styled.button`
  //width: 16px;
  border: 0;
  display: flex;
  background: #fff;
  color: #777;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin: 0;
  padding: 0 8px;
  font-size: 14px;
  cursor: pointer;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
`

export const InputMore = styled.img`
  width: 16px;
  height: 16px;
`

export const DropdownMore = styled.img`
  width: 16px;
  height: 16px;
  margin-left: ${({ nofollow }) => (!nofollow ? '8px' : '0px')};
`

export const DropdownIcon = styled.img`
  ${({ buttonLabel }) =>
    buttonLabel !== '' &&
    css`
      margin-right: 8px;
    `}
`

export const DropdownMenuContent = styled.ul`
  position: absolute;
  top: 45px;
  margin: 0;
  padding: 8px 0;
  z-index: 10;
  display: block;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  width: 214px;
  min-height: 40px;
  background-color: #fff;
`

export const DropdownMenuItem = styled.li`
  list-style-type: none;
  margin: 0 8px;
  padding: 8px;
  display: flex;
  align-items: center;
  background-color: ${({ active }) =>
    active ? 'rgba(104, 206, 248, 0.3)' : '#fff'};
  color: #777;
  line-height: 16px;
  font-size: 15px;
  border-radius: 8px;
  border: 0;
  cursor: pointer;

  &:hover {
    background-color: #eee;
    color: ${({ active }) => active && 'rgb(119, 119, 119)'};
  }
`

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
`

export const DropdownMenuIcon = styled.img`
  margin: 0 8px 0 0;
`
