import * as React from 'react'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  $createParagraphNode,
  $insertNodes,
  $isRootOrShadowRoot,
  LexicalCommand,
  createCommand,
} from 'lexical'
import { $wrapNodeInElement } from '@lexical/utils'
import { $createVideoNode } from '../nodes/videoNode'

// Create a custom command with a typed payload.
type CommandPayload = string
export const INSERT_VIDEO_COMMAND: LexicalCommand<CommandPayload> =
  createCommand('INSERT_VIDEO_COMMAND')

export const VideoPlugin = (props) => {
  const { getVideo } = props
  const [editor] = useLexicalComposerContext()

  React.useEffect(() => {
    // Similar with command listener, which returns unlisten callback
    const removeListener = editor.registerCommand(
      INSERT_VIDEO_COMMAND,
      (payload) => {
        // Adding custom command that will be handled by this plugin
        editor.update(() => {
          const videoNode = $createVideoNode(payload)
          $insertNodes([videoNode])
          if ($isRootOrShadowRoot(videoNode.getParentOrThrow())) {
            $wrapNodeInElement(videoNode, $createParagraphNode).selectEnd()
          }
        })

        // Returning true indicates that command is handled and no further propagation is required
        return true
      },
      0
    )

    return () => {
      removeListener()
    }
  }, [editor])

  return null
}
