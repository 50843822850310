import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileSignature,
  faFile,
  faCircleInfo,
  faFileArrowUp,
  faArrowRight,
  faArrowLeft,
  faXmark,
  faCheck,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'

import { getObjectUrl } from 'utils/getObjectUrl'
import useClickOutside from 'hooks/use-click-outside'
import * as F from './Form.styled'
import { StepperList, StepperTrigger } from 'components/ui/form/FormStepper'
import { Separator } from 'components/ui/separator/Separator'
import Input from 'features/ui/input.component'
import Preview from 'features/ui/preview.component'

const Files = (props) => {
  const dispatch = useDispatch()

  const {
    id,
    isVisible,
    loading,
    form,
    item,
    routeGroup,
    onNextStep,
    onPrevStep,
    onSubmit,
    updateFiles,
    updateStep,
  } = props

  const navigate = useNavigate()

  const hiddenFilesInput = React.useRef(null)
  const hiddenThumbnailInput = React.useRef(null)

  const [thumbnail, setThumbnail] = React.useState([])
  const [files, setFiles] = React.useState([])

  let domNode = useClickOutside(() => {
    if (id) {
      navigate(`${routeGroup}/${id}`)
    } else {
      navigate(routeGroup)
    }
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    thumbnail: form?.thumbnail,
    files: form?.files,
  })

  const { ref: thumbnailRef, ...restThumbnail } = register('thumbnail', {
    onChange: (e) => {
      const files = []

      files.push(getObjectUrl(e.target.files[0]))

      setThumbnail(files)
    },
    validate: {
      required: (value) => value.length > 0 || 'Thumbnail is required',
    },
  })

  const { ref: filesRef, ...restFiles } = register('files', {
    onChange: async (e) => {
      const newFiles = []

      for (let file of e.target.files) newFiles.push(getObjectUrl(file))

      if (files.length > 0) setFiles((prevState) => [...newFiles, ...prevState])
      else setFiles(newFiles)
    },
  })

  React.useEffect(() => {
    if (form?.thumbnail) setThumbnail(form?.thumbnail)
    if (form?.files) setFiles(form?.files)

    if (id) {
      if (item?.thumbnail && form.thumbnail.length < 1)
        setThumbnail(item?.thumbnail)
      if (item.files && form.files.length < 1) setFiles(item?.files)
    }
  }, [id, item, form])

  const handleOnDelete = (index) => {
    const newFiles = files.filter((_, i) => i !== index)
    setFiles(newFiles)
  }

  const handleOnClick = (e, ref) => {
    e.preventDefault()
    ref.current.click()
  }

  const handleOnSubmit = (e) => {
    e.preventDefault()

    if (thumbnail.length > 0) {
      if (onSubmit) handleSubmit(onSubmit(e, { thumbnail, files }))
      else handleSubmit(onStepChange({ thumbnail, files }, 'details'))
    }
  }

  const handleLoadingSpinner = (isLoading, onSubmit) => {
    if (isLoading)
      return (
        <F.Button type='button' disabled={isLoading}>
          <FontAwesomeIcon icon={faSpinner} spin />
        </F.Button>
      )
    if (onSubmit)
      return (
        <F.Button type='submit'>
          <FontAwesomeIcon icon={faCheck} />
        </F.Button>
      )
    else
      return (
        <F.Button type='submit' solid>
          <FontAwesomeIcon icon={faArrowRight} />
        </F.Button>
      )
  }

  const onStepChange = (data, step) => {
    dispatch(updateFiles(data))
    dispatch(updateStep(step))
  }

  console.log(thumbnail)

  return (
    <React.Fragment>
      <F.Container ref={domNode}>
        <StepperList>
          {isVisible?.title && (
            <StepperTrigger
              onClick={() => onStepChange({ thumbnail, files }, 'title')}
            >
              <span>
                <FontAwesomeIcon icon={faFileSignature} />
              </span>
              Title
            </StepperTrigger>
          )}
          {isVisible?.files && (
            <React.Fragment>
              <Separator />
              <StepperTrigger
                onClick={() => onStepChange({ thumbnail, files }, 'files')}
                active
              >
                <span>
                  <FontAwesomeIcon icon={faFile} />
                </span>
                Files
              </StepperTrigger>
            </React.Fragment>
          )}
          {isVisible?.details && (
            <React.Fragment>
              <Separator />
              <StepperTrigger
                onClick={() => onStepChange({ thumbnail, files }, 'details')}
              >
                <span>
                  <FontAwesomeIcon icon={faCircleInfo} />
                </span>
                Details
              </StepperTrigger>
            </React.Fragment>
          )}
        </StepperList>
        <F.Form onSubmit={(e) => handleSubmit(handleOnSubmit(e))}>
          <F.Body>
            <F.Field>
              <Input
                hidden
                type='file'
                name='thumbnail'
                label='Thumbnail'
                ref={(e) => {
                  thumbnailRef(e)
                  hiddenThumbnailInput.current = e
                }}
                {...restThumbnail}
                accept='image/*, application/pdf'
              />
              <F.Button
                type='button'
                style={{ marginBottom: '10px' }}
                onClick={(e) => handleOnClick(e, hiddenThumbnailInput)}
              >
                <span>
                  <FontAwesomeIcon icon={faFileArrowUp} />
                </span>
                Upload
              </F.Button>
              {errors.thumbnail && (
                <F.ErrorMessage style={{ marginLeft: '10px' }}>
                  Select a file
                </F.ErrorMessage>
              )}
              {thumbnail && <Preview files={thumbnail} />}
            </F.Field>
            <F.Hr />
            <F.Field>
              <Input
                hidden
                multiple
                type='file'
                name='files'
                label='Files'
                ref={(e) => {
                  filesRef(e)
                  hiddenFilesInput.current = e
                }}
                {...restFiles}
              />
              <F.Button
                type='button'
                style={{ marginBottom: '10px' }}
                onClick={(e) => handleOnClick(e, hiddenFilesInput)}
              >
                <span>
                  <FontAwesomeIcon icon={faFileArrowUp} />
                </span>
                Upload
              </F.Button>
              {errors.files && (
                <F.ErrorMessage style={{ marginLeft: '10px' }}>
                  Select one file or mulitple files
                </F.ErrorMessage>
              )}
              {files && files.length > 0 && (
                <F.Row>
                  <Preview
                    style={{ marginBottom: '10px' }}
                    multiple
                    files={files}
                    handleDelete={handleOnDelete}
                  />
                </F.Row>
              )}
            </F.Field>
          </F.Body>
          <F.ActionGroup>
            <F.Button
              type='button'
              outline
              onClick={() => onStepChange({ thumbnail, files }, 'title')}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </F.Button>
            {handleLoadingSpinner(loading, onSubmit)}
          </F.ActionGroup>
        </F.Form>
      </F.Container>
      <F.Close>
        <F.Button type='button' outline>
          <FontAwesomeIcon icon={faXmark} />
        </F.Button>
      </F.Close>
    </React.Fragment>
  )
}

export default Files
