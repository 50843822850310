import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInstagramSquare,
  faFacebookSquare,
} from '@fortawesome/free-brands-svg-icons'

import * as S from './styles-tablet-mobile'
import { sendEmail } from 'service/emailService'
import contactImage from 'assets/kim/kim-contact.jpg'

const DesktopLaptop = () => {
  const form = React.useRef()

  const onSendEmail = (e) => {
    e.preventDefault()
    sendEmail(form)
  }

  return (
    <S.Container>
      <S.Card>
        <img src={contactImage} />
      </S.Card>
      <S.Form ref={form} onSubmit={onSendEmail}>
        <S.Title>Contact</S.Title>
        <S.Input type='text' name='naam' placeholder='Naam' />
        <S.Input type='email' name='eMail' placeholder='E-mail adres' />
        <S.Input
          type='text'
          name='telefoonnummer'
          placeholder='Telefoonnummer'
        />
        <S.Input type='text' name='onderwerp' placeholder='Onderwerp' />
        <S.Textarea name='bericht' placeholder='Bericht' />
        <S.Block>
          <S.Brands>
            <span className='icon'>
              <a href='https://www.instagram.com/beleefkim/'>
                <FontAwesomeIcon icon={faInstagramSquare} />
              </a>
            </span>
            <span className='icon'>
              <a href='https://www.facebook.com/kim.jansen.142'>
                <FontAwesomeIcon icon={faFacebookSquare} />
              </a>
            </span>
          </S.Brands>
          <S.Button type='submit'>Neem contact op</S.Button>
        </S.Block>
      </S.Form>
    </S.Container>
  )
}

export default DesktopLaptop
