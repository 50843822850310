import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import * as React from 'react'
import styled from 'styled-components'

import uuid from 'react-uuid'
import { StorageService } from 'service/storageService'
import { Option } from '../../select.component'
import { INSERT_IMAGE_COMMAND } from './imagePlugin'
import { INSERT_VIDEO_COMMAND } from './videosPlugin'
import QuoteLeft from 'assets/icons/QuoteLeft.svg'

export default function ToolbarPlugin(props) {
  const { handleIsOpen, setShowDropdown } = props
  const { writeVideoData } = StorageService

  const [editor] = useLexicalComposerContext()
  const onClick = (payload) =>
    editor.dispatchCommand(INSERT_IMAGE_COMMAND, payload)

  const [src, setSrc] = React.useState(null)
  const [videoSrc, setVideoSrc] = React.useState(null)
  const [altText, setAltText] = React.useState('')
  const hiddenImageInput = React.useRef(null)
  const hiddenVideoInput = React.useRef(null)

  React.useEffect(() => {
    if (src) setShowDropdown(false)
  }, [src])

  const insertVideo = (payload) => {
    // Executing command defined in a plugin
    editor.dispatchCommand(INSERT_VIDEO_COMMAND, payload)
  }

  const insertQuoteOnClick = (payload) => {
    editor.dispatchCommand(INSERT_IMAGE_COMMAND, payload)
  }

  const loadImage = (event) => {
    const image = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(image)

    reader.onload = function () {
      if (image instanceof Blob) {
        setSrc(reader.result)
      }
      return ''
    }
  }

  const loadVideo = async (event) => {
    const video = event.target.files[0]
    var reader = new FileReader()
    var url = URL.createObjectURL(video)

    const fileNameParts = video.name.split('.')
    const fileExtension = fileNameParts.pop()
    const newName = uuid() // New name without the extension
    const renamedName = `${newName}.${fileExtension}`
    const renamed = new File([video], renamedName, {
      type: video.type,
    })

    const { downloadURL } = await writeVideoData(renamed)
    setVideoSrc(downloadURL)
  }

  React.useEffect(() => {
    if (src) onClick({ altText, src })
  }, [src])

  React.useEffect(() => {
    if (videoSrc) {
      insertVideo(videoSrc)
    }
  }, [videoSrc])

  const handleImageClick = (event) => {
    event.preventDefault()
    hiddenImageInput.current.click()
    // handleIsOpen(event)
  }

  const handleVideoClick = (event) => {
    event.preventDefault()
    hiddenVideoInput.current.click()
    // handleIsOpen(event)
  }

  return (
    <div className='toolbar'>
      {/* <Option
        hasChildren
        onClick={
          () =>
            onClick({
              altText: 'Quote',
              src: QuoteLeft,
              className: 'editor-quote',
            })
          // insertQuoteOnClick({
          //   altText: 'Quote',
          //   src: QuoteLeft,
          //   className: 'editor-quote',
          // })
        }
      >
        Sample
      </Option> */}
      <input
        style={{ display: 'none' }}
        ref={hiddenImageInput}
        type='file'
        name='thumbnail'
        onChange={loadImage}
      />
      <Option hasChildren onClick={handleImageClick}>
        Image
      </Option>
      {/* <input
        style={{ display: 'none' }}
        ref={hiddenVideoInput}
        type='file'
        name='thumbnail'
        onChange={loadVideo}
      />
      <Option hasChildren onClick={handleVideoClick}>
        Video
      </Option> */}
    </div>
  )
}

const Modal = styled.div`
  z-index: 1000;
  position: absolute;
  top: 48px;
  margin: 0;
  padding: 8px;
  list-style-type: none;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  width: 214px;
  min-height: 40px;
  background-color: #fff;
`
