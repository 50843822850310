import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { createPage, updatePage, fetchPage } from 'features/about/createAction'
import { createItem, selectedItem } from 'features/about/aboutSlice'
import AdminForm from 'blocks/admin-form'
import Tiptap from 'components/utils/editor'

const AboutForm = () => {
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const { selectedKey, data, tabs, isLoading } = useSelector(
    (store) => store.about
  )

  const _tabs = [
    {
      id: 'advies',
      title: 'advies',
      content: (control) => (
        <Tiptap
          control={control}
          name='advies'
          getValue={(e) => dispatch(createItem(e))}
          defaultValue={tabs.advies}
          editable={true}
        />
      ),
    },
    {
      id: 'styling',
      title: 'styling',
      content: (control) => (
        <Tiptap
          control={control}
          name='styling'
          getValue={(e) => dispatch(createItem(e))}
          defaultValue={tabs.styling}
          editable={true}
        />
      ),
    },
    {
      id: '2DOntwerp',
      title: '2D Ontwerp',
      content: (control) => (
        <Tiptap
          control={control}
          name='2DOntwerp'
          getValue={(e) => dispatch(createItem(e))}
          defaultValue={tabs['2DOntwerp']}
          editable={true}
        />
      ),
    },
    {
      id: '3DOntwerp',
      title: '3D Ontwerp',
      content: (control) => (
        <Tiptap
          control={control}
          name='3DOntwerp'
          getValue={(e) => dispatch(createItem(e))}
          defaultValue={tabs['3DOntwerp']}
          editable={true}
        />
      ),
    },
  ]

  React.useEffect(() => {
    dispatch(fetchPage())
  }, [dispatch])

  const onSubmit = (e, _data) => {
    e.preventDefault()
    if (data[0]?.id) dispatch(updatePage({ id: data[0]?.id, ..._data }))
    else dispatch(createPage(data))

    navigate('/about')
  }

  const handleTabChange = (key) => {
    if (key) dispatch(selectedItem({ selectedKey: key }))
  }

  if (isLoading) return <p>...Loading</p>

  return (
    <AdminForm
      selectedTab={selectedKey}
      onSubmit={onSubmit}
      selectedKey={tabs}
      onSelectionChange={handleTabChange}
      titleObj={{
        label: 'Title',
        description: 'Enter a title.',
        defaultValue: data[0]?.title,
      }}
      shortDescriptionObj={{
        label: 'Preview',
        description: 'Write a short description...',
        defaultValue: data[0]?.shortDescription?.advies,
      }}
      descriptionObj={{
        // tabs: _tabs,
        label: 'Description',
        description: 'Write a complete description...',
        defaultValue: data[0]?.description,
      }}
    />
  )
}

export default AboutForm
