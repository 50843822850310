import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'

import tabsReducer from 'components/tabs/tabsSlice'

import userReducer from 'features/auth/authSlice'
import aboutReducer from 'features/about/aboutSlice'
import approachReducer from 'features/approach/approachSlice'
import projectReducer from 'features/projects/projectSlice'
import postReducer from 'features/posts/postSlice'

const reducers = combineReducers({
  auth: userReducer,
  about: aboutReducer,
  approach: approachReducer,
  projects: projectReducer,
  posts: postReducer,
  tabs: tabsReducer,
})

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export const persistor = persistStore(store)
