import styled from 'styled-components'

import { theme } from 'theme'

export const Stepper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  align-items: center;
  height: 48px;
  padding: 8px 0;
  font-size: 14px;
  background-color: white;
`

export const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  border: 0;
  background: none;
  border-radius: 8px;
  margin: 0 8px;
  padding: 0 8px;
  cursor: pointer;
  background-color: ${({ active }) => active && '#eee'};
  color: #777;

  span {
    margin-right: 8px;
  }

  &:hover {
    background-color: #eee;
  }
`
