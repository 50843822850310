import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { serverTimestamp } from 'firebase/database'

import { beleefKimApi } from '../../service/beleefKim'
import { createPost, updatePost } from 'features/posts/createAction'
import { blobFromUrl } from '../utils/blobFromUrl'
import {
  updateTitle,
  updateFiles,
  updateEditor,
  updateStep,
} from 'features/posts/postSlice'
import Title from 'components/form/Title'
import Files from 'components/form/Files'
import Details from 'components/form/Details'

const { uploadFileHandler, downloadFileHandler } = beleefKimApi
const ROUTE_GROUP = '/posts'

const PostForm = () => {
  const dispatch = useDispatch()

  const { postForm, postItem, isLoading } = useSelector((store) => store.posts)

  const { step } = postForm

  const navigate = useNavigate()
  let { id } = useParams()

  const [loading, setLoading] = React.useState(false)
  // const [step, setStep] = React.useState(1)

  // const onNextStep = (body, e) => {
  //   e.preventDefault()

  //   dispatch(addToForm(body))

  //   setStep(step + 1)
  // }

  // const onPrevStep = (e, body) => {
  //   e.preventDefault()

  //   dispatch(addToForm(body))

  //   setStep(step - 1)
  // }

  // const onStepChange = (step, data) => {
  //   dispatch(updateStep({ step, data }))
  // }

  const handleFiles = async (file) => {
    const newFiles = []
    if (file.length > 0) {
      for (const key in file) {
        const blob = await blobFromUrl(file[key].downloadURL, file[key].type)
        const { name } = blob

        await uploadFileHandler(`posts/files/${name}`, blob)
        const { downloadURL } = await downloadFileHandler(`posts/files/${name}`)

        newFiles.push({
          downloadURL,
          type: file[key].type,
          name,
        })
      }
    }

    return newFiles
  }

  const onSubmitPost = async (e, data) => {
    e.preventDefault()

    setLoading(true)

    const { editor } = data

    const { title, thumbnail, files } = postForm.data

    const newThumbnail = await handleFiles(thumbnail)
    const newFiles = await handleFiles(files)

    if (id) {
      const newData = {
        id: postItem.id,
        title,
        files: newFiles,
        thumbnail: newThumbnail,
        content: editor || [],
        createdAt: postItem.createdAt,
        updatedAt: serverTimestamp(),
      }

      dispatch(updatePost({ newPost: newData, post: postItem }))

      navigate(`${ROUTE_GROUP}/${id}`)
    } else {
      const newData = {
        title,
        thumbnail: newThumbnail,
        files: newFiles,
        content: editor || [],
        createdAt: serverTimestamp(),
      }

      dispatch(createPost(newData))

      navigate(ROUTE_GROUP)
    }

    setLoading(false)
  }

  switch (step) {
    case 'title':
      return (
        <Title
          id={id}
          isVisible={{ title: true, files: true, details: true }}
          form={postForm.data}
          item={postItem}
          routeGroup={ROUTE_GROUP}
          updateTitle={updateTitle}
          updateStep={updateStep}
          // onNextStep={onNextStep}
        />
      )
    case 'files':
      return (
        <Files
          id={id}
          isVisible={{ title: true, files: true, details: true }}
          hasFiles={true}
          form={postForm.data}
          item={postItem}
          routeGroup={ROUTE_GROUP}
          updateFiles={updateFiles}
          updateStep={updateStep}
          // onNextStep={onNextStep}
          // onPrevStep={onPrevStep}
        />
      )
    case 'details':
      return (
        <Details
          id={id}
          isVisible={{ title: true, files: true, details: true }}
          loading={loading}
          form={postForm.data}
          item={postItem}
          routeGroup={ROUTE_GROUP}
          updateEditor={updateEditor}
          updateStep={updateStep}
          onSubmit={onSubmitPost}
        />
      )
    default:
  }
}

export default PostForm
