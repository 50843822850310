import styled, { css } from 'styled-components'

export const DialogContent = styled.div`
  display: flex;
  align-items: center;
  ${({ center }) =>
    center
      ? css`
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `
      : css`
          position: absolute;
          top: 40px;
        `}
  margin: 0;
  padding: 8px;
  min-width: 375px;
  z-index: 10;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  background-color: #fff;
`
