import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Tiptap from 'features/ui/editor/editor.component'

import { ABOUT_PAGE } from 'pages'
import * as S from './styles'
// import { MuiButton as Button } from 'features/ui/button.component.mui'
import Button from 'components/buttons/button/button.component'
import { MuiButton } from 'features/ui/button.component.mui'

const {
  path: ABOUT_PATH,
  heading: ABOUT_HEADING,
  icon: ABOUT_ICON,
  text: ABOUT_TEXT,
} = ABOUT_PAGE

const { preview: ABOUT_PREVIEW } = ABOUT_TEXT

const DesktopLaptop = () => {
  let navigate = useNavigate()

  const { data } = useSelector((store) => store.about)

  const routeChange = () => {
    navigate(ABOUT_PATH)
  }

  return (
    <S.Row>
      <S.Column style={{ position: 'relative' }}>
        <S.Wrapper>
          <S.Title>{ABOUT_HEADING}</S.Title>
          <S.Paragraph>{ABOUT_PREVIEW}</S.Paragraph>
        </S.Wrapper>
        <S.Button>
          <MuiButton variant='contained' onClick={routeChange}>
            Meer weten
          </MuiButton>
          {/* <Button variant='solid' href={ABOUT_PATH}>
            Meer weten
          </Button> */}
          {/* <Button variant='contained' onClick={routeChange}>
            Meer weten
          </Button> */}
        </S.Button>
      </S.Column>
      <S.Column>{ABOUT_ICON}</S.Column>
    </S.Row>
  )
}

export default DesktopLaptop
