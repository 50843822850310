import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileSignature,
  faFile,
  faCircleInfo,
  faArrowRight,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'

import * as F from './Form.styled'
import { StepperList, StepperTrigger } from 'components/ui/form/FormStepper'
import { Separator } from 'components/ui/separator/Separator'
import useClickOutside from 'hooks/use-click-outside'
import Input from 'features/ui/input.component'
import TextField from 'components/textField/textField'

const Title = (props) => {
  const dispatch = useDispatch()

  const { id, isVisible, form, item, routeGroup, updateTitle, updateStep } =
    props

  const navigate = useNavigate()

  let domNode = useClickOutside(() => {
    if (id) {
      navigate(`${routeGroup}/${id}`)
    } else {
      navigate(routeGroup)
    }
  })

  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
    getValues,
    getFieldState,
    control,
  } = useForm({
    defaultValues: {
      title: form?.title,
    },
  })

  React.useEffect(() => {
    if (id) {
      // or !form?.title
      if (form.title === '') reset({ title: item?.title })
    }
  }, [id, reset, form, item])

  const onStepChange = (data, step) => {
    dispatch(updateTitle(data))
    dispatch(updateStep(step))
  }

  return (
    <React.Fragment>
      <F.Container ref={domNode}>
        <StepperList>
          {isVisible?.title && (
            <StepperTrigger
              onClick={() => onStepChange(getValues('title'), 'title')}
              active
            >
              <span>
                <FontAwesomeIcon icon={faFileSignature} />
              </span>
              Title
            </StepperTrigger>
          )}
          {isVisible?.files && (
            <React.Fragment>
              <Separator />
              <StepperTrigger
                onClick={() => onStepChange(getValues('title'), 'files')}
              >
                <span>
                  <FontAwesomeIcon icon={faFile} />
                </span>
                Files
              </StepperTrigger>
            </React.Fragment>
          )}
          {isVisible?.details && (
            <React.Fragment>
              <Separator />
              <StepperTrigger
                onClick={() => onStepChange(getValues('title'), 'details')}
              >
                <span>
                  <FontAwesomeIcon icon={faCircleInfo} />
                </span>
                Details
              </StepperTrigger>
            </React.Fragment>
          )}
        </StepperList>
        <F.Form
          onSubmit={handleSubmit((data) => onStepChange(data.title, 'files'))}
        >
          <F.Body>
            <Controller
              control={control}
              rules={{ required: true }}
              name='title'
              {...register('title')}
              render={({
                field: { onChange, value, field, ref },
                fieldState: { invalid, error },
              }) => {
                return (
                  <TextField
                    {...field}
                    onChange={onChange}
                    value={value}
                    isRequired
                    validationbehavior='aria'
                    isInvalid={invalid}
                    ref={ref}
                    error={error?.title}
                    label='Title'
                    errorMessage='Title is required.'
                  />
                )
              }}
            />
            {/* <Input
                schema={{ required: true }}
                placeholder='Title'
                type='text'
                name='title'
                value={title}
                label='Title'
                {...register('title', {
                  onChange: (e) => {
                    setTitle(e.target.value)
                  },
                })}
                validationSchema={{
                  required: true,
                }}
              />
              {errors.title && (
                <F.ErrorMessage>Title is required</F.ErrorMessage>
              )} */}
          </F.Body>
          <F.ActionGroup>
            <F.Button solid type='submit'>
              <FontAwesomeIcon icon={faArrowRight} />
            </F.Button>
          </F.ActionGroup>
        </F.Form>
      </F.Container>
      <F.Close>
        <F.Button type='submit' outline>
          <FontAwesomeIcon icon={faXmark} />
        </F.Button>
      </F.Close>
    </React.Fragment>
  )
}

export default Title
