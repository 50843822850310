import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  createPage,
  updatePage,
  fetchPage,
} from 'features/approach/createAction'
import { createItem, selectedItem } from 'features/approach/approachSlice'
import { setDefaultTabListState } from 'components/tabs/tabsSlice'
import AdminForm from 'blocks/admin-form-test'
import Tiptap from 'components/utils/editor'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const ApproachForm = () => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(fetchPage())
  }, [dispatch])

  const onSubmit = (_data) => {}

  return (
    <AdminForm
      onSubmit={onSubmit}
      defaultTabListState={[
        { id: 1, title: 'Tab 1', content: 'Tab body 1' },
        { id: 2, title: 'Tab 2', content: 'Tab body 2' },
        { id: 3, title: 'Tab 3', content: 'Tab body 3' },
      ]}
    />
  )
}

export default ApproachForm
