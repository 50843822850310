import { createSlice } from '@reduxjs/toolkit'

import { fetchPosts, createPost, updatePost, deletePost } from './createAction'

const initialState = {
  posts: [],
  postItem: {},
  postForm: {
    step: 'title',
    data: {
      title: '',
      thumbnail: [],
      files: [],
      editor: [],
    },
  },
  error: null,
  isLoading: false,
}

export const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    fetchPostById: (state, action) => {
      state.postItem = state.posts.find((post) => post.id === action.payload)
    },
    updateTitle: (state, action) => {
      const { data } = state.postForm
      data.title = action.payload
    },
    updateFiles: (state, action) => {
      const { data } = state.postForm
      const { thumbnail, files } = action.payload
      data.thumbnail = thumbnail
      data.files = files
    },
    updateEditor: (state, action) => {
      const { data } = state.postForm
      const { editor } = action.payload
      data.editor = editor
    },
    updateStep: (state, action) => {
      state.postForm.step = action.payload
    },
    resetForm: (state) => {
      const { postForm } = initialState
      state.postForm = postForm
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPosts.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchPosts.fulfilled, (state, action) => {
      state.isLoading = false
      const sortedPosts = action.payload.sort(
        (a, b) => b.createdAt - a.createdAt
      )
      state.posts = sortedPosts
    })
    builder.addCase(createPost.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(createPost.fulfilled, (state, action) => {
      state.isLoading = false
      state.posts = [action.payload, ...state.posts]
    })
    builder.addCase(updatePost.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updatePost.fulfilled, (state, action) => {
      state.isLoading = false
      const { id, newPost } = action.payload
      state.posts = state.posts.map((item) => (item.id === id ? newPost : item))
    })
    builder.addCase(deletePost.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(deletePost.fulfilled, (state, action) => {
      state.isLoading = false
      state.posts = state.posts.filter((item) => item.id !== action.payload)
    })
  },
})

export const {
  fetchPostById,
  updateStep,
  updateTitle,
  updateFiles,
  updateEditor,
  resetForm,
} = postSlice.actions

export default postSlice.reducer
