import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faArrowLeft,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'

import useClickOutside from 'hooks/use-click-outside'
import { Tabs } from 'components/tabs/tabs'
import { Item } from 'react-stately'
import {
  Container,
  Form,
  Body,
  Field,
  Button,
  Close,
  ActionGroup,
} from 'globalStyle/formStyle'
import Label from 'components/label/label'
import TextField from 'components/textField/textField'
import Tiptap from 'features/ui/editor/editor.component'

const TabPanel = (props) => {
  const { index, fields, field, control } = props

  const isNotEmpty = (value) => {
    const clearHTMLTags = value.replace(/(<([^>]+)>)/gi, '')

    return clearHTMLTags.trim() !== ''
  }

  return (
    <Tabs
      selectedKey={`hello.${index}.title`}
      // onSelectionChange={state.setSelectedKey}
      aria-label='Dynamic tabs'
      items={field}
    >
      {(item) => {
        return (
          <Item title={item.title}>
            <Controller
              control={control}
              rules={{ required: true }}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { invalid, error },
              }) => {
                return (
                  <TextField
                    style={{ marginBottom: '8px' }}
                    name={`hello.${index}.title`}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    label={item.label}
                    isRequired
                    validationbehavior='aria'
                    isInvalid={invalid}
                    error={error?.title}
                    errorMessage={item.errorMessage}
                    description='Enter a subtitle.'
                  />
                )
              }}
              name='title'
            />
            <Controller
              control={control}
              rules={{
                required: true,
                validate: isNotEmpty,
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <Tiptap onChange={onChange} value={value} editable={true} />
                )
              }}
              name={`hello.${index}.content`}
            />
          </Item>
        )
      }}
    </Tabs>
  )
}

const Admin = (props) => {
  const {
    onSubmit,
    onCancel,
    titleObj,
    tabs,
    values,
    resolver,
    shortDescriptionObj,
    descriptionObj,
  } = props

  const navigate = useNavigate()

  const [selectedKey, setSelectedKey] = React.useState(tabs[0].id)
  const [lengthValues, setLengthValues] = React.useState(0)

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    control,
  } = useForm({
    defaultValues: { title: '', content: '' },
    // resolver,
  })
  const { fields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'hello', // unique name for your Field Array
  })

  const watchValues = watch(selectedKey)

  React.useEffect(() => {
    if (watchValues !== '') setLengthValues(watchValues?.length)
  }, [watchValues])

  let containerRef = useClickOutside(() => navigate('/about'))

  const isNotEmpty = (value) => {
    const clearHTMLTags = value.replace(/(<([^>]+)>)/gi, '')

    return clearHTMLTags.trim() !== ''
  }

  const clearHTMLTags = (value) => {
    // value !== '' && value?.replace(/(<([^>]+)>)/gi, '')
    return ''
  }

  console.log(fields)

  return (
    <React.Fragment>
      <Container ref={containerRef}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Body>
            <Field>
              <Controller
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { invalid, error },
                }) => {
                  return (
                    <TextField
                      onBlur={onBlur}
                      onChange={onChange}
                      defaultValue={titleObj?.defaultValue}
                      value={value}
                      label={titleObj?.label}
                      isRequired
                      validationbehavior='aria'
                      isInvalid={invalid}
                      error={error?.title}
                      errorMessage={titleObj?.errorMessage}
                      description={titleObj?.description}
                    />
                  )
                }}
                name='title'
              />
            </Field>
            <Field>
              <Label description={shortDescriptionObj?.description}>
                {shortDescriptionObj?.label}
              </Label>
              <Controller
                control={control}
                rules={{
                  required: true,
                  validate: isNotEmpty,
                }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Tiptap onChange={onChange} value={value} editable={true} />
                  )
                }}
                name='home'
              />
            </Field>
            <Field>
              <Label description={descriptionObj?.description}>
                {descriptionObj?.label}
              </Label>
              {fields.map((field, index) => {
                console.log(field)
                return (
                  <TabPanel
                    key={field.id}
                    fields={fields}
                    field={field}
                    control={control}
                    index={index}
                  />
                )
              })}
              <p>{errors[selectedKey]?.message}</p>
              <p>{clearHTMLTags(watchValues).length}</p>
              {!tabs && (
                <Controller
                  control={control}
                  rules={{
                    required: true,
                    validate: isNotEmpty,
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Tiptap
                        onChange={onChange}
                        value={value}
                        editable={true}
                      />
                    )
                  }}
                  name='describe'
                />
              )}
            </Field>
            <ActionGroup>
              <Button type='button' outline onClick={() => navigate('/about')}>
                <FontAwesomeIcon icon={faXmark} />
              </Button>
              <Button type='submit'>
                <FontAwesomeIcon icon={faCheck} />
              </Button>
            </ActionGroup>
          </Body>
        </Form>
      </Container>
      <Close>
        <Button type='button' outline>
          <FontAwesomeIcon icon={faXmark} />
        </Button>
      </Close>
    </React.Fragment>
  )
}

export default Admin
