import StarterKit from '@tiptap/starter-kit'
import FontFamily from '@tiptap/extension-font-family'
import FontSize from 'tiptap-extension-font-size'
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import TextAlign from '@tiptap/extension-text-align'
import ListItem from '@tiptap/extension-list-item'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'

import BlockStyle from './extensions/BlockStyle'

export const extensions = [
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  FontFamily.configure({
    types: ['textStyle'],
  }),
  FontSize,
  Color,
  TextStyle.configure({ types: [ListItem.name] }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Placeholder.configure({
    placeholder: 'Write something …',
  }),
  Underline,
  Link.configure({
    openOnClick: true,
    HTMLAttributes: {
      rel: null,
      target: null,
    },
  }),
  Image.configure({
    allowBase64: true,
  }),
  BlockStyle,
]
