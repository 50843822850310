import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLightbulb,
  faPuzzlePiece,
  faHouseChimneyWindow,
} from '@fortawesome/free-solid-svg-icons'

import {
  PROCESS_PAGE,
  QUESTION_PAGE,
  PUZZLE_PAGE,
  SOLUTION_PAGE,
  RESULT_PAGE,
} from 'pages'
import { theme } from 'theme'
import { BrittanySignature } from 'features/ui/typography.component'
import { Box } from 'features/ui/box.component'
import { MuiButton } from 'features/ui/button.component.mui'

const { heading: PROCESS_HEADING, text: PROCESS_TEXT } = PROCESS_PAGE
const {
  path: QUESTION_PATH,
  heading: QUESTION_HEADING,
  text: QUESTION_TEXT,
  icon: QUESTION_ICON,
} = QUESTION_PAGE
const {
  path: PUZZLE_PATH,
  heading: PUZZLE_HEADING,
  text: PUZZLE_TEXT,
  icon: PUZZLE_ICON,
} = PUZZLE_PAGE
const {
  path: SOLUTION_PATH,
  heading: SOLUTION_HEADING,
  text: SOLUTION_TEXT,
  icon: SOLUTION_ICON,
} = SOLUTION_PAGE
const {
  path: RESULT_PATH,
  heading: RESULT_HEADING,
  text: RESULT_TEXT,
  icon: RESULT_ICON,
} = RESULT_PAGE

const DesktopLaptop = () => {
  let navigate = useNavigate()

  const routeChange = () => {
    navigate(`/process`)
  }

  return (
    <Container>
      <Block>{PROCESS_TEXT}</Block>

      <Grid>
        <Box style={{ marginBottom: '-30px' }}>
          <BrittanySignatureCustom>Proces</BrittanySignatureCustom>
          <MuiButton variant='contained' onClick={routeChange}>
            Nieuwsgierig
          </MuiButton>
        </Box>
        <Box style={{ marginBottom: '-30px' }} />
        <Box style={{ marginBottom: '-30px' }} />
        <Box style={{ marginBottom: '-30px', display: 'flex' }} />
        <Box style={{ marginBottom: '-30px' }} />
        <Card className='card' />
        <Card>
          <Icon>{QUESTION_ICON}</Icon>
          <CardTitle>Opgave</CardTitle>
        </Card>
        <Card>
          <Icon>{PUZZLE_ICON}</Icon>
          <CardTitle>Puzzel</CardTitle>
        </Card>
        <Card>
          <Icon>{SOLUTION_ICON}</Icon>
          <CardTitle>Oplossing</CardTitle>
        </Card>
        <Card>
          <Icon>{RESULT_ICON}</Icon>
          <CardTitle>Besluit</CardTitle>
        </Card>
      </Grid>
    </Container>
  )
}

export default DesktopLaptop

const Container = styled.section`
  display: grid;
  min-height: 100vh;
  padding: 60px;
  font-family: 'VL Arch Light Cond Light';
  font-size: 20px;
`

const Block = styled.p`
  width: 50%;
  margin-left: auto;
  font-size: 20px;
  line-height: 21px;
  text-align: right;
  color: ${theme.colors.quinary};

  span {
    display: block;
    margin-bottom: 10px;
  }
`

const BrittanySignatureCustom = styled(BrittanySignature)`
  color: ${theme.colors.quinary};
  font-size: 40px;
  text-align: right;
  z-index: 1;

  margin: 0;
`

const Grid = styled.div`
  margin-top: auto;
  display: grid;
  grid-template-columns: 200px 1fr 1fr 1fr 1fr;
  grid-gap: 30px;

  .card {
    border: none;
  }
`

const Card = styled.div`
  position: relative;
  display: flex;
  border: 5px solid ${theme.colors.primary};
  border-radius: 50%;
  aspect-ratio: 1 / 1;

  @media only screen and (min-width: 1300px) {
    .icon {
      position: absolute;
      top: 50%;
      right: 90px;
      transform: translateY(-50%);
      color: ${theme.colors.primary};
      font-size: 90px;
    }
  }

  @media only screen and (min-width: 1224px) and (max-width: 1299px) {
    .icon {
      position: absolute;
      top: 50%;
      right: 90px;
      transform: translateY(-50%);
      color: ${theme.colors.primary};
      font-size: 60px;
    }
  }
`

const Icon = styled.span`
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    width: 35%;
  }
`

const CardTitle = styled.div`
  position: absolute;
  bottom: -15px;
  right: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.quaternary};
  color: ${theme.colors.quinary};
  border-radius: 50%;

  text-transform: uppercase;

  width: 85px;
  height: 85px;
  font-size: 16px;

  /* @media only screen and (min-width: 1224px) {
    width: 120px;
    height: 120px;
  } */

  /* @media only screen and (min-width: 1300px) {
    width: 90px;
    height: 90px;
    font-size: 16px;
  }

  @media only screen and (min-width: 1224px) and (max-width: 1299px) {
    width: 90px;
    height: 90px;
    font-size: 16px;
  } */
`
