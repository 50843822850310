import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getDatabase } from 'firebase/database'

const firebaseConfig = {
  apiKey: 'AIzaSyBpWe4As-dUPAlU7pSDbYa76Gwww-J6dyM',
  authDomain: 'beleefkim.firebaseapp.com',
  databaseURL:
    'https://beleefkim-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'beleefkim',
  storageBucket: 'beleefkim.appspot.com',
  messagingSenderId: '285677283324',
  appId: '1:285677283324:web:936d23ac16d5b4713eb479',
  measurementId: 'G-25DMH1MK02',
}

const app = initializeApp(firebaseConfig)
const db = getDatabase(app)
const auth = getAuth(app)

export { auth, db }
