import styled, { css } from 'styled-components'

import { theme } from 'theme'

const SPACING = 50
const SPACING_OPTIONS = [`${SPACING}px`, `${SPACING * 2}px`]

const FONTSIZE_OPTIONS = ['16px', '20px', '40px']

const BUTTON_HEIGHT = '60px'

const BORDER_THICKNESS = '5px'

const primaryFont = css`
  margin: 0;
  padding: 0;
  font-family: 'VL Arch Light Cond Light';
  font-size: ${FONTSIZE_OPTIONS[0]};
  color: ${theme.colors.quinary};
`

export const Wrapper = styled.div`
  height: 100vh;
  padding: 100px;
`

export const Container = styled.div`
  display: flex;
  //margin: 100px;
  border: 5px solid ${theme.colors.quaternary};
  height: 100%;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 50px;
`

export const Title = styled.h1`
  margin: 0;
  margin-bottom: 25px;
  padding: 0;
  font-family: 'Brittany Signature';
  font-size: ${FONTSIZE_OPTIONS[2]};
  color: ${theme.colors.quinary};
`

export const Input = styled.input`
  ${primaryFont}
  margin: 0;
  padding: 0 17px;
  background-color: ${theme.colors.quaternary};
  color: ${theme.colors.quinary};
  border: none;
  width: 100%;
  height: 50px;
`

export const Button = styled.button`
  ${primaryFont}
  margin: 0;
  margin-left: auto;
  padding: 0 17px;
  background-color: ${theme.colors.tertiary};
  color: white;
  border: none;
  width: 200px;
  height: 50px;
  cursor: pointer;

  /* &:hover {
    background-color: ${theme.colors.primary};
  } */
`

export const Textarea = styled.textarea`
  ${primaryFont}
  margin: 0;
  padding: 0 17px;
  padding-top: 17px;
  background-color: ${theme.colors.quaternary};
  color: ${theme.colors.quinary};
  border: none;
  height: 150px;
`

export const Brands = styled.div`
  margin: 0;
  display: flex;
  gap: 10px;
  font-size: 50px;
  color: ${theme.colors.quaternary};

  a:link {
    color: ${theme.colors.quaternary};
  }

  a:visited {
    color: ${theme.colors.quaternary};
  }

  a:hover {
    color: ${theme.colors.tertiary};
    cursor: pointer;
  }
`

export const Block = styled.div`
  display: flex;
  align-items: center;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export const Column = styled.div`
  &:first-child {
    flex-grow: 1;
  }

  &:last-child {
    margin-left: auto;
  }
`
