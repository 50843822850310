import * as React from 'react'
import {
  ADVISE_PAGE,
  STYLING_PAGE,
  TWO_DESIGN_PAGE,
  THREE_DESIGN_PAGE,
  EXTRAS_PAGE,
} from 'pages'
import Tab from 'layouts/tab'

const TabletMobile = (props) => {
  const { tabs, path, setPath } = props

  const handlePageData = () => {
    let page = {}

    switch (path) {
      case '/styling':
        page = STYLING_PAGE
        break
      case '/2D':
        page = TWO_DESIGN_PAGE
        break
      case '/3D':
        page = THREE_DESIGN_PAGE
        break
      case '/extras':
        page = EXTRAS_PAGE
        break
      default:
        page = ADVISE_PAGE
        break
    }

    return page
  }

  const { icon, heading, text } = handlePageData()

  return (
    <Tab
      tabs={tabs}
      path={path}
      setPath={setPath}
      icon={icon}
      name={heading}
      text={text}
    />
  )
}

export default TabletMobile
