import styled, { css } from 'styled-components'

import { theme } from 'theme'
import { CarouselIcon } from 'components/ui/carousel/Carousel'

const commonStyles = css`
  ${({ size }) =>
    css`
      width: ${size?.width};
      height: ${size?.height};
    `}

  ${({ outlined }) =>
    outlined
      ? css`
          border: ${outlined?.lineWidth} ${outlined?.lineStyle}
            ${outlined?.color};
        `
      : css`
          border: none;
        `}

  ${({ rounded }) =>
    css`
      border-radius: ${rounded};
    `}

    ${({ theme }) =>
    css`
      background-color: ${theme?.background};
      color: ${theme.color};
    `}
`

export const Container = styled.div`
  display: flex;
  position: relative;
  width: calc(100% - 120px);
  height: calc(100vh - 120px);
  border: 2px solid ${theme.colors.quaternary};
  margin: 60px;
  //padding: 0 80px;
`

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  margin-top: -50px;
  border-radius: 50%;
  border: 2px solid ${theme.colors.quaternary};
  background-color: #fff;
  cursor: pointer;

  img {
    width: 45px;
  }
`

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const CarouselWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 50%;
  height: 50%;

  .carouselPrevious {
    align-self: center;
    height: 45px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    cursor: pointer;

    img {
      //width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .carouselNext {
    align-self: center;
    height: 45px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    cursor: pointer;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .carousel {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 4px solid ${theme.colors.quaternary};
  }

  .carouselContent {
    display: flex;
    width: 100%;
    height: 100%;
    transition: all 1s ease-out;
  }

  .carouselItem {
    flex-shrink: 0;
    width: 100%;
    height: 100%;

    ${({ scrollArea }) =>
      scrollArea
        ? css`
            overflow-y: scroll;

            &::-webkit-scrollbar {
              display: none;
            }

            img {
              width: 100%;
              min-height: 100%;
              object-fit: cover;
              vertical-align: bottom;
            }

            .previewImage {
              width: 100%;
              height: 100%;

              img {
                width: 100%;

                object-fit: cover;
                vertical-align: bottom;
              }
            }
          `
        : css`
            .previewVideo {
              width: 100%;
              height: 100%;
              //object-fit: cover;

              video {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .previewImage {
              width: 100%;
              height: 100%;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          `}
  }

  /* button {
    width: 90px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    cursor: pointer;

    img {
      width: 100%;
      object-fit: cover;
    }
  } */
`

export const CarouselNext = styled(CarouselIcon)`
  width: 90px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;

  img {
    width: 100%;
    object-fit: cover;
  }
`

export const CarouselPrevious = styled(CarouselIcon)`
  width: 90px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;

  img {
    width: 100%;
    object-fit: cover;
  }
`

export const CarouselHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 50%;
  height: 50px;
  margin-bottom: 10px;
  padding: 0 ${({ iconWidth }) => `calc(${iconWidth}px + 10px)`};
`

export const CarouselTitle = styled.h1`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  font-family: 'Brittany Signature';
  font-size: 30px;
  color: ${theme.colors.quinary};
`

export const CarouselItem = styled.div`
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  width: 100%;

  ${({ title }) =>
    title === 'Blog'
      ? css`
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        `
      : css`
          /* width: 100%;
          height: 100%;
          background-color: red;
          flex-shrink: 0;
          margin: 0;
          padding: 0;

          .previewImage {
            flex-shrink: 0;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;

            img {
              width: 100%;
              height: 100%;
              //object-fit: cover;
            }
          }

          .previewVideo {
            flex-shrink: 0;
            width: 100%;
            height: 100%;

            video {
              margin: 0;
              padding: 0;
              width: 100%;
              height: 100%;
              //object-fit: cover;
            }
          } */
        `}
`

export const EditorWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-shrink: 0;
  position: absolute;
  width: 100%;
  height: 100%;
`

export const EditorTitleWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  /* background-color: yellow; */
`

export const EditorTitle = styled.h1`
  margin: 40px 40px 40px 40px;
  margin-left: auto;
  font-family: 'Brittany Signature';
  font-size: 30px;
  color: ${theme.colors.quinary};
`

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;

  ${commonStyles}

  ${({ hoverState }) =>
    hoverState &&
    css`
      &:hover {
        width: ${hoverState.width};
        height: ${hoverState.height};
        border: ${hoverState.lineWidth} ${hoverState.lineStyle}
          ${hoverState.color};
        border-radius: ${hoverState.rounded};
        background-color: ${hoverState.background};
        color: ${hoverState.color};
      }
    `}

    ${({ onActive }) =>
    onActive &&
    css`
      &:active {
        width: ${onActive.width};
        height: ${onActive.height};
        border: ${onActive.lineWidth} ${onActive.lineStyle} ${onActive.color};
        border-radius: ${onActive.rounded};
        background-color: ${onActive.background};
        color: ${onActive.color};
      }
    `}

  ${({ position, spacing }) =>
    position === 'TopLeft' &&
    css`
      z-index: 500;
      position: absolute;
      top: ${spacing};
      left: ${spacing};
    `}
  ${({ position, spacing }) =>
    position === 'TopCenter' &&
    css`
      z-index: 500;
      position: absolute;
      top: ${spacing};
      left: 50%;
      transform: translateX(-50%);
    `}
  ${({ position, spacing }) =>
    position === 'TopRight' &&
    css`
      z-index: 500;
      position: absolute;
      top: ${spacing};
      right: ${spacing};
    `}
  ${({ position, spacing }) =>
    position === 'MiddleLeft' &&
    css`
      z-index: 500;
      position: absolute;
      top: 50%;
      left: ${spacing};
      transform: translateY(-50%);
    `}
  ${({ position }) =>
    position === 'MiddleCenter' &&
    css`
      z-index: 500;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
  ${({ position, spacing }) =>
    position === 'MiddleRight' &&
    css`
      z-index: 500;
      position: absolute;
      top: 50%;
      right: ${spacing};
      transform: translateY(-50%);
    `}
  ${({ position, spacing }) =>
    position === 'BottomLeft' &&
    css`
      z-index: 500;
      position: absolute;
      bottom: ${spacing};
      left: ${spacing};
    `}
  ${({ position, spacing }) =>
    position === 'BottomCenter' &&
    css`
      z-index: 500;
      position: absolute;
      bottom: ${spacing};
      left: 50%;
      transform: translateX(-50%);
    `}
  ${({ position, spacing }) =>
    position === 'BottomRight' &&
    css`
      z-index: 500;
      position: absolute;
      right: ${spacing};
      bottom: ${spacing};
    `}
    ${({ position, spacing }) =>
    position === 'LeftTop' &&
    css`
      z-index: 500;
      position: absolute;
      top: ${spacing};
      left: ${spacing};
    `}
    ${({ position, spacing }) =>
    position === 'LeftCenter' &&
    css`
      z-index: 500;
      position: absolute;
      top: 50%;
      left: ${spacing};
      transform: translateY(-50%);
    `}
    ${({ position, spacing }) =>
    position === 'LeftBottom' &&
    css`
      z-index: 500;
      position: absolute;
      left: ${spacing};
      bottom: ${spacing};
    `}
    ${({ position, spacing }) =>
    position === 'RightTop' &&
    css`
      z-index: 500;
      position: absolute;
      top: ${spacing};
      right: ${spacing};
    `}
    ${({ position, spacing }) =>
    position === 'RightCenter' &&
    css`
      z-index: 500;
      position: absolute;
      top: 50%;
      right: ${spacing};
      transform: translateY(-50%);
    `}
    ${({ position, spacing }) =>
    position === 'RightBottom' &&
    css`
      z-index: 500;
      position: absolute;
      right: ${spacing};
      bottom: ${spacing};
    `}
`
