import { createSlice } from '@reduxjs/toolkit'

import {
  fetchProjects,
  createProject,
  updateProject,
  deleteProject,
} from './createAction'

const initialState = {
  projects: [],
  projectItem: {},
  projectForm: {
    step: 'title',
    data: {
      title: '',
      thumbnail: [],
      files: [],
    },
  },
  error: null,
  isLoading: false,
}

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    fetchProjectById: (state, action) => {
      state.projectItem = state.projects.find(
        (project) => project.id === action.payload
      )
    },
    updateTitle: (state, action) => {
      const { data } = state.projectForm
      data.title = action.payload
    },
    updateFiles: (state, action) => {
      const { data } = state.projectForm
      const { thumbnail, files } = action.payload
      data.thumbnail = thumbnail
      data.files = files
    },
    updateEditor: (state, action) => {
      const { data } = state.projectForm
      const { editor } = action.payload
      data.editor = editor
    },
    updateStep: (state, action) => {
      state.projectForm.step = action.payload
    },
    resetForm: (state) => {
      const { projectForm } = initialState
      state.projectForm = projectForm
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjects.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      state.isLoading = false
      const sortedProjects = action.payload.sort(
        (a, b) => b.createdAt - a.createdAt
      )
      state.projects = sortedProjects
    })
    builder.addCase(createProject.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(createProject.fulfilled, (state, action) => {
      state.isLoading = false
      state.projects = [action.payload, ...state.projects]
    })
    builder.addCase(updateProject.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateProject.fulfilled, (state, action) => {
      state.isLoading = false
      const { id, newProject } = action.payload
      state.projects = state.projects.map((item) =>
        item.id === id ? newProject : item
      )
    })
    builder.addCase(deleteProject.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(deleteProject.fulfilled, (state, action) => {
      state.isLoading = false
      state.projects = state.projects.filter(
        (item) => item.id !== action.payload
      )
    })
  },
})

export const {
  fetchProjectById,
  updateStep,
  updateTitle,
  updateFiles,
  resetForm,
} = projectSlice.actions

export default projectSlice.reducer
