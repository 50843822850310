import React from 'react'
import styled, { css } from 'styled-components'
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// import { signOutHandler } from 'utils/firebase/firebase.utils'
// import { useSelector, useDispatch } from 'react-redux'
// import { setCurrentUser, logout } from 'app/user/user.slice'
import { logout } from '../../features/auth/authSlice'
import Nav from 'layouts/nav/index'
import Header from 'layouts/header/index'
import { theme } from 'theme'

import {
  About,
  Approach,
  Process,
  Projects,
  Blog,
  Contact,
} from 'features/home/index'

const Home = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth.user)
  const location = useLocation()

  const logoutHandler = () => {
    dispatch(logout())
  }

  return (
    <React.Fragment>
      <Container>
        <NavContainer>
          <Nav />
        </NavContainer>
        <Header />
        <section id='over-mezelf'>
          <About />
        </section>
        <section id='aanpak'>
          <Approach />
        </section>
        <section id='process'>
          <Process />
        </section>
        <section id='projecten'>
          <Projects />
        </section>
        <section id='blog'>
          <Blog />
        </section>
        <section id='contact'>
          <Contact />
        </section>
      </Container>
    </React.Fragment>
  )
}

export default Home

const Container = styled.div`
  ${({ isLoggedIn }) => isLoggedIn && css``}

  @media only screen and (max-width: 1223px) {
    //margin-top: 100px;
    position: absolute;
    top: 100px;
    width: 100%;
    height: calc(100% - 100px);
  }
`

const NavContainer = styled.nav`
  /* position: absolute;
  padding: 15px 0;
  width: 100%;
  z-index: 3; */
`
