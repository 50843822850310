import React from 'react'
import styled from 'styled-components'

import heroImage from 'assets/kim/kim-hero.jpg'

const DesktopLaptop = () => {
  return (
    <HeaderContainer>
      <Overlay />
      <img src={heroImage} alt='Hero' />
      <HeaderBottom>
        <p>Hey jij, wat leuk dat je er bent!</p>
      </HeaderBottom>
    </HeaderContainer>
  )
}

export default DesktopLaptop

const HeaderContainer = styled.header`
  position: relative;
  display: block;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  img {
    display: block;
    width: 110%;
    height: 100%;
    object-fit: cover;
    object-position: calc(50% - 100px) calc(50% + 85px);
  }
`

const HeaderBottom = styled.div`
  z-index: 3;
  display: flex;
  align-items: center;

  width: 100%;
  height: 90px;
  position: absolute;
  bottom: 0;
  background-color: #dad5cf;

  p {
    margin-left: auto;
    margin-right: 60px;
    font-family: 'Brittany Signature';
    font-size: 32px;
    color: #35435d;
  }
`

const Overlay = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.6);
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 2;
`
