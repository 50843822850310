import { createAsyncThunk } from '@reduxjs/toolkit'

import { beleefKimApi } from 'service/beleefKim'

const {
  writeDataHandler,
  readDataHandler,
  updateDataHandler,
  deleteDataHandler,
  downloadFileHandler,
  deleteFileHandler,
} = beleefKimApi

const DATABASE_PATH = '/projects'
const STORAGE_URL = '/projects/files'

export const fetchProjects = createAsyncThunk(
  'projects/fetchProjects',
  async () => {
    try {
      const projects = await readDataHandler(DATABASE_PATH)
      return projects
    } catch (error) {
      console.log(error)
    }
  }
)

export const createProject = createAsyncThunk(
  'projects/createProject',
  async (project, { rejectWithValue }) => {
    try {
      const { id } = await writeDataHandler(DATABASE_PATH, project)
      return { id, ...project }
    } catch (error) {
      return rejectWithValue(error.response)
    }
  }
)

export const updateProject = createAsyncThunk(
  'projects/updateProject',
  async ({ newProject, project }, { rejectWithValue }) => {
    try {
      const { id, thumbnail, files } = project

      for (const key in thumbnail) {
        const url = `${STORAGE_URL}/${thumbnail[key].name}`
        const { fileExists } = await downloadFileHandler(url)
        if (!fileExists) await deleteFileHandler(url)
      }
      for (const key in files) {
        const url = `${STORAGE_URL}/${files[key].name}`
        const fileExists = await downloadFileHandler(url)
        if (!fileExists) await deleteFileHandler(url)
      }

      await updateDataHandler(`${DATABASE_PATH}/${id}`, newProject)

      return { id, newProject }
    } catch (error) {
      return rejectWithValue(error.response)
    }
  }
)

export const deleteProject = createAsyncThunk(
  'projects/deleteProject',
  async ({ id, projects }, { rejectWithValue }) => {
    try {
      const projectItem = projects.find((item) => item.id === id)
      const { thumbnail, files } = projectItem

      for (const key in thumbnail) {
        const url = `${STORAGE_URL}/${thumbnail[key].name}`
        await deleteFileHandler(url)
      }
      for (const key in files) {
        const url = `${STORAGE_URL}/${files[key].name}`
        await deleteFileHandler(url)
      }

      await deleteDataHandler(`${DATABASE_PATH}/${id}`)
      return id
    } catch (error) {
      return rejectWithValue(error.response)
    }
  }
)
