import styled, { css } from 'styled-components'
import TextField from '../textField/textField'

export const StyledTab = styled.div`
  ${({ isEditable }) =>
    isEditable
      ? css`
          display: none;
        `
      : css`
          display: block;
        `}

  ${({ isSelected }) =>
    isSelected
      ? css`
          background-color: red;
        `
      : ''}
`

export const StyledTextField = styled(TextField)`
  ${({ isEditable }) =>
    isEditable
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`
