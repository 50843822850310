import styled, { css } from 'styled-components'

import { theme } from 'theme'

export const Container = styled.div`
  z-index: 1000;
  position: fixed;
  bottom: 0;
  width: 100%;
  /* height: calc(100vh - 50px); */
  height: calc(100vh - 200px);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-family: 'VL Arch Light Cond Light';
  font-size: 20px;
  background-color: white;
`

export const Header = styled.div`
  display: flex;
`

export const Tabs = styled.div`
  display: flex;
  gap: 10px;
  margin: 10px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin: 0 0 0 auto;
  padding: 0 20px;
  //width: 150px;
  height: 40px;
  border-radius: 5px;
  background-color: ${({ active }) =>
    active ? theme.colors.primary : 'transparent'};
  color: ${({ active }) => (active ? 'white' : theme.colors.primary)};
  font-size: 16px;
  cursor: pointer;
  /* font-size: 16px;
  text-transform: uppercase;
  color: ${({ active }) =>
    active ? theme.colors.primary : theme.colors.quinary};
  ${({ active }) =>
    active &&
    css`
      text-shadow: 0px 0px 1px #35435d;
    `}
  cursor: pointer; */
`

export const Button = styled.button`
  /* position: absolute;
  top: 0;
  right: 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: ${theme.colors.secondary};
  border: 1px solid ${theme.colors.secondary};
  border-radius: 16px;
  height: 46px;
  margin: 10px;
  margin-left: auto;
  padding: 0 16px;
  font-size: 14px;
  cursor: pointer;
`

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 340px;
  margin-left: 10px;
  background-color: transparent;
  color: ${theme.colors.quaternary};
  border: 1px solid ${theme.colors.quaternary};
  font-size: 60px;
`

export const Icon = styled.div`
  ${({ type }) =>
    type === 'photo'
      ? css`
          width: 100%;
          height: 100%;
        `
      : css`
          width: 110px;
        `}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const TextBlock = styled.div`
  margin: 10px;
`

export const Title = styled.h1`
  margin: 0 0 20px 0;
  font-family: 'Brittany Signature';
  font-size: 32px;
  color: ${theme.colors.quinary};
`

export const Paragraph = styled.p`
  line-height: 1.5;
  color: ${theme.colors.quinary};
`

export const Overflow = styled.div`
  overflow: auto !important;
  height: calc(100% - 66px);
`

export const Overlay = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #444;
  opacity: 0.9;
  cursor: zoom-out;
`

export const Wrapper = styled.div`
  &.hide-scrollbar {
    overflow: hidden;
  }

  &.show-scrollbar {
    overflow: auto;
  }
`
