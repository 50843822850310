import { createSlice } from '@reduxjs/toolkit'

import { fetchPage, createPage, updatePage } from './createAction'

const initialState = {
  data: [],
  currentData: {},
  defaultValues: {
    title: 'Test',
    home: 'Home',
    describe: '',
    advies: 'Advies',
    styling: 'Styling',
    twoDimensionalDesign: '',
    threeDimensionalDesign: '',
  },
  error: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
}

export const approachSlice = createSlice({
  name: 'approach',
  initialState,
  reducers: {
    resetCurrentData: () => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPage.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchPage.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
    })
    builder.addCase(createPage.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(createPage.fulfilled, (state, action) => {
      state.isLoading = false
      const { title, describe } = action.payload
      state.currentData = { title, describe, ...state.tabs }
      state.data = { ...state.currentData, ...state.data }
    })
    builder.addCase(updatePage.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updatePage.fulfilled, (state, action) => {
      state.isLoading = false
      const { id } = action.payload
      state.currentData = { ...action.payload, ...state.tabs }
      state.data = state.data.map((item) =>
        item.id === id ? state.currentData : item
      )
    })
  },
})

export const { onSubmit, resetCurrentData } = approachSlice.actions

export default approachSlice.reducer
