import styled, { css } from 'styled-components'

import { theme } from 'theme'

export const Container = styled.div`
  z-index: 5000;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: calc(100% - 86px);
  min-height: 100%;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  background-color: ${theme.colors.quaternary};
  padding: 20px;

  h1 {
    text-align: center;
    margin: 0;
    font-family: 'Brittany Signature';
    font-size: 32px;
  }

  /* form {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
  } */
`

export const Field = styled.div`
  .previewPDF {
    flex-shrink: 0;
    border-radius: 20px;
    overflow: hidden;
    width: 200px;
    height: 200px;
  }

  .react-pdf__Page__canvas {
    width: 100% !important;
    min-height: 100% !important;
    object-fit: cover;
  }

  .previewImage {
    flex-shrink: 0;
    border-radius: 20px;
    overflow: hidden;
    width: 200px;
    height: 200px;
    background-color: #444;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .previewVideo {
    flex-shrink: 0;
    border-radius: 20px;
    overflow: hidden;
    width: 300px;
    height: 200px;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

export const ActionGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  margin-left: auto;
`

export const ErrorMessage = styled.span`
  color: red;
`

export const Button = styled.button`
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  background-color: ${({ solid }) =>
    solid ? theme.colors.secondary : 'transparent'};
  color: ${({ solid }) => (solid ? 'white' : theme.colors.secondary)};
  border: 1px solid ${theme.colors.secondary};
  border-radius: 16px;
  height: 46px;
  margin: 0;
  padding: 0 16px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    ${({ solid }) =>
      solid
        ? css`
            background-color: #4d85ba;
            border: 1px solid #4d85ba;
          `
        : css`
            color: #4d85ba;
            border: 1px solid #4d85ba;
          `}
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    background-color: #ccc;
    color: #666;
    border: 1px solid #ccc;
  }
`

export const Wrapper = styled.div`
  position: relative;
  border-radius: 8px;
  margin: 0 20px;
  padding: 20px 0;
  background-color: white;
  overflow: hidden;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  /* border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; */
  border-radius: 8px;
  padding: 20px;
  background-color: white;
  overflow-y: scroll;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const Tabs = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;

  overflow: hidden;
  border-bottom: 1px solid #eee;
  align-items: center;
  overflow: auto;
  height: 48px;
  font-size: 14px;
`

export const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  border: 0;
  background: none;
  border-radius: 8px;
  margin: 0 8px;
  padding: 0 8px;
  cursor: pointer;
  background-color: ${({ active }) => active && '#eee'};
  color: #777;

  span {
    margin-right: 8px;
  }

  &:hover {
    background-color: #eee;
  }
`

export const Divider = styled.div`
  width: 1px;
  height: 100%;
  background-color: #eee;
`

export const Hr = styled.div`
  margin: 8px 0;
  width: 100%;
  height: 1px;
  background-color: #eee;
`

export const FormShell = styled.div`
  margin-top: 48px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 50px);
`

export const Close = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 86px;
  padding-top: 20px;
  min-height: 100%;
  cursor: zoom-out;
`

export const Row = styled.div`
  display: flex;
  gap: 10px;
  overflow: scroll;
`

export const Hidden = styled.div`
  width: 100%;
  height: 1px;
  background-color: red;

  /* margin-bottom: -20px !important; */
  position: fixed;
  bottom: calc(20px + 46px + 10px);
  left: 0;
`
