import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import styled, { css } from 'styled-components'
import { theme } from 'theme'

import { deleteProject } from './createAction'
import { resetForm } from './projectSlice'
import GridView from 'components/GridView'

const Projects = () => {
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const { user } = useSelector((state) => state.auth)
  const { projects, isLoading } = useSelector((store) => store.projects)

  const handleDeleteData = (e, id) => {
    e.preventDefault()
    // findByID
    // const project = data.find((item) => item.id === id)
    if (window.confirm('Are you sure you want to delete this project?')) {
      dispatch(deleteProject({ id, projects }))
    }
  }

  if (isLoading)
    return (
      <Loading>
        <FontAwesomeIcon icon={faSpinner} spin />
      </Loading>
    )

  // Set loading state isLoading && to fix issue
  return (
    <GridView
      title='Projecten'
      user={user}
      data={projects}
      toDetails={(id) => `/projects/${id}`}
      toCreateForm={() => {
        dispatch(resetForm())
        navigate('/projects/form')
      }}
      handleDeleteData={handleDeleteData}
    />
  )
}

export default Projects

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  color: ${theme.colors.secondary};
  font-size: 30px;
`
