import React, { useRef } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'

import * as S from './ItemView.styled'
import logo from 'assets/logo.png'
import ArrowForward from 'assets/icons/ArrowForward.png'
import Tiptap from 'features/ui/editor/editor.component'

import useCarousel from './ui/carousel/use-carousel'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselIcon,
} from 'components/ui/carousel/Carousel'
import Preview from 'features/ui/preview.component'
import { theme } from 'theme'

const ItemView = (props) => {
  const { user, item, toUpdateForm, scrollArea } = props

  const { title, files, content } = item

  const carouselIconRef = useRef(null)

  const [items, setItems] = React.useState([])
  const [carouselIconWidth, setCarouselIconWidth] = React.useState(0)

  const { currentIndex, scrollPrev, scrollNext } = useCarousel(items)

  const navigate = useNavigate()

  React.useEffect(() => {
    const iconWidth = carouselIconRef.current.getBoundingClientRect().width

    setCarouselIconWidth(iconWidth.toFixed(2))

    let newItems = []
    console.log(files)
    console.log(content)
    if (files) newItems = [...files, ...newItems]
    if (content) newItems = [...content, ...newItems]

    setItems(newItems)
  }, [carouselIconWidth, files, content])

  const carouselItemHandler = (item) => {
    if (item?.hasOwnProperty('downloadURL')) {
      return <Preview files={item} />
    }

    if (item?.hasOwnProperty('description')) {
      return (
        <S.EditorWrapper>
          <Tiptap value={item.description} editable={false} />
          <S.EditorTitleWrapper>
            <S.EditorTitle>{item.subTitle}</S.EditorTitle>
          </S.EditorTitleWrapper>
        </S.EditorWrapper>
      )
    }
  }

  return (
    <S.Container>
      <S.Logo onClick={() => navigate(`/`)}>
        <img src={logo} alt='Logo' />
      </S.Logo>
      <S.CarouselContainer>
        <S.CarouselHeader iconWidth={carouselIconWidth}>
          <S.CarouselTitle>{title}</S.CarouselTitle>
          {user && (
            <S.IconButton
              onClick={toUpdateForm}
              style={{ marginLeft: 'auto' }}
              size={{ width: '50px', height: '50px' }}
              rounded='16px'
              outlined={{
                lineWidth: '1px',
                lineStyle: 'solid',
                color: theme.colors.secondary,
              }}
              theme={{
                background: 'transparent',
                color: theme.colors.secondary,
              }}
              hoverState={{ background: theme.colors.secondary, color: '#fff' }}
            >
              <EditIcon />
            </S.IconButton>
          )}
        </S.CarouselHeader>
        <S.CarouselWrapper scrollArea={scrollArea}>
          <CarouselIcon
            ref={carouselIconRef}
            onClick={scrollPrev}
            className='carouselPrevious'
            items={items}
          >
            <img
              src={ArrowForward}
              style={{ transform: 'rotate(-180deg)' }}
              alt='Previous'
            />
          </CarouselIcon>
          <Carousel>
            <CarouselContent currentIndex={currentIndex}>
              {items.map((item, index) => (
                <CarouselItem key={index}>
                  {carouselItemHandler(item)}
                </CarouselItem>
              ))}
            </CarouselContent>
          </Carousel>
          <CarouselIcon
            onClick={scrollNext}
            className='carouselNext'
            items={items}
          >
            <img src={ArrowForward} alt='Next' />
          </CarouselIcon>
        </S.CarouselWrapper>
      </S.CarouselContainer>
    </S.Container>
  )
}

export default ItemView
