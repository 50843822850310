import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { beleefKimApi, sortData, filterData } from 'service/beleefKim'
import { theme } from 'theme'
import { BrittanySignature } from 'features/ui/typography.component'
import { Box } from 'features/ui/box.component'
import { MuiButton } from 'features/ui/button.component.mui'
import Preview from 'features/ui/preview.component'

const { readDataHandler } = beleefKimApi
const { orderByChild } = sortData
const { limitToLast } = filterData

const DesktopLaptop = () => {
  let navigate = useNavigate()

  const routeChange = () => {
    navigate(`projects`)
  }

  const [dataItems, setDataItems] = React.useState([])

  const hiddenGridItems = 3 - dataItems?.length

  const fetchData = async () => {
    try {
      const queries = []
      // queries.push(orderByChild('createdAt'))
      queries.push(limitToLast(3))
      const docs = await readDataHandler('/projects', queries)
      docs.reverse()
      setDataItems(docs)
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  return (
    <Container>
      <Grid>
        <Box>
          <BrittanySignatureCustom>Projecten</BrittanySignatureCustom>
        </Box>
        {dataItems &&
          dataItems?.map((item) => (
            <Card>
              <Preview files={item.thumbnail} />
            </Card>
          ))}
        {dataItems &&
          Array(hiddenGridItems)
            .fill()
            .map((_, index) => <HiddenCard isVisible key={index} />)}
        {/* <Card></Card>
        <Card></Card>
        <Card></Card> */}
        <Box>
          <MuiButton variant='contained' onClick={routeChange}>
            Meer zien
          </MuiButton>
        </Box>
      </Grid>
      <Block>
        'Hier alvast een voorproefje wat hopelijk smaakt naar meer. <br /> Mijn
        portfolio met projecten die ik zelf ontworpen en uitgewerkt heb. Ik ben
        razend benieuwd wat jullie er van vinden'
      </Block>
    </Container>
  )
}

export default DesktopLaptop

const Container = styled.section`
  position: relative;
  height: 100vh;
  padding: 60px;
  background-color: ${theme.colors.primary};
  font-family: 'VL Arch Light Cond Light';
  font-size: 18px;
`

const Block = styled.p`
  position: absolute;
  bottom: 60px;
  width: 32%;
  font-size: 20px;
  line-height: 21px;
  color: white;
  margin: auto 0 0 0;

  span {
    display: block;
  }
`

const BrittanySignatureCustom = styled(BrittanySignature)`
  margin: auto 0 0 0;
  color: ${theme.colors.quinary};
  font-size: 40px;
`

const Grid = styled.div`
  /* margin-top: auto; */
  /* background-color: red;
  margin: 0;
  padding: 0; */
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: auto repeat(3, 1fr) 200px;
  grid-column-gap: 30px;
`

const Card = styled.div`
  border: 5px solid ${theme.colors.quaternary};
  margin: 0;
  padding: 0;
  aspect-ratio: 1 / 1;
  overflow: hidden;

  .previewPDF {
    margin: 0;
    padding: 0;
    flex-shrink: 0;
    width: 100%;
    aspect-ratio: 1 /1;
    background-color: ${theme.colors.quaternary};
  }

  .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 1 / 1 !important;
  }

  .react-pdf__Page__textContent {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 1 / 1 !important;
  }

  .previewImage {
    flex-shrink: 0;
    width: 100%;
    aspect-ratio: 1 /1;
    background-color: ${theme.colors.quaternary};
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

const HiddenCard = styled.div`
  aspect-ratio: 1 / 1;
  visibility: hidden;
`
