import * as React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import ArrowForward from 'assets/icons/ArrowForward.png'
import QuoteLeft from 'assets/icons/QuoteLeft.png'
import logo from 'assets/logo.png'
import { theme } from 'theme'
import * as S from './GridView.styled'
import Preview from 'features/ui/preview.component'

const LIMIT = 8

const GridView = (props) => {
  const {
    title,
    user,
    data,
    toDetails,
    onClickDetails,
    toCreateForm,
    handleDeleteData,
  } = props

  const navigate = useNavigate()

  const gridRef = React.useRef(null)

  const [page, setPage] = React.useState(1)
  const [gridHeight, setGridHeight] = React.useState(0)

  React.useEffect(() => {
    setGridHeight(`${gridRef.current?.clientHeight}px`)
  }, [])

  const routeChange = () => {
    navigate(`/`)
  }

  const handleItemsPerPage = () => {
    const firstIndex = (page - 1) * LIMIT
    const lastIndex = firstIndex + LIMIT

    return data?.slice(firstIndex, lastIndex)
  }

  const totalPages = Math.ceil(data?.length / LIMIT)

  const handleNextPage = () => {
    setPage((page % totalPages) + 1)
  }

  const handlePrevPage = () => {
    setPage(((page - 2 + totalPages) % totalPages) + 1)
  }

  return (
    <S.Container>
      {data.length > 8 && (
        <S.IconButton
          style={{ transform: 'rotate(-180deg)' }}
          onClick={handlePrevPage}
          position='LeftCenter'
          spacing='3.5%'
          size={{ width: '100px', height: '50px' }}
          rounded='50%'
          theme={{ background: 'transparent' }}
        >
          <img src={ArrowForward} />
        </S.IconButton>
      )}
      {user && (
        <S.IconButton
          onClick={toCreateForm}
          position='TopRight'
          spacing='15px'
          size={{ width: '50px', height: '50px' }}
          rounded='16px'
          outlined={{
            lineWidth: '1px',
            lineStyle: 'solid',
            color: theme.colors.secondary,
          }}
          theme={{ background: 'transparent', color: theme.colors.secondary }}
          hoverState={{ background: theme.colors.secondary, color: '#fff' }}
        >
          <AddIcon />
        </S.IconButton>
      )}
      <S.Logo onClick={routeChange}>
        <img src={logo} alt='Logo' />
      </S.Logo>
      <S.Header height={gridHeight} title={title}>
        <S.Title title={title}>{title}</S.Title>
        <S.Overlay />
      </S.Header>
      {title === 'Blog' && (
        <S.QuoteLeft height={gridHeight}>
          <img src={QuoteLeft} style={{ transform: 'scaleX(-1)' }} />
        </S.QuoteLeft>
      )}
      <>
        <S.Grid ref={gridRef}>
          {handleItemsPerPage().map((data) => {
            console.log('handleItemsPerPage: ', data)
            return (
              <S.CardWrapper key={data.key}>
                <S.Card>
                  <Link to={toDetails(data.id)}>
                    <Preview files={data.thumbnail} />
                  </Link>
                </S.Card>
                <S.CardTitleWrapper>
                  <S.CardTitle>{data.title}</S.CardTitle>
                </S.CardTitleWrapper>

                {user && (
                  <S.IconButton
                    onClick={(e) => handleDeleteData(e, data.id)}
                    position='TopRight'
                    spacing='15px'
                    size={{ width: '50px', height: '50px' }}
                    rounded='50%'
                    theme={{ background: '#ff8585' }}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      onMouseDown={(e) => e.stopPropagation()}
                    />
                  </S.IconButton>
                )}
              </S.CardWrapper>
            )
          })}

          {LIMIT &&
            Array(LIMIT - handleItemsPerPage().length)
              .fill()
              .map((_, index) => (
                <S.CardWrapper key={index} isVisible={false} />
              ))}
        </S.Grid>
      </>

      {title === 'Blog' && (
        <S.QuoteRight height={gridHeight}>
          <img src={QuoteLeft} />
        </S.QuoteRight>
      )}
      {data.length > 8 && (
        <S.IconButton
          onClick={handleNextPage}
          position='RightCenter'
          spacing='3.5%'
          size={{ width: '100px', height: '50px' }}
          rounded='50%'
          theme={{ background: 'transparent' }}
        >
          <img src={ArrowForward} />
        </S.IconButton>
      )}
    </S.Container>
  )
}

export default GridView
